import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Switch,
  Upload,
} from "antd";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useState } from "react";
import Label from "../../../component/common/Label";
import TextEditor from "../../../component/common/Text-Editor";
import {
  CITY_ARRAY,
  convertLocalToUTC,
  COUNTRY_ARRAY,
  GENDER_ARRAY,
  INDIAN_STATES_ARRAY,
  TASK_FORM_OPTION,
  TASK_PLATFORM_OPTION,
} from "../../../util/functions";
import ImgCrop from "antd-img-crop";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";

const CheckboxGroup = Checkbox.Group;

const TaskForm = ({
  open,
  onCancel,
  formData,
  form,
  handleFormSubmit,
  loading,
  allBrand,
  allCategory,
  // isRegionBased,
  // setIsRegionBased,
  isVisibleOnApp,
  setIsVisibleOnApp,
  checkedList,
  setCheckedList,
  checkAll,
  setCheckAll,
  platformTypeList,
  setPlatformTypeList,
}) => {
  // console.log(convertUTCToLocal(formData?.expireDate, 'DD/MM/YYYY hh:mm A'));
  const api = useHttp();
  const [taskTC, setTaskTC] = useState("");
  const [tagsOption, setTagsOption] = useState([]);
  const [countryToggale, setCountryToggale] = useState();
  const [stateToggale, setStateToggale] = useState(false);
  const [cityToggale, setCityToggale] = useState(false);
  const [genderToggale, setGenderToggale] = useState(false);
  const [AgeToggale, setAgeToggale] = useState(false);
  const [tagToggale, setTagToggale] = useState(false);
  const onChange = (list) => {
    setCheckedList(list);
    setCheckAll(list.length === TASK_FORM_OPTION.length);
    form.setFieldsValue({ availableFileType: list });
  };

  const onChangePlatform = (list) => {
    setPlatformTypeList(list);
    form.setFieldsValue({ platformType: list });
  };

  const onCheckAllChange = (e) => {
    const checked = e.target.checked;
    const list = checked ? TASK_FORM_OPTION.map((option) => option.value) : [];
    setCheckedList(list);
    setCheckAll(checked);
    form.setFieldsValue({ availableFileType: list });
  };

  const normFile = (e) => {
    // console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    if (!Object?.keys(formData)?.length)
      api.sendRequest(
        CONSTANTS.API.setting.terms.getAll,
        (res) => {
          setTaskTC(res?.data?.rows?.[0]?.information);
        },
        { type: "taskTermAndCondition" }
      );

    api.sendRequest(CONSTANTS.API.setting.terms.getTaskTag, (res) => {
      const opt = res?.data?.rows?.map((tg) => {
        return {
          label: tg?.name,
          value: tg?.name,
        };
      });
      setTagsOption(opt);
    });
  }, []);

  useEffect(() => {
    if (Object?.keys(formData)?.length > 0) {
      // form.setFieldsValue(formData)
      let pfType = [];
      if (formData?.allowAndroid) pfType.push("allowAndroid");
      if (formData?.allowIOS) pfType.push("allowIOS");
      if (formData?.allowWeb) pfType.push("allowWeb");

      form.resetFields();
      form.setFieldsValue({
        name: formData?.name,
        brandId: formData?.brandId,
        completionAmount: formData?.completionAmount,
        completionSpin: formData?.completionSpin,
        taskCategoryId: formData?.taskCategoryId,
        helpLink: formData?.helpLink,
        description: formData?.description,
        termAndCondition: formData?.termAndCondition,
        completionTime: formData?.completionTime,
        priority: formData?.priority,
        isRegionBased: formData?.isRegionBased,
        expireDate: dayjs(formData.expireDate),
        availableFileType: formData?.availableFileType?.split(","),
        cities: formData?.isRegionBased
          ? formData?.regions?.split(",")
          : undefined,
        trainingUrl: formData?.trainingUrl,
        visibleOnApp: formData?.visibleOnApp,
        endGoal: formData?.endGoal,
        trackUrl: formData?.trackUrl,
        reviewDays: formData?.reviewDays,
        message: formData?.message,
        extendReviewDays: formData?.extendReviewDays,
        defaultAdminMessageOnReject: formData?.defaultAdminMessageOnReject,
        defaultNumberOfDaysForResubmitOnReject:
          formData?.defaultNumberOfDaysForResubmitOnReject,
        percentageReferralEarning: formData?.percentageReferralEarning,
        holdTime: formData?.holdTime / 60 || null,
        windowCount: formData?.windowCount,
        windowTime: formData?.windowTime / 60 || null,
        totalTaskCount: formData?.totalTaskCount,
        countryFilterValue: formData?.countryFilterValue,
        stateFilterValue: formData?.stateFilterValue?.length
          ? formData?.stateFilterValue?.split(",")
          : [],
        cityFilterValue: formData?.cityFilterValue?.length
          ? formData?.cityFilterValue?.split(",")
          : [],
        genderFilterValue: formData?.genderFilterValue?.length
          ? formData?.genderFilterValue?.split(",")
          : [],
        tagFilterValue: formData?.tagFilterValue?.length
          ? formData?.tagFilterValue?.split(",")
          : [],
        tags: formData?.tags?.length ? formData?.tags?.split(",") : [],
        minAgeWindow: formData?.minAgeWindow,
        maxAgeWindow: formData?.maxAgeWindow,
        isAllowCountry: formData?.isAllowCountry,
        isAllowState: formData?.isAllowState,
        isAllowCity: formData?.isAllowCity,
        isAllowGender: formData?.isAllowGender,
        isAllowAgeWindow: formData?.isAllowAgeWindow,
        isAllowTag: formData?.isAllowTag,
        countryFilter: formData?.countryFilter,
        stateFilter: formData?.stateFilter,
        cityFilter: formData?.cityFilter,
        genderFilter: formData?.genderFilter,
        ageWindowFilter: formData?.ageWindowFilter,
        tagFilter: formData?.tagFilter,
      });
      setPlatformTypeList(pfType);
      if (formData?.availableFileType) {
        setCheckedList(formData?.availableFileType?.split(","));
        setCheckAll(
          formData?.availableFileType?.split(",")?.length ===
            TASK_FORM_OPTION.length
        );
      }
      console.log(formData);
      // setIsRegionBased(formData?.isRegionBased || false);
      setCountryToggale(formData?.countryFilter || false);
      setStateToggale(formData?.stateFilter || false);
      setCityToggale(formData?.cityFilter || false);
      setGenderToggale(formData?.genderFilter || false);
      setAgeToggale(formData?.ageWindowFilter || false);
      setTagToggale(formData?.tagFilter || false);
      setIsVisibleOnApp(formData?.visibleOnApp || false);
    }
  }, [formData]);
  return (
    <Modal
      title={Object?.keys(formData)?.length > 0 ? "Edit  Task" : "Add Task"}
      open={open}
      style={{
        top: 20,
      }}
      className=""
      footer={[
        <Button
          className="mt-2"
          key="submit"
          style={{
            borderRadius: "4px",
            height: "40px",
            width: "200px",
          }}
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((value) => {
                const data = {
                  ...value,
                  holdTime: value?.holdTime * 60,
                  windowTime: value?.windowTime * 60,
                  availableFileType: checkedList?.toString(),
                  allowAndroid: platformTypeList?.includes("allowAndroid"),
                  allowIOS: platformTypeList?.includes("allowIOS"),
                  allowWeb: platformTypeList?.includes("allowWeb"),
                  stateFilterValue: value?.stateFilterValue?.toString(),
                  cityFilterValue: value?.cityFilterValue?.toString(),
                  genderFilterValue: value?.genderFilterValue?.toString(),
                  tagFilterValue: value?.tagFilterValue?.toString(),
                  tags: value?.tags?.toString(),
                  // expireDate: `${moment(value.expireDate).utc().format("YYYY-MM-DD HH:mm:ss")}`,
                  expireDate: convertLocalToUTC(
                    value.expireDate?.$d,
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  regions:
                    value?.cities?.length > 0 ? value?.cities?.join(",") : "",
                };
                delete data.cities;
                handleFormSubmit(data);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
          loading={loading}
        >
          {formData?.id ? "Update" : "Create"}
        </Button>,
      ]}
      width={1000}
      onCancel={() => {
        onCancel();
      }}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
    >
      <Form form={form}>
        {/* Heading and Brand */}
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Label required>Task Heading</Label>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Task Heading is required" }]}
            >
              <Input placeholder="Enter a Task Heading..." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label required>Brand</Label>
            <Form.Item
              name="brandId"
              hasFeedback
              rules={[{ required: true, message: "Please Select Brand" }]}
            >
              <Select
                // disabled={data?.disabled && formData[data?.name]}
                showSearch
                placeholder={"Select a Brand"}
                filterOption={(inputValue, option) => {
                  const value = option?.children?.props?.children[0]?.props
                    ?.children
                    ? `${option?.children?.props?.children[0]?.props?.children}`
                    : `${option?.children}`;
                  return value
                    ?.toLowerCase()
                    ?.includes(inputValue?.toLowerCase());
                }}
                allowClear // Allow clearing the selected value
              >
                {allBrand &&
                  allBrand?.length > 0 &&
                  allBrand?.map((item) => (
                    <Select.Option key={`role_${item.id}`} value={item.value}>
                      {item.label ? item.label : item.value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* Amount, Spin and Category */}
        <Row gutter={[24, 16]}>
          <Col span={12}>
            <Row gutter={[24, 16]}>
              <Col span={12}>
                <Label>Enter Amount</Label>
                <Form.Item
                  name="completionAmount"
                  // rules={[{ required: true, message: "Amount is required" }]}
                >
                  <InputNumber
                    className="w-full"
                    placeholder="Enter Amount..."
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Label>Number of Spin</Label>
                <Form.Item
                  name="completionSpin"
                  rules={
                    [
                      // { required: true, message: "Number of Spin is required" },
                    ]
                  }
                >
                  <InputNumber
                    className="w-full"
                    placeholder="Enter Number of Spin..."
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Label required>Task Category</Label>
            <Form.Item
              name="taskCategoryId"
              hasFeedback
              rules={[
                { required: true, message: "Please Select Task Category" },
              ]}
            >
              <Select
                // disabled={data?.disabled && formData[data?.name]}
                showSearch
                placeholder={"Select a Task Category"}
                filterOption={(inputValue, option) => {
                  const value = option?.children?.props?.children[0]?.props
                    ?.children
                    ? `${option?.children?.props?.children[0]?.props?.children}`
                    : `${option?.children}`;
                  return value
                    ?.toLowerCase()
                    ?.includes(inputValue?.toLowerCase());
                }}
                allowClear // Allow clearing the selected value
              >
                {allCategory &&
                  allCategory?.length > 0 &&
                  allCategory?.map((item) => (
                    <Select.Option key={`role_${item.id}`} value={item.value}>
                      {item.label ? item.label : item.value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* Expiry Date and Completion Time, Priority */}
        <Row className="mb-5" gutter={[24, 16]}>
          <Col span={8}>
            <Label required>Expiry Date</Label>
            <Form.Item
              name={"expireDate"}
              className="form "
              // required={true}
              rules={[{ required: true, message: "Select a Expiry Date" }]}
            >
              <DatePicker
                format={"DD/MM/YYYY hh:mm A"}
                showTime
                placeholder={"Expiry Date"}
                style={{
                  width: "100%",
                }}
                disabledDate={(current) =>
                  current && current < moment().startOf("day")
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Label required>
              Completion Time <span className="text-xs font-medium">(min)</span>{" "}
            </Label>
            <Form.Item
              name={"completionTime"}
              className="form "
              // required={true}
              rules={[{ required: true, message: "Select a Completion Time" }]}
            >
              <InputNumber
                className="w-full"
                placeholder="Enter Completion Time..."
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Label required>Priority</Label>
            <Form.Item
              name={"priority"}
              className="form "
              // required={true}
              rules={[{ required: true, message: "Priority is required" }]}
            >
              <InputNumber className="w-full" placeholder="Enter Priority..." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 16]}>
          <Col span={8}>
            <Label>Review Days</Label>
            <Form.Item
              name={"reviewDays"}
              className="form "
              // required={true}
            >
              <InputNumber
                className="w-full"
                placeholder="Enter reviewDays..."
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Label>Message</Label>
            <Form.Item name="message">
              <Input placeholder="Enter a message..." />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Label>Extend Review Days</Label>
            <Form.Item
              name={"extendReviewDays"}
              className="form "
              // required={true}
            >
              <InputNumber className="w-full" placeholder="Enter Days..." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 16]}>
          <Col span={12}>
            <Label>Track Url</Label>
            <Form.Item
              name="trackUrl"
              rules={[
                {
                  type: "url",
                  message: "Please enter a valid URL",
                },
              ]}
            >
              <Input type="url" placeholder="Enter URL..." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label>End Goal</Label>
            <Form.Item name="endGoal">
              <Input placeholder="Enter a End Goal..." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 16]}>
          <Col span={12}>
            <Label>Default Number Of Days For Resubmit On Rework</Label>
            <Form.Item name="defaultNumberOfDaysForResubmitOnReject">
              <Input type="number" placeholder="Enter days..." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label>Default Message On Rework</Label>
            <Form.Item name="defaultAdminMessageOnReject">
              <Input placeholder="Enter a message..." />
            </Form.Item>
          </Col>
        </Row>
        {/* URL */}
        <Row gutter={[24, 16]}>
          <Col span={18}>
            <Label>URL How to Perform the Task</Label>
            <Form.Item
              name="helpLink"
              rules={[
                {
                  type: "url",
                  message: "Please enter a valid URL",
                },
              ]}
            >
              <Input type="url" placeholder="Enter URL..." />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Label>Referral Earning (%)</Label>
            <Form.Item
              name={"percentageReferralEarning"}
              className="form "
              // required={true}
            >
              <InputNumber
                className="w-full"
                placeholder="Enter task referral percentage"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 16]}>
          <Col span={24}>
            <Row gutter={[24, 16]}>
              <Col span={6}>
                <Label required>Targeted Time Slot (hr)</Label>
                <Form.Item
                  name="windowTime"
                  rules={[
                    {
                      required: true,
                      message: "Targeted Time Slot is required",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    placeholder="Enter targeted time slot"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Label required>Targeted Submission Count</Label>
                <Form.Item
                  name="windowCount"
                  rules={[
                    {
                      required: true,
                      message: "Targeted Submission Count is required",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    placeholder="Enter targeted submission count"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Label required>Block Time (hr)</Label>
                <Form.Item
                  name="holdTime"
                  rules={[
                    { required: true, message: "Block Time is required" },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    placeholder="Enter block time"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Label required>Total Submission Count</Label>
                <Form.Item
                  name="totalTaskCount"
                  rules={[
                    {
                      required: true,
                      message: "Total Submission Count is required",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    placeholder="Enter total submission count"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Region and City  */}
        <Row gutter={[24, 24]}>
          {/* <Col span={5} className="flex items-baseline gap-3 mt-3">
            <Label>Is Region Base</Label>
            <Form.Item name="isRegionBased">
              <Switch
                onChange={(value) => {
                  if (!value) {
                    form.setFieldValue("cities", []);
                  }
                  setIsRegionBased(value);
                }}
                checked={isRegionBased}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label required={isRegionBased}>Select Multiple Cities</Label>
            <Form.Item
              name="cities"
              id="cities"
              rules={[
                { required: isRegionBased, message: "Please Select Brand" },
              ]}
            >
              <Select
                disabled={!isRegionBased}
                mode="multiple"
                showSearch
                maxTagCount="responsive"
                placeholder={"Select a Cities"}
                filterOption={(inputValue, option) => {
                  const value = option?.children?.props?.children[0]?.props
                    ?.children
                    ? `${option?.children?.props?.children[0]?.props?.children}`
                    : `${option?.children}`;
                  return value
                    ?.toLowerCase()
                    ?.includes(inputValue?.toLowerCase());
                }}
                allowClear // Allow clearing the selected value
              >
                {CITY_ARRAY &&
                  CITY_ARRAY?.length > 0 &&
                  CITY_ARRAY?.map((item) => (
                    <Select.Option
                      key={`role_${item.id}`}
                      value={item.value.toLowerCase()}
                    >
                      {item.label ? item.label : item.value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col span={6}>
            <Label required={!formData?.featuredImage}>
              Featured Image
              <span className="text-xs text-[#00000073]">(820 × 312)</span>
            </Label>
            <Form.Item
              name="featuredImage"
              className="form "
              valuePropName="fileList"
              rules={[
                {
                  required: !formData?.featuredImage,
                  message: "Please upload feature image",
                },
              ]}
              getValueFromEvent={normFile}
            >
              <ImgCrop
                showGrid
                rotationSlider
                // aspectSlider
                minZoom={0}
                maxZoom={3}
                cropperProps={{
                  restrictPosition: false,
                }}
                fillColor="transparent"
                showReset
                aspect={2.63 / 1}
              >
                <Upload
                  name="featuredImage"
                  listType="picture"
                  customRequest={async ({ onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok");
                    }, 0);
                  }}
                  accept={"image/png, image/jpeg"}
                  maxCount={1}
                  onChange={(e) => {
                    form?.setFieldsValue({
                      featuredImage: normFile(e),
                    });
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label>Tag</Label>
            <Form.Item
              name="tags"
              id="tags"
              rules={[{ required: false, message: "Please Select Tag" }]}
            >
              <Select
                // disabled={!tagToggale}
                mode="tags"
                showSearch
                maxTagCount="responsive"
                placeholder={"Select tag"}
                filterOption={(inputValue, option) => {
                  const value = option?.children?.props?.children[0]?.props
                    ?.children
                    ? `${option?.children?.props?.children[0]?.props?.children}`
                    : `${option?.children}`;
                  return value?.includes(inputValue);
                }}
                allowClear // Allow clearing the selected value
              >
                {tagsOption &&
                  tagsOption?.length > 0 &&
                  tagsOption?.map((item) => (
                    <Select.Option key={`role_${item.id}`} value={item.value}>
                      {item.label ? item.label : item.value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Media, Description and Task Terms */}
        <Row gutter={[24, 16]}>
          <Col span={24}>
            <Label required>Accepted File Type</Label>
            <Form.Item
              name="availableFileType"
              rules={[
                {
                  required: true,
                  message: "Please select at least one file type",
                },
              ]}
            >
              <div>
                <Checkbox
                  indeterminate={
                    checkedList?.length > 0 &&
                    checkedList?.length < TASK_FORM_OPTION?.length
                  }
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  All
                </Checkbox>
                <CheckboxGroup
                  options={TASK_FORM_OPTION}
                  value={checkedList}
                  onChange={onChange}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Label>Platform Type</Label>
            <Form.Item
              name="platformType"
              rules={[
                {
                  required: false,
                  // message: "Please select at least one file type",
                },
              ]}
            >
              <div>
                <CheckboxGroup
                  options={TASK_PLATFORM_OPTION}
                  value={platformTypeList}
                  onChange={onChangePlatform}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Label required>Description</Label>
            <Form.Item
              name={"description"}
              className="form "
              style={{ height: "250px" }}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || value === "<p><br></p>") {
                      return Promise.reject(
                        new Error("Please Enter Valid Description")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <TextEditor />
            </Form.Item>
          </Col>
          <Col className="my-5" span={24}>
            <Label required>Task Terms & Condition</Label>
            <Form.Item
              name={"termAndCondition"}
              initialValue={taskTC}
              className="form "
              style={{ height: "250px" }}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || value === "<p><br></p>") {
                      return Promise.reject(
                        new Error("Please Enter Valid Task Terms & Condition")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <TextEditor />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 24]} className="my-6">
          <Col span={4}>
            <Label>Visible on App</Label>
            <Form.Item name="visibleOnApp">
              <Switch
                onChange={(value) => {
                  setIsVisibleOnApp(value);
                }}
                checked={isVisibleOnApp}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label required={isVisibleOnApp}>Training URL</Label>
            <Form.Item
              name="trainingUrl"
              rules={[
                {
                  required: isVisibleOnApp,
                  message: "Please upload feature image",
                },
                {
                  type: "url",
                  message: "Please enter a valid URL",
                },
              ]}
            >
              <Input type="url" placeholder="Enter URL..." />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Label required={formData?.banner ? false : isVisibleOnApp}>
              Banner{" "}
              <span className="text-xs text-[#00000073]">(820 × 312)</span>
            </Label>
            <Form.Item
              name="banner"
              className="form "
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: formData?.banner ? false : isVisibleOnApp,
                  message: "Please upload Banner image",
                },
              ]}
            >
              <ImgCrop
                showGrid
                rotationSlider
                // aspectSlider
                minZoom={0}
                maxZoom={3}
                cropperProps={{
                  restrictPosition: false,
                }}
                fillColor="transparent"
                showReset
                aspect={2.63 / 1}
              >
                <Upload
                  name="banner"
                  listType="picture"
                  customRequest={async ({ onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok");
                    }, 0);
                  }}
                  accept={
                    "image/png, image/jpeg, image/jpg, image/webp, image/tiff"
                  }
                  maxCount={1}
                  onChange={(e) => {
                    form?.setFieldsValue({
                      banner: normFile(e),
                    });
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>
        </Row>
        {/* Country */}
        <Row gutter={[24, 24]} className="mt-6">
          <Col span={4}>
            <Label>Country</Label>
            <Form.Item name="countryFilter">
              <Switch
                onChange={(value) => {
                  setCountryToggale(value);
                }}
                checked={countryToggale}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label required={countryToggale}>Country</Label>
            <Form.Item
              name="countryFilterValue"
              id="countryFilterValue"
              rules={[
                { required: countryToggale, message: "Please Select Country" },
              ]}
            >
              <Select
                disabled={!countryToggale}
                // mode="multiple"
                showSearch
                maxTagCount="responsive"
                placeholder={"Select country"}
                filterOption={(inputValue, option) => {
                  const value = option?.children?.props?.children[0]?.props
                    ?.children
                    ? `${option?.children?.props?.children[0]?.props?.children}`
                    : `${option?.children}`;
                  return value
                    ?.toLowerCase()
                    ?.includes(inputValue?.toLowerCase());
                }}
                allowClear // Allow clearing the selected value
              >
                {COUNTRY_ARRAY &&
                  COUNTRY_ARRAY?.length > 0 &&
                  COUNTRY_ARRAY?.map((item) => (
                    <Select.Option
                      key={`role_${item.id}`}
                      value={item.value.toLowerCase()}
                    >
                      {item.label ? item.label : item.value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="isAllowCountry">
              <Radio.Group className="mb-2">
                <Radio value={true}>Allow</Radio>
                <Radio value={false}>Block</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {/* city */}
        <Row gutter={[24, 24]}>
          <Col span={4}>
            <Label>State</Label>
            <Form.Item name="stateFilter">
              <Switch
                onChange={(value) => {
                  setStateToggale(value);
                }}
                checked={stateToggale}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label required={stateToggale}>State</Label>
            <Form.Item
              name="stateFilterValue"
              id="stateFilterValue"
              rules={[
                { required: stateToggale, message: "Please Select State" },
              ]}
            >
              <Select
                disabled={!stateToggale}
                mode="multiple"
                showSearch
                maxTagCount="responsive"
                placeholder={"Select state"}
                filterOption={(inputValue, option) => {
                  const value = option?.children?.props?.children[0]?.props
                    ?.children
                    ? `${option?.children?.props?.children[0]?.props?.children}`
                    : `${option?.children}`;
                  return value
                    ?.toLowerCase()
                    ?.includes(inputValue?.toLowerCase());
                }}
                allowClear // Allow clearing the selected value
              >
                {INDIAN_STATES_ARRAY &&
                  INDIAN_STATES_ARRAY?.length > 0 &&
                  INDIAN_STATES_ARRAY?.map((item) => (
                    <Select.Option
                      key={`role_${item.id}`}
                      value={item.value.toLowerCase()}
                    >
                      {item.label ? item.label : item.value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="isAllowState">
              <Radio.Group className="mb-2">
                <Radio value={true}>Allow</Radio>
                <Radio value={false}>Block</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={4}>
            <Label>City</Label>
            <Form.Item name="cityFilter">
              <Switch
                onChange={(value) => {
                  setCityToggale(value);
                }}
                checked={cityToggale}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label required={cityToggale}>City</Label>
            <Form.Item
              name="cityFilterValue"
              id="cityFilterValue"
              rules={[{ required: cityToggale, message: "Please Select City" }]}
            >
              <Select
                disabled={!cityToggale}
                mode="multiple"
                showSearch
                maxTagCount="responsive"
                placeholder={"Select city"}
                filterOption={(inputValue, option) => {
                  const value = option?.children?.props?.children[0]?.props
                    ?.children
                    ? `${option?.children?.props?.children[0]?.props?.children}`
                    : `${option?.children}`;
                  return value
                    ?.toLowerCase()
                    ?.includes(inputValue?.toLowerCase());
                }}
                allowClear // Allow clearing the selected value
              >
                {CITY_ARRAY &&
                  CITY_ARRAY?.length > 0 &&
                  CITY_ARRAY?.map((item) => (
                    <Select.Option
                      key={`role_${item.id}`}
                      value={item.value.toLowerCase()}
                    >
                      {item.label ? item.label : item.value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="isAllowCity">
              <Radio.Group className="mb-2">
                <Radio value={true}>Allow</Radio>
                <Radio value={false}>Block</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {/* Gender */}
        <Row gutter={[24, 24]}>
          <Col span={4}>
            <Label>Gender</Label>
            <Form.Item name="genderFilter">
              <Switch
                onChange={(value) => {
                  setGenderToggale(value);
                }}
                checked={genderToggale}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label required={genderToggale}>Gender</Label>
            <Form.Item
              name="genderFilterValue"
              id="genderFilterValue"
              rules={[
                { required: genderToggale, message: "Please Select Gender" },
              ]}
            >
              <Select
                disabled={!genderToggale}
                mode="multiple"
                showSearch
                maxTagCount="responsive"
                placeholder={"Select gender"}
                filterOption={(inputValue, option) => {
                  const value = option?.children?.props?.children[0]?.props
                    ?.children
                    ? `${option?.children?.props?.children[0]?.props?.children}`
                    : `${option?.children}`;
                  return value
                    ?.toLowerCase()
                    ?.includes(inputValue?.toLowerCase());
                }}
                allowClear // Allow clearing the selected value
              >
                {GENDER_ARRAY &&
                  GENDER_ARRAY?.length > 0 &&
                  GENDER_ARRAY?.map((item) => (
                    <Select.Option
                      key={`role_${item.id}`}
                      value={item.value.toLowerCase()}
                    >
                      {item.label ? item.label : item.value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="isAllowGender">
              <Radio.Group className="mb-2">
                <Radio value={true}>Allow</Radio>
                <Radio value={false}>Block</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {/* Age */}
        <Row gutter={[24, 24]}>
          <Col span={4}>
            <Label>Age</Label>
            <Form.Item name="ageWindowFilter">
              <Switch
                onChange={(value) => {
                  setAgeToggale(value);
                }}
                checked={AgeToggale}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label required={AgeToggale}>Age</Label>
            <Row gutter={[24, 24]}>
              <Col span={11}>
                <Form.Item
                  name="minAgeWindow"
                  rules={[
                    {
                      required: AgeToggale,
                      message: "Min age is required",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    placeholder="Enter min. age"
                    disabled={!AgeToggale}
                  />
                </Form.Item>
              </Col>
              -
              <Col span={11}>
                <Form.Item
                  name="maxAgeWindow"
                  rules={[
                    {
                      required: AgeToggale,
                      message: "Max age is required",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    placeholder="Enter max. age"
                    disabled={!AgeToggale}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={6} className="flex-col content-center">
            <Form.Item name="isAllowAgeWindow">
              <Radio.Group className="mb-2">
                <Radio value={true}>Allow</Radio>
                <Radio value={false}>Block</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {/* Tag */}
        <Row gutter={[24, 24]}>
          <Col span={4}>
            <Label>Tag</Label>
            <Form.Item name="tagFilter">
              <Switch
                onChange={(value) => {
                  setTagToggale(value);
                }}
                checked={tagToggale}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Label required={tagToggale}>Tag</Label>
            <Form.Item
              name="tagFilterValue"
              id="tagFilterValue"
              rules={[{ required: tagToggale, message: "Please Select Tag" }]}
            >
              <Select
                disabled={!tagToggale}
                mode="tags"
                showSearch
                maxTagCount="responsive"
                placeholder={"Select tag"}
                filterOption={(inputValue, option) => {
                  const value = option?.children?.props?.children[0]?.props
                    ?.children
                    ? `${option?.children?.props?.children[0]?.props?.children}`
                    : `${option?.children}`;
                  return value?.includes(inputValue);
                }}
                allowClear // Allow clearing the selected value
                onChange={(values, ds) => {
                  console.log(values, ds);
                  const existingValues = tagsOption.map((tag) => tag.value);
                  const newTags = values.filter(
                    (val) => !existingValues.includes(val)
                  );

                  if (newTags.length > 0) {
                    console.log("New tags added:", newTags);
                    // Handle the newly created tags (e.g., update state, call API, etc.)
                  }
                }}
              >
                {tagsOption &&
                  tagsOption?.length > 0 &&
                  tagsOption?.map((item) => (
                    <Select.Option key={`role_${item.id}`} value={item.value}>
                      {item.label ? item.label : item.value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="isAllowTag">
              <Radio.Group className="mb-2">
                <Radio value={true}>Allow</Radio>
                <Radio value={false}>Block</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TaskForm;
