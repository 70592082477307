import React, { memo } from "react";
import { Row } from "antd";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { convertUTCToLocal } from "../../../../util/functions";

const AffiliateHistory = ({ id }) => {
  return (
    <>
      <>
        <Row>
          <CRUDComponent
            GET={{
              API: CONSTANTS.API.affiliate.getAll,
              extraQuery: { userId: id },
              DataModifier: (res, API, Setrefresh) => {
                return res?.map((data) => {
                  return {
                    ...data,
                    no: data?.id,
                    ...data?.user,
                    createdDate: convertUTCToLocal(data?.user?.createdAt),
                    taskName: data?.task?.name,
                    amount: data?.task?.completionAmount,
                    walletBalanceA: data?.user?.walletBalance ?? 0,
                    affiliateAmount: data?.affiliateTaskStatuses?.[0]?.amount,
                  };
                });
              },
              column: CONSTANTS.TABLE.AFFILIATE_HISTORY,
            }}
            isSearch
          />
        </Row>
      </>
    </>
  );
};

export default memo(AffiliateHistory);
