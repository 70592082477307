import React, { useState } from "react";
import FormFields from "../../../component/common/FormFields";
import Papa from "papaparse";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Progress,
  Row,
  Select,
  Upload,
  message,
  notification,
} from "antd";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { TASK_APPROVAL_FORMS, apiGenerator } from "../../../util/functions";
import Label from "../../../component/common/Label";
import dayjs from "dayjs";
import Services from "../../../util/API/service";

const action = ["Accepted", "Reject", "Rework"];
const actionType = ["All Pending", "File", "Date"];

function BulkAction({ bulkAction, setBulkAction, api, id, setRealod }) {
  const [actionBy, setActionBy] = useState(null);
  const [fileDataType, setFileDataType] = useState(null);
  const [selectedDates, setSelectedDates] = useState({});
  const [activeForm, setActiveForm] = useState(null);
  const [numberList, setNumberList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [form] = Form.useForm();

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const clearAll = () => {
    setNumberList([]);
    setActionBy(null);
    setActiveForm(null);
    setFileDataType(null);
    setSelectedDates({});
    setProgress(null);
    setRealod((pre) => !pre);
    setBulkAction((pre) => !pre);
  };

  const approve = async (payload) => {
    if (!id) {
      notification.error({ message: "Somethin" });
      return;
    }
    const status =
      actionBy === "All Pending"
        ? `?status=Pending&taskId=${id}`
        : actionBy === "Date"
        ? `?status=Pending&taskId=${id}&startDate=${selectedDates?.startDate}&endDate=${selectedDates?.endDate}`
        : `?taskId=${id}`;

    setProgress(0);

    if (actionBy === "File" && numberList?.length) {
      let count = 0;

      for (const cd of numberList) {
        if (cd) {
          try {
            await delay(500);
            await Services.patch(
              CONSTANTS.API.UserTask.updateModal.endpoint.replace(
                ":dataId",
                cd
              ),
              payload
            );
          } catch (error) {
            console.log(error);
          }
        }
        count++;
        setProgress((count / numberList.length) * 100);
      }

      clearAll();
    } else
      api.sendRequest(
        apiGenerator(CONSTANTS.API.UserTask.getAll, {}, status),
        (res) => {
          api.sendRequest(
            apiGenerator(
              CONSTANTS.API.UserTask.getAll,
              {},
              status + "&limit=" + res.data?.count
            ),
            async (res) => {
              let count = 0;
              for (const element of res.data.rows) {
                if (element.status !== payload.status) {
                  try {
                    await delay(500);
                    await Services.patch(
                      CONSTANTS.API.UserTask.updateModal.endpoint.replace(
                        ":dataId",
                        element.id
                      ),
                      payload
                    );
                  } catch (error) {
                    console.log(error);
                  }
                }
                count++;
                setProgress((count / res.data?.count) * 100);
              }
              clearAll();
            }
          );
        }
      );
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (!actionBy) {
          notification.error({ message: "Please Select Action Type" });
          return;
        }
        if (actionBy && !activeForm) {
          notification.error({ message: "Please Select Action" });
          return;
        }
        if (actionBy === "File" && activeForm && !fileDataType) {
          notification.error({ message: "Please Select Action By" });
          return;
        }
        if (
          actionBy === "File" &&
          activeForm &&
          fileDataType &&
          !numberList?.length
        ) {
          notification.error({ message: "Please upload valid data file" });
          return;
        }
        if (
          actionBy === "Date" &&
          activeForm &&
          !Object.keys(selectedDates)?.length
        ) {
          notification.error({ message: "Please select date range" });
          return;
        }

        const payload = {
          ...values,
          status: activeForm,
        };
        approve(payload);
        // const BULK_UPDATE_API = apiGenerator(
        //   CONSTANTS.API.UserTask.bulkUpdate,
        //   {
        //     taskId: id,
        //   }
        // );
        // api.sendRequest(
        //   BULK_UPDATE_API,
        //   (res) => {
        //     if (!res) return;
        //     setBulkAction((pre) => !pre);
        //   },
        //   payload,
        //   "Bulk Action approved"
        // );
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleFileUpload = (file) => {
    if (file instanceof Blob) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        Papa.parse(csvData, {
          header: true,
          complete: (results) => {
            const mobileNumbers = results.data
              .map((row) => row[fileDataType])
              .filter(Boolean);
            setNumberList(mobileNumbers);
            console.log(mobileNumbers);
          },
          error: (error) => {
            message.error("Error parsing CSV file");
          },
        });
      };
      reader.readAsText(file);
    } else {
      message.error("File is not a valid Blob");
    }
  };

  const onChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedDates({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
    } else {
      setSelectedDates({});
    }
  };

  const uploadProps = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isCSV = file.type === "text/csv";
      if (!isCSV) {
        message.error("You can only upload CSV files!");
        return Upload.LIST_IGNORE;
      }
      handleFileUpload(file); // Process the file immediately
      return false; // Prevent automatic upload
    },
    maxCount: 1, // Limit to single file upload
  };

  const footer = {
    footer: [
      <Button
        key="submit"
        style={{
          borderRadius: "4px",
          height: "40px",
          width: "100px",
        }}
        type="primary"
        onClick={handleFormSubmit}
        loading={api.loading}
      >
        Submit
      </Button>,
    ],
  };
  return (
    <Modal
      open={bulkAction}
      title={<p className="form-modal-head mb25">Bulk Action</p>}
      okText="Submit"
      width={700}
      {...footer}
      cancelButtonProps={{ style: { display: "none" } }}
      className="form-modal-title"
      onCancel={() => {
        setBulkAction((pre) => !pre);
        setActiveForm(null);
      }}
      onOk={handleFormSubmit}
    >
      {progress ? <Progress percent={progress.toFixed(0)} size="small" /> : ""}
      <Row className="mt-5 mb-2" gutter={[16, 16]}>
        <Col span={6}>
          <Label required={true}>Action Type</Label>
          <Select
            className="w-[120px]"
            placeholder={"Select"}
            value={actionBy}
            allowClear
            onClear={() => {
              setActionBy(null);
              setActiveForm(null);
              setFileDataType(null);
              setSelectedDates({});
            }}
            onChange={(value) => {
              setActionBy(value);
            }}
          >
            {actionType.map((item) => (
              <Select.Option key={item}>{item}</Select.Option>
            ))}
          </Select>
          <div className="text-gray-400 mt-1">
            Select the option through which you want to take bulk action
          </div>
        </Col>
        {actionBy && (
          <Col span={6}>
            <Label required={true}>Action</Label>
            <Select
              className="w-[120px]"
              placeholder={"Select"}
              value={activeForm}
              allowClear
              onClear={() => {
                setActiveForm(null);
                setFileDataType(null);
                setSelectedDates({});
              }}
              onChange={(value) => {
                setActiveForm(value);
              }}
            >
              {action.map((item) => (
                <Select.Option key={item}>{item}</Select.Option>
              ))}
            </Select>
            <div className="text-gray-400 mt-1">
              Select the action you want to take
            </div>
          </Col>
        )}
        {actionBy === "Date" && activeForm && (
          <Col span={12}>
            <Label required={true}>Action</Label>
            <DatePicker.RangePicker
              className={"w-[15rem]"}
              allowClear={true}
              value={
                selectedDates?.startDate && selectedDates?.endDate
                  ? [
                      dayjs(selectedDates.startDate),
                      dayjs(selectedDates.endDate),
                    ]
                  : []
              }
              onChange={onChange}
              format="YYYY-MM-DD"
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
            />
            <div className="text-gray-400 mt-1">
              Select the date range for which you want to take action
            </div>
          </Col>
        )}
        {actionBy === "File" && activeForm && (
          <>
            <Col span={6}>
              <Label required={true}>Action By</Label>
              <Select
                className="w-[120px]"
                placeholder={"Select"}
                value={fileDataType}
                onClear={() => {
                  setFileDataType(null);
                }}
                onChange={(value) => {
                  setFileDataType(value);
                }}
              >
                {/* {["Click Id", "Mobile Number"]?.map((item) => (
                  <Select.Option key={item}>{item}</Select.Option>
                ))} */}
                {["Click Id"]?.map((item) => (
                  <Select.Option key={item}>{item}</Select.Option>
                ))}
              </Select>
              <div className="text-gray-400 mt-1">
                Select the option that you want to take action by targeting
              </div>
            </Col>
            {fileDataType && (
              <Col span={6}>
                <Label required={true}>File</Label>
                <Upload {...uploadProps} disabled={!actionBy}>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
                <div className="text-gray-400 mt-1">
                  Upload a file with the same header selected in the "Action By"
                  option
                </div>
              </Col>
            )}
          </>
        )}
      </Row>
      {activeForm && (
        <FormFields
          menuFields={
            CONSTANTS.FORM_FIELD[TASK_APPROVAL_FORMS[activeForm]?.form]
          }
          form={form}
          disabled={false}
        />
      )}
    </Modal>
  );
}

export default BulkAction;
