import { Button, Card, Form, notification, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import CRUDComponent, {
  payloadGenerator,
} from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import {
  apiGenerator,
  PERMISSION,
  TASK_LABEL_OPTION,
  TASK_STATUS_COLOR,
} from "../../../util/functions";
import { useNavigate } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import TaskForm from "./TaskForm";
import Heading from "../../../component/common/Heading";
import { taskExportData } from "../../../util/exportData";

const Task = ({ admindetails, permissionId }) => {
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(null);
  const [editData, setEditData] = useState(null);
  const api = useHttp();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [allBrand, setAllBrand] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  // const [isRegionBased, setIsRegionBased] = useState(false);
  const [isVisibleOnApp, setIsVisibleOnApp] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [platformTypeList, setPlatformTypeList] = useState([]);

  const [checkAll, setCheckAll] = useState(false);

  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];
  const onCLose = () => {
    setIsOpen(null);
    setEditData(null);
    setCheckAll(false);
    setCheckedList([]);
    // setIsRegionBased(false);
    setIsVisibleOnApp(false);
    form.resetFields();
  };

  const formDataToJson = (formData) => {
    const jsonObject = {};
    formData.forEach((value, key) => {
      if (jsonObject[key]) {
        // If key already exists, we need to handle it as an array
        if (Array.isArray(jsonObject[key])) {
          jsonObject[key].push(value);
        } else {
          jsonObject[key] = [jsonObject[key], value];
        }
      } else {
        jsonObject[key] = value;
      }
    });
    return jsonObject;
  };

  // console.log(editData);

  const onSubmit = (data) => {
    const fieldsF = [...CONSTANTS.FORM_FIELD.TASK_MODAL];
    if (editData)
      fieldsF.push({
        no: 17,
        id: "isCompleteEdit",
        type: "boolean",
      });
    const payload = payloadGenerator(
      {
        ...data,
        ...(editData && { isCompleteEdit: true }),
      },
      fieldsF,
      true
    );

    if (editData) {
      const UPDATE_TASK_API = apiGenerator(
        CONSTANTS.API.Task_Management.update,
        {},
        `${editData?.id}`
      );

      api.sendRequest(
        UPDATE_TASK_API,
        (res) => {
          // console.log(res);
          setRefresh((prev) => !prev);
          onCLose();
        },
        payload,
        "Update Task Successfully!!!"
      );
    } else {
      const CREATE_TASK_API = { ...CONSTANTS.API.Task_Management.add };

      api.sendRequest(
        CREATE_TASK_API,
        (res) => {
          // console.log(res);
          setRefresh((prev) => !prev);
          onCLose();
        },
        payload,
        "Create Task Successfully!!!"
      );
    }
  };

  useEffect(() => {
    const apiEndpoints = [
      CONSTANTS.API.Brand_Management.getAll,
      CONSTANTS.API.setting.task_category.getAll,
    ];
    api.sendBulkRequest(apiEndpoints, (res) => {
      // (CONSTANTS.TABLE.TASK?.find(ele => (ele.dataIndex === 'brandName')))['filters'] = res[1]?.data?.data?.rows?.map(ele => ({ text: ele?.name, label: ele?.name, value: ele?.name }))
      setAllBrand(
        res[0]?.data?.data?.rows?.map((ele) => ({
          label: ele?.name,
          value: ele?.id,
        }))
      );
      // (CONSTANTS.TABLE.TASK?.find(ele => (ele.dataIndex === 'category')))['filters'] = res[1]?.data?.data?.rows?.map(ele => ({ text: ele?.name, label: ele?.name, value: ele?.name }))
      setAllCategory(
        res[1]?.data?.data?.rows?.map((ele) => ({
          label: ele?.name,
          value: ele?.id,
        }))
      );
      // setAllCategoryList(res[1]?.data?.data?.rows?.map(ele => ({ label: ele?.name, value: ele?.id })))
    });
  }, []);
  return (
    <>
      <Card className="my-5">
        <Heading>Task List</Heading>
        <Row>
          {PERMISSION[accessPermission] !== "READ" && (
            <Button
              className="relative mt-3 top-3"
              onClick={() => setIsOpen(true)}
              type="primary"
            >
              Add
            </Button>
          )}
          <TaskForm
            allCategory={allCategory}
            allBrand={allBrand}
            form={form}
            open={isOpen || editData}
            onCancel={() => {
              onCLose();
            }}
            handleFormSubmit={onSubmit}
            formData={editData ? editData : {}}
            loading={api.isLoading}
            // isRegionBased={isRegionBased}
            // setIsRegionBased={setIsRegionBased}
            setPlatformTypeList={setPlatformTypeList}
            platformTypeList={platformTypeList}
            isVisibleOnApp={isVisibleOnApp}
            setIsVisibleOnApp={setIsVisibleOnApp}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            checkAll={checkAll}
            setCheckAll={setCheckAll}
          />

          <CRUDComponent
            title="Task"
            exportData={taskExportData}
            reload={refresh}
            accessPermission={accessPermission}
            GET={{
              API: CONSTANTS.API.Task_Management.getAll,
              DataModifier: (res, API, Setrefresh) => {
                return res?.map((data) => {
                  return {
                    ...data,
                    no: `T${data?.id.toString().padStart(7, "0")}`,
                    category: data?.taskCategory?.name,
                    brandName: data?.brand?.name,
                    amount: data?.completionAmount,
                    statusD: data?.status,
                    status: {
                      checked: data?.status !== "Active",
                      id: data?.id,
                      onClick: (id, val) => {
                        const UpdateAPIEnd = {
                          ...CONSTANTS.API.Task_Management.update,
                        };
                        UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                        API.sendRequest(
                          UpdateAPIEnd,
                          () => {
                            Setrefresh((prev) => !prev);
                          },
                          { status: val ? "Active" : "Inactive" },
                          `Task ${val ? "Active" : "Inactive"} Successfully`
                        );
                      },
                    },
                    label: (
                      <Select
                        disabled={PERMISSION[accessPermission] === "READ"}
                        className="w-28"
                        placeholder={"Select a Status"}
                        value={data?.tag}
                        onChange={(value) => {
                          // setTag({
                          //     id: data?.id,
                          //     tag: value
                          // })
                          const UPDATE_TAG_API = apiGenerator(
                            CONSTANTS.API.Task_Management.update,
                            {},
                            `${data?.id}`
                          );
                          api.sendRequest(
                            UPDATE_TAG_API,
                            (res) => {
                              setRefresh((prev) => !prev);
                            },
                            { tag: value },
                            "Update a Tag Successfully!!!"
                          );
                          // warning()
                        }}
                        allowClear // Allow clearing the selected value
                      >
                        {TASK_LABEL_OPTION.map((item) => (
                          <Select.Option
                            key={`role_${item.id}`}
                            value={item.value}
                          >
                            {item.label ? item.label : item.value}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                    view: {
                      id: data?.id,
                      onClick: (dataId) => {
                        navigate(`/app/task/${dataId}`);
                      },
                    },
                    isBestProject: {
                      checked: !data?.bestProject,
                      id: data?.id,
                      onClick: (id, val) => {
                        const UpdateAPIEnd = {
                          ...CONSTANTS.API.Task_Management.update,
                        };
                        UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                        API.sendRequest(
                          UpdateAPIEnd,
                          () => {
                            Setrefresh((prev) => !prev);
                          },
                          { bestProject: val },
                          `Task Update Successfully`
                        );
                      },
                    },
                    isAutoActive: {
                      checked: !data?.isAutoActive,
                      id: data?.id,
                      onClick: (id, val) => {
                        const UpdateAPIEnd = {
                          ...CONSTANTS.API.Task_Management.update,
                        };
                        UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                        API.sendRequest(
                          UpdateAPIEnd,
                          () => {
                            Setrefresh((prev) => !prev);
                          },
                          { isAutoActive: val },
                          `Task Update Successfully`
                        );
                      },
                    },
                    notification: {
                      id: data?.id,
                      onClick: (taskId) => {
                        // console.log(data);
                        api.sendRequest(
                          CONSTANTS.API.Task_Management.notification,
                          (res) => {
                            setEditData(res?.data);
                          },
                          { taskId },
                          "Notification sent successfully"
                        );
                      },
                    },
                    edit: {
                      id: data?.id,
                      onClick: () => {
                        // console.log(data);
                        const GET_DATA_API = apiGenerator(
                          CONSTANTS.API.Task_Management.getOne,
                          { id: data?.id }
                        );
                        api.sendRequest(GET_DATA_API, (res) => {
                          setEditData(res?.data);
                        });
                      },
                    },
                  };
                });
              },
              column:
                PERMISSION[accessPermission] !== "READ"
                  ? CONSTANTS.TABLE.TASK
                  : CONSTANTS.TABLE.TASK?.filter(
                      (ele) => ele.dataIndex !== "edit"
                    ),
            }}
            isSearch
            UPDATE={{
              API: CONSTANTS.API.Task_Management.update,
              isFormData: true,
              message: "Edit Task successfully",
              modaltitle: "Edit Task",
              modalFields: CONSTANTS.FORM_FIELD.EDIT_BRAND_MODAL,
              // payloadModifier: (res) => res,
            }}
            DELETE={{
              API: CONSTANTS.API.Task_Management.delete,
              message: "Deleted Task Successfully",
            }}
          />
        </Row>
      </Card>
    </>
  );
};

export default Task;
