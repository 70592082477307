import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import { apiGenerator } from "../../../util/functions";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import CommonTaskDetail from "../../../component/common/CommonTaskDetail";

const TaskDetail = ({ admindetails, permissionId }) => {
  const [taskData, setTaskData] = useState(null);
  const api = useHttp();
  const { id } = useParams();

  useEffect(() => {
    const TASK_DETAIL_API = apiGenerator(CONSTANTS.API.Task_Management.getOne, {
      id,
    });
    api.sendRequest(TASK_DETAIL_API, (res) => {
      setTaskData({
        ...res?.data,
        // isRegion: res?.data?.isRegionBased,
      });
    });
  }, []);
  return (
    <>
      <CommonTaskDetail
        {...taskData}
        type="task"
        isAdmin={admindetails?.role === "Admin"}
      />
    </>
  );
};

export default TaskDetail;
