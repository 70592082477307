import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FreeMode, Keyboard, Navigation, Pagination, Thumbs } from "swiper";
import { Card, Image } from "antd";
import { FaCloudDownloadAlt } from "react-icons/fa";

export default function WorkProof({ MultipleImages = {} }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const isBothAvl =
    MultipleImages?.proofOfWork?.length && MultipleImages?.taskStep?.length;
  const isImageUrl = (url) => {
    return /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(url);
  };

  const StepsUI = ({ steps }) => {
    return (
      <div className="max-w-md mx-auto">
        <h1 className="text-xl font-bold mb-4">
          Progress{" "}
          {MultipleImages?.progress ? (
            <div className="text-gray-600 text-sm mt-1">
              ({MultipleImages?.progress})
            </div>
          ) : (
            ""
          )}
        </h1>
        {steps?.map((step) => {
          const isCompleted =
            step?.userTaskSteps?.[0]?.status === "completed"
              ? "bg-green-500"
              : "bg-gray-400";

          return (
            <div key={step?.id} className="flex items-start mb-6">
              {/* Step Number */}
              <div
                className={`h-10 w-10 flex items-center justify-center rounded-full text-white font-bold shrink-0 ${isCompleted}`}
              >
                {step?.stepNumber}
              </div>

              {/* Step Details */}
              <div className="ml-4">
                <div className="font-semibold text-lg">{step?.name}</div>
                {step?.description && (
                  <div className="text-gray-600 text-sm mt-1">
                    {step?.description}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex">
      {MultipleImages?.taskStep?.length ? (
        <div style={{ width: isBothAvl ? "35%" : "100%" }}>
          <Card className="bg-gray-200 h-full">
            <StepsUI steps={MultipleImages?.taskStep} />
          </Card>
        </div>
      ) : (
        ""
      )}
      <div
        className={`customab h-full`}
        style={{
          width: MultipleImages?.proofOfWork?.length
            ? isBothAvl
              ? "65%"
              : "100%"
            : "0%",
        }}
      >
        {!isBothAvl && MultipleImages?.progress ? (
          <>
            <h1 className="text-base font-bold mb-4 inline-block me-2">
              Progress{" "}
            </h1>
            <div className="text-gray-600 text-sm mt-1 inline-block">
              ({MultipleImages?.progress})
            </div>
          </>
        ) : (
          ""
        )}
        <Swiper
          style={{
            "--swiper-navigation-color": "#000",
            "--swiper-pagination-color": "#000",
          }}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={10}
          navigation
          loop={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs, Pagination]}
          className={`!h-96`}
        >
          {MultipleImages?.proofOfWork?.map((pof, index) => (
            <>
              <SwiperSlide key={index} className="rounded-sandee">
                <div>
                  {pof?.stepName && (
                    <div>
                      {pof?.stepNumber}. {pof?.stepName}
                    </div>
                  )}
                  {isImageUrl(pof?.image) ? (
                    <Image
                      className="rounded-sandee transition-transform duration-1000 ease-in-out transform group-hover:scale-125 !h-80"
                      src={pof?.image}
                      preview={false}
                      alt={`Displayed image ${index + 1}`}
                    />
                  ) : (
                    <div className="flex flex-col">
                      <div>
                        File type is {pof?.image?.split(".")?.reverse()[0]}
                      </div>
                      <a className="flex gap-2 items-center" href={pof?.image}>
                        Download <FaCloudDownloadAlt size={20} />
                      </a>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            </>
          ))}
        </Swiper>

        {MultipleImages?.proofOfWork?.length > 1 && (
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={15}
            slidesPerView={7}
            loop={true}
            modules={[FreeMode, Navigation, Thumbs, Keyboard]}
            className="mySwiper"
          >
            {MultipleImages?.proofOfWork?.map((el, index) => (
              <SwiperSlide
                key={index}
                className="overflow-hidden rounded-md !w-[100px] !h-[75px] !md:w-[90px] !lg:w-[90px] !xl:w-[100px] !mx-2 !p-1 border-2"
              >
                {isImageUrl(el?.image) ? (
                  <Image
                    className=" rounded-md transition-transform duration-1000 ease-in-out transform group-hover:scale-125 w-full"
                    src={el?.image}
                    preview={false}
                  />
                ) : (
                  <div className="flex gap-2 items-center">
                    <FaCloudDownloadAlt size={30} color="#1677ff" />
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
}
