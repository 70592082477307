import { Card, Row } from "antd";
import Heading from "../../../component/common/Heading";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useParams } from "react-router-dom";

const TaskLogs = () => {
  const { id } = useParams();

  return (
    <>
      <div>
        <Card className="my-5">
          <Heading>Task Logs</Heading>
          <Row>
            <CRUDComponent
              title="Task Logs"
              GET={{
                API: CONSTANTS.API.Task_Management.taskLog,
                extraQuery: { taskId: id },
                DataModifier: (res, API, Setrefresh) => {
                  return res?.map((data) => {
                    return {
                      ...data,
                    };
                  });
                },
                column: CONSTANTS.TABLE.TASKLOGS,
              }}
            />
          </Row>
        </Card>
      </div>
    </>
  );
};

export default TaskLogs;
