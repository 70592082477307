import moment from "moment";
import { getAuthToken } from "./API/authStorage";

export const apiGenerator = (apiObject, exchangePair = {}, join = null) => {
  const apiObj = { ...apiObject };
  if (Object.keys(exchangePair).length) {
    Object.keys(exchangePair).forEach((el) => {
      apiObj.endpoint = apiObj.endpoint.replace(`:${el}`, exchangePair[el]);
    });
  }

  if (join) {
    apiObj.endpoint = `${apiObj.endpoint}${join}`;
  }
  return apiObj;
};


export const isLogin = () => {
  return getAuthToken() !== undefined && getAuthToken() !== null;
};

export const convertLocalToUTC = (localTime, format = 'YYYY-MM-DDTHH:mm:ss', utcFormat = 'YYYY-MM-DDTHH:mm:ss') => {
  return moment.utc(localTime, utcFormat).format(format);
}

export const convertUTCToLocal = (utcTime, format = 'YYYY-MM-DD HH:mm:ss') => {
  const localTime = moment.utc(utcTime).local().format(format);
  return localTime;
};


export const generateMenuFromRoutes = (routes = [], role, basePath = "") => {
  let menu = [];
  const targetedRole = role

  routes?.forEach((route) => {
    let nestedBasePath = route?.index
      ? basePath
      : route?.path
        ? route.path
        : "/";
    nestedBasePath = nestedBasePath.trim().replace("//", "/");
    const menuItem = {
      id: nestedBasePath,
      icon: route?.icon,
      label: route?.label,
      key: nestedBasePath,
    };

    if (route?.children && route?.children?.length > 0) {
      // Recursive call for nested children

      const children = generateMenuFromRoutes(
        route?.children,
        role,
        nestedBasePath
      );
      if (children.length) menuItem.children = children;
    }
    if (route?.icon && (!route?.Role || route?.Role?.some(r => targetedRole?.includes(r)))) {
      menu?.push(menuItem);
    } else if (
      menuItem?.children?.length &&
      (!route?.Role || route?.Role?.some(r => targetedRole?.includes(r)))
    ) {
      menu?.push(...menuItem.children);
    }
  });
  return menu;
};

export const generateRoutes = (routes = [], role) => {
  // console.log(routes);
  const targetedRole = role
  let Routes = [];
  routes?.forEach((route) => {
    const mainRoute = { ...route };
    if (route?.children && route?.children?.length > 0) {
      // Recursive call for nested children

      const children = generateRoutes(route?.children, role);
      if (children.length) mainRoute.children = children;
    }
    if (!route?.Role || route?.Role?.some(r => targetedRole?.includes(r))) {
      Routes?.push(mainRoute);
    } else if (
      mainRoute?.children?.length &&
      (!route?.Role || route?.Role?.some(r => targetedRole?.includes(r)))
    ) {
      Routes?.push(...mainRoute.children);
    }
  });
  // console.log(Routes, 'rou');
  return Routes;
};


export const PERMISSION = {
  READ: 0,
  WRITE_WITH_OUT_DELETE: 1,
  ALL: 2,
  0:'READ',
  1:'WRITE_WITH_OUT_DELETE',
  2:'ALL'

}


export const DEFAUlT_PERMISSION = [
  `admin-${PERMISSION.READ}`,
  `admin-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
  `admin-${PERMISSION.ALL}`,
]


export const getClearObject = (obj = {}) => {
  let newObj = {};
  Object.keys(obj).map((key) => {
    let item = obj[key];
    if (typeof item === "boolean") {
      newObj[key] = item;
    } else if (typeof item === "number") {
      newObj[key] = item;
    } else if (typeof item === "string") {
      if (item) {
        newObj[key] = item;
      }
    } else if (Array.isArray(item)) {
      if (item.length) {
        newObj[key] = item;
      }
    } else if (typeof item === "object") {
      // if object is type of date
      if (item instanceof Date) {
        if (!isNaN(item)) {
          newObj[key] = item;
        }
      } else if (Object?.keys(item || {})?.length) {
        newObj[key] = item;
      }
    }
  });

  return newObj;
};


export const TASK_STATUS_COLOR = {
  'Active': '#007BFF',
  'Completed': '#28A745',
  'Applied': '#7F7F7F',
  'Interview': '#FA8C16',
  'Rejected': '#FF4D4F',
  'Accepted': '#52C41A',
}


export const TASK_LABEL_OPTION = [
  {
    id: 1,
    label: 'None',
    value: null
  },
  {
    id: 2,
    label: 'New',
    value: 'new'
  },
  {
    id: 3,
    label: 'Popular',
    value: 'popular'
  },
  {
    id: 4,
    label: 'Hot',
    value: 'hot'
  },
]

export const TASK_APPROVAL_OPTION = [
  {
    id: 1,
    label: "Accepted",
    value: "Accepted",
  },
  {
    id: 2,
    label: "Reject",
    value: "Reject",
  },
  {
    id: 3,
    label: "Rework",
    value: "Rework",
  },
];

export const TASK_APPROVAL_FORMS = {
  Accepted : {
    message: "Task Approved",
    modaltitle: "Accept",
    form: "TASK_APPROVAL_MODAL"
  },
  Reject :{
    message: "Task Rejected",
    modaltitle: "Reject",
    form: "TASK_REJECT_MODAL"
  },
  Rework: {
    message: "Rework on Task",
    modaltitle: "Rework",
    form: "TASK_REMOVE_MODAL"
  },
}

export const getSelectionOption = () => {
  return [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    {
      value: "Monthly",
      label: "Monthly",
    },
    {
      value: "Quarterly",
      label: "Quarterly",
    },
    {
      value: "Yearly",
      label: "Yearly",
    },
  ];
};


export const TASK_FORM_OPTION = [   { label: 'jpeg', value: 'jpeg' },
  { label: 'jpg', value: 'jpg' },
  { label: 'png', value: 'png' },
  { label: 'pdf', value: 'pdf' },
  { label: 'mp4', value: 'mp4' },
  { label: 'ppt', value: 'ppt' },
  { label: 'docx', value: 'docx' },
  { label: 'excel', value: 'excel' },
  { label: 'word', value: 'word' },]

export const TASK_PLATFORM_OPTION = [
  { label: "Android", value: "allowAndroid" },
  { label: "iOS", value: "allowIOS" },
  { label: "Web", value: "allowWeb" },
];

export const INDIAN_STATES_ARRAY = [
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
];

export const GENDER_ARRAY = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

export const COUNTRY_ARRAY = [
  {
    "label": "India",
    "value": "India"
  },
]
  export const CITY_ARRAY = [
  {
    "label": "Abdul",
    "value": "Abdul"
  },
  {
    "label": "Adilabad",
    "value": "Adilabad"
  },
  {
    "label": "Adwani",
    "value": "Adwani"
  },
  {
    "label": "Agartala",
    "value": "Agartala"
  },
  {
    "label": "Agra",
    "value": "Agra"
  },
  {
    "label": "Ahmedabad",
    "value": "Ahmedabad"
  },
  {
    "label": "Ahmednagar",
    "value": "Ahmednagar"
  },
  {
    "label": "Aizawl",
    "value": "Aizawl"
  },
  {
    "label": "Ajabpur",
    "value": "Ajabpur"
  },
  {
    "label": "Ajmer",
    "value": "Ajmer"
  },
  {
    "label": "Akividu",
    "value": "Akividu"
  },
  {
    "label": "Akola",
    "value": "Akola"
  },
  {
    "label": "Alanallur",
    "value": "Alanallur"
  },
  {
    "label": "Alangulam",
    "value": "Alangulam"
  },
  {
    "label": "Alappuzha",
    "value": "Alappuzha"
  },
  {
    "label": "Aldona",
    "value": "Aldona"
  },
  {
    "label": "Alibag",
    "value": "Alibag"
  },
  {
    "label": "Aligarh",
    "value": "Aligarh"
  },
  {
    "label": "Alipur",
    "value": "Alipur"
  },
  {
    "label": "Allahabad",
    "value": "Allahabad"
  },
  {
    "label": "Almora",
    "value": "Almora"
  },
  {
    "label": "Aluva",
    "value": "Aluva"
  },
  {
    "label": "Alwar",
    "value": "Alwar"
  },
  {
    "label": "Amal",
    "value": "Amal"
  },
  {
    "label": "Amalapuram",
    "value": "Amalapuram"
  },
  {
    "label": "Ambad",
    "value": "Ambad"
  },
  {
    "label": "Ambah",
    "value": "Ambah"
  },
  {
    "label": "Ambala",
    "value": "Ambala"
  },
  {
    "label": "Ambarnath",
    "value": "Ambarnath"
  },
  {
    "label": "Ambejogai",
    "value": "Ambejogai"
  },
  {
    "label": "Ambikapur",
    "value": "Ambikapur"
  },
  {
    "label": "Ambur",
    "value": "Ambur"
  },
  {
    "label": "Amer",
    "value": "Amer"
  },
  {
    "label": "Amet",
    "value": "Amet"
  },
  {
    "label": "Amravati",
    "value": "Amravati"
  },
  {
    "label": "Amreli",
    "value": "Amreli"
  },
  {
    "label": "Amritsar",
    "value": "Amritsar"
  },
  {
    "label": "Anand",
    "value": "Anand"
  },
  {
    "label": "Anantapur",
    "value": "Anantapur"
  },
  {
    "label": "Anantnag",
    "value": "Anantnag"
  },
  {
    "label": "Anantpur",
    "value": "Anantpur"
  },
  {
    "label": "Anchal",
    "value": "Anchal"
  },
  {
    "label": "Andheri",
    "value": "Andheri"
  },
  {
    "label": "Andra",
    "value": "Andra"
  },
  {
    "label": "Angadipuram",
    "value": "Angadipuram"
  },
  {
    "label": "Angul",
    "value": "Angul"
  },
  {
    "label": "Ankleshwar",
    "value": "Ankleshwar"
  },
  {
    "label": "Annamalainagar",
    "value": "Annamalainagar"
  },
  {
    "label": "Antapur",
    "value": "Antapur"
  },
  {
    "label": "Arakkonam",
    "value": "Arakkonam"
  },
  {
    "label": "Arani",
    "value": "Arani"
  },
  {
    "label": "Aranmula",
    "value": "Aranmula"
  },
  {
    "label": "Arch",
    "value": "Arch"
  },
  {
    "label": "Ariyalur",
    "value": "Ariyalur"
  },
  {
    "label": "Arora",
    "value": "Arora"
  },
  {
    "label": "Arpora",
    "value": "Arpora"
  },
  {
    "label": "Arunachal",
    "value": "Arunachal"
  },
  {
    "label": "Arvi",
    "value": "Arvi"
  },
  {
    "label": "Asansol",
    "value": "Asansol"
  },
  {
    "label": "Assagao",
    "value": "Assagao"
  },
  {
    "label": "Attingal",
    "value": "Attingal"
  },
  {
    "label": "Attur",
    "value": "Attur"
  },
  {
    "label": "Aundh",
    "value": "Aundh"
  },
  {
    "label": "Aurangabad",
    "value": "Aurangabad"
  },
  {
    "label": "Avanigadda",
    "value": "Avanigadda"
  },
  {
    "label": "Azamgarh",
    "value": "Azamgarh"
  },
  {
    "label": "Baddi",
    "value": "Baddi"
  },
  {
    "label": "Badlapur",
    "value": "Badlapur"
  },
  {
    "label": "Bagalkot",
    "value": "Bagalkot"
  },
  {
    "label": "Bagh",
    "value": "Bagh"
  },
  {
    "label": "Bagpat",
    "value": "Bagpat"
  },
  {
    "label": "Bahadurgarh",
    "value": "Bahadurgarh"
  },
  {
    "label": "Baharampur",
    "value": "Baharampur"
  },
  {
    "label": "Baidyabati",
    "value": "Baidyabati"
  },
  {
    "label": "Bala",
    "value": "Bala"
  },
  {
    "label": "Balaghat",
    "value": "Balaghat"
  },
  {
    "label": "Balana",
    "value": "Balana"
  },
  {
    "label": "Balanagar",
    "value": "Balanagar"
  },
  {
    "label": "Balangir",
    "value": "Balangir"
  },
  {
    "label": "Balasore",
    "value": "Balasore"
  },
  {
    "label": "Bali",
    "value": "Bali"
  },
  {
    "label": "Ballabgarh",
    "value": "Ballabgarh"
  },
  {
    "label": "Balu",
    "value": "Balu"
  },
  {
    "label": "Balurghat",
    "value": "Balurghat"
  },
  {
    "label": "Bambolim",
    "value": "Bambolim"
  },
  {
    "label": "Banda",
    "value": "Banda"
  },
  {
    "label": "Bandra",
    "value": "Bandra"
  },
  {
    "label": "Banga",
    "value": "Banga"
  },
  {
    "label": "Bangalore",
    "value": "Bangalore"
  },
  {
    "label": "Bangaon",
    "value": "Bangaon"
  },
  {
    "label": "Bank",
    "value": "Bank"
  },
  {
    "label": "Banka",
    "value": "Banka"
  },
  {
    "label": "Bankura",
    "value": "Bankura"
  },
  {
    "label": "Banswara",
    "value": "Banswara"
  },
  {
    "label": "Bapatla",
    "value": "Bapatla"
  },
  {
    "label": "Barakpur",
    "value": "Barakpur"
  },
  {
    "label": "Baramati",
    "value": "Baramati"
  },
  {
    "label": "Barddhaman",
    "value": "Barddhaman"
  },
  {
    "label": "Bardoli",
    "value": "Bardoli"
  },
  {
    "label": "Bareilly",
    "value": "Bareilly"
  },
  {
    "label": "Bargarh",
    "value": "Bargarh"
  },
  {
    "label": "Barmer",
    "value": "Barmer"
  },
  {
    "label": "Barnala",
    "value": "Barnala"
  },
  {
    "label": "Baroda",
    "value": "Baroda"
  },
  {
    "label": "Barpali",
    "value": "Barpali"
  },
  {
    "label": "Barpeta",
    "value": "Barpeta"
  },
  {
    "label": "Basirhat",
    "value": "Basirhat"
  },
  {
    "label": "Basti",
    "value": "Basti"
  },
  {
    "label": "Basu",
    "value": "Basu"
  },
  {
    "label": "Batala",
    "value": "Batala"
  },
  {
    "label": "Bawan",
    "value": "Bawan"
  },
  {
    "label": "Bawana",
    "value": "Bawana"
  },
  {
    "label": "Beawar",
    "value": "Beawar"
  },
  {
    "label": "Begusarai",
    "value": "Begusarai"
  },
  {
    "label": "Behala",
    "value": "Behala"
  },
  {
    "label": "Bela",
    "value": "Bela"
  },
  {
    "label": "Belapur",
    "value": "Belapur"
  },
  {
    "label": "Belgaum",
    "value": "Belgaum"
  },
  {
    "label": "Belgharia",
    "value": "Belgharia"
  },
  {
    "label": "Bellare",
    "value": "Bellare"
  },
  {
    "label": "Bellary",
    "value": "Bellary"
  },
  {
    "label": "Bemetara",
    "value": "Bemetara"
  },
  {
    "label": "Berasia",
    "value": "Berasia"
  },
  {
    "label": "Betalbatim",
    "value": "Betalbatim"
  },
  {
    "label": "Betim",
    "value": "Betim"
  },
  {
    "label": "Betul",
    "value": "Betul"
  },
  {
    "label": "Bhadath",
    "value": "Bhadath"
  },
  {
    "label": "Bhadohi",
    "value": "Bhadohi"
  },
  {
    "label": "Bhadravati",
    "value": "Bhadravati"
  },
  {
    "label": "Bhagalpur",
    "value": "Bhagalpur"
  },
  {
    "label": "Bhagwan",
    "value": "Bhagwan"
  },
  {
    "label": "Bhandari",
    "value": "Bhandari"
  },
  {
    "label": "Bhandup",
    "value": "Bhandup"
  },
  {
    "label": "Bharatpur",
    "value": "Bharatpur"
  },
  {
    "label": "Bharuch",
    "value": "Bharuch"
  },
  {
    "label": "Bhatapara",
    "value": "Bhatapara"
  },
  {
    "label": "Bhatinda",
    "value": "Bhatinda"
  },
  {
    "label": "Bhatkal",
    "value": "Bhatkal"
  },
  {
    "label": "Bhavnagar",
    "value": "Bhavnagar"
  },
  {
    "label": "Bhawan",
    "value": "Bhawan"
  },
  {
    "label": "Bhilai",
    "value": "Bhilai"
  },
  {
    "label": "Bhilwara",
    "value": "Bhilwara"
  },
  {
    "label": "Bhimavaram",
    "value": "Bhimavaram"
  },
  {
    "label": "Bhiwandi",
    "value": "Bhiwandi"
  },
  {
    "label": "Bhiwani",
    "value": "Bhiwani"
  },
  {
    "label": "Bhoj",
    "value": "Bhoj"
  },
  {
    "label": "Bhongir",
    "value": "Bhongir"
  },
  {
    "label": "Bhopal",
    "value": "Bhopal"
  },
  {
    "label": "Bhubaneswar",
    "value": "Bhubaneswar"
  },
  {
    "label": "Bhuj",
    "value": "Bhuj"
  },
  {
    "label": "Bhusawal",
    "value": "Bhusawal"
  },
  {
    "label": "Bichpuri",
    "value": "Bichpuri"
  },
  {
    "label": "Bidar",
    "value": "Bidar"
  },
  {
    "label": "Bihar Sharif",
    "value": "Bihar Sharif"
  },
  {
    "label": "Bijapur",
    "value": "Bijapur"
  },
  {
    "label": "Bikaner",
    "value": "Bikaner"
  },
  {
    "label": "Bilaspur",
    "value": "Bilaspur"
  },
  {
    "label": "Bilimora",
    "value": "Bilimora"
  },
  {
    "label": "Binavas",
    "value": "Binavas"
  },
  {
    "label": "Binnaguri",
    "value": "Binnaguri"
  },
  {
    "label": "Bishnupur",
    "value": "Bishnupur"
  },
  {
    "label": "Bobbili",
    "value": "Bobbili"
  },
  {
    "label": "Bodhan",
    "value": "Bodhan"
  },
  {
    "label": "Bodinayakkanur",
    "value": "Bodinayakkanur"
  },
  {
    "label": "Boisar",
    "value": "Boisar"
  },
  {
    "label": "Bokaro",
    "value": "Bokaro"
  },
  {
    "label": "Bolpur",
    "value": "Bolpur"
  },
  {
    "label": "Botad",
    "value": "Botad"
  },
  {
    "label": "Brahmapur",
    "value": "Brahmapur"
  },
  {
    "label": "Budaun",
    "value": "Budaun"
  },
  {
    "label": "Budbud",
    "value": "Budbud"
  },
  {
    "label": "Budha",
    "value": "Budha"
  },
  {
    "label": "Bulandshahr",
    "value": "Bulandshahr"
  },
  {
    "label": "Bundi",
    "value": "Bundi"
  },
  {
    "label": "Calangute",
    "value": "Calangute"
  },
  {
    "label": "Candolim",
    "value": "Candolim"
  },
  {
    "label": "Canning",
    "value": "Canning"
  },
  {
    "label": "Caranzalem",
    "value": "Caranzalem"
  },
  {
    "label": "Chakan",
    "value": "Chakan"
  },
  {
    "label": "Chakra",
    "value": "Chakra"
  },
  {
    "label": "Chalisgaon",
    "value": "Chalisgaon"
  },
  {
    "label": "Chamba",
    "value": "Chamba"
  },
  {
    "label": "Champa",
    "value": "Champa"
  },
  {
    "label": "Chand",
    "value": "Chand"
  },
  {
    "label": "Chandan",
    "value": "Chandan"
  },
  {
    "label": "Chandannagar",
    "value": "Chandannagar"
  },
  {
    "label": "Chandauli",
    "value": "Chandauli"
  },
  {
    "label": "Chandausi",
    "value": "Chandausi"
  },
  {
    "label": "Chandigarh",
    "value": "Chandigarh"
  },
  {
    "label": "Chandrapur",
    "value": "Chandrapur"
  },
  {
    "label": "Changanacheri",
    "value": "Changanacheri"
  },
  {
    "label": "Channapatna",
    "value": "Channapatna"
  },
  {
    "label": "Charan",
    "value": "Charan"
  },
  {
    "label": "Charu",
    "value": "Charu"
  },
  {
    "label": "Chen",
    "value": "Chen"
  },
  {
    "label": "Chengannur",
    "value": "Chengannur"
  },
  {
    "label": "Chennai",
    "value": "Chennai"
  },
  {
    "label": "Chetan",
    "value": "Chetan"
  },
  {
    "label": "Cheyyar",
    "value": "Cheyyar"
  },
  {
    "label": "Chhabra",
    "value": "Chhabra"
  },
  {
    "label": "Chhachhrauli",
    "value": "Chhachhrauli"
  },
  {
    "label": "Chhota Udepur",
    "value": "Chhota Udepur"
  },
  {
    "label": "Chicalim",
    "value": "Chicalim"
  },
  {
    "label": "Chidambaram",
    "value": "Chidambaram"
  },
  {
    "label": "Chikodi",
    "value": "Chikodi"
  },
  {
    "label": "Chinchvad",
    "value": "Chinchvad"
  },
  {
    "label": "Chintamani",
    "value": "Chintamani"
  },
  {
    "label": "Chiplun",
    "value": "Chiplun"
  },
  {
    "label": "Chirala",
    "value": "Chirala"
  },
  {
    "label": "Chitra",
    "value": "Chitra"
  },
  {
    "label": "Chitradurga",
    "value": "Chitradurga"
  },
  {
    "label": "Chittoor",
    "value": "Chittoor"
  },
  {
    "label": "Chittur",
    "value": "Chittur"
  },
  {
    "label": "Choolai",
    "value": "Choolai"
  },
  {
    "label": "Chopda",
    "value": "Chopda"
  },
  {
    "label": "Chopra",
    "value": "Chopra"
  },
  {
    "label": "Churachandpur",
    "value": "Churachandpur"
  },
  {
    "label": "Coimbatore",
    "value": "Coimbatore"
  },
  {
    "label": "Colaba",
    "value": "Colaba"
  },
  {
    "label": "Connaught Place",
    "value": "Connaught Place"
  },
  {
    "label": "Coonoor",
    "value": "Coonoor"
  },
  {
    "label": "Cuddalore",
    "value": "Cuddalore"
  },
  {
    "label": "Cumbum",
    "value": "Cumbum"
  },
  {
    "label": "Cuncolim",
    "value": "Cuncolim"
  },
  {
    "label": "Curchorem",
    "value": "Curchorem"
  },
  {
    "label": "Cuttack",
    "value": "Cuttack"
  },
  {
    "label": "Dadri",
    "value": "Dadri"
  },
  {
    "label": "Dahanu",
    "value": "Dahanu"
  },
  {
    "label": "Dahod",
    "value": "Dahod"
  },
  {
    "label": "Dam Dam",
    "value": "Dam Dam"
  },
  {
    "label": "Daman",
    "value": "Daman"
  },
  {
    "label": "Damoh",
    "value": "Damoh"
  },
  {
    "label": "Dang",
    "value": "Dang"
  },
  {
    "label": "Dangi",
    "value": "Dangi"
  },
  {
    "label": "Darbhanga",
    "value": "Darbhanga"
  },
  {
    "label": "Darjeeling",
    "value": "Darjeeling"
  },
  {
    "label": "Darsi",
    "value": "Darsi"
  },
  {
    "label": "Dasna",
    "value": "Dasna"
  },
  {
    "label": "Dasua",
    "value": "Dasua"
  },
  {
    "label": "Davangere",
    "value": "Davangere"
  },
  {
    "label": "Dehradun",
    "value": "Dehradun"
  },
  {
    "label": "Delhi",
    "value": "Delhi"
  },
  {
    "label": "Deolali",
    "value": "Deolali"
  },
  {
    "label": "Deoria",
    "value": "Deoria"
  },
  {
    "label": "Devgarh",
    "value": "Devgarh"
  },
  {
    "label": "Devipattinam",
    "value": "Devipattinam"
  },
  {
    "label": "Dewas",
    "value": "Dewas"
  },
  {
    "label": "Dhaka",
    "value": "Dhaka"
  },
  {
    "label": "Dhamtari",
    "value": "Dhamtari"
  },
  {
    "label": "Dhanbad",
    "value": "Dhanbad"
  },
  {
    "label": "Dhansura",
    "value": "Dhansura"
  },
  {
    "label": "Dhar",
    "value": "Dhar"
  },
  {
    "label": "Dharamsala",
    "value": "Dharamsala"
  },
  {
    "label": "Dharapuram",
    "value": "Dharapuram"
  },
  {
    "label": "Dharavi",
    "value": "Dharavi"
  },
  {
    "label": "Dhariwal",
    "value": "Dhariwal"
  },
  {
    "label": "Dharmapuri",
    "value": "Dharmapuri"
  },
  {
    "label": "Dharwad",
    "value": "Dharwad"
  },
  {
    "label": "Dhenkanal",
    "value": "Dhenkanal"
  },
  {
    "label": "Dhone",
    "value": "Dhone"
  },
  {
    "label": "Dhrol",
    "value": "Dhrol"
  },
  {
    "label": "Dhubri",
    "value": "Dhubri"
  },
  {
    "label": "Dhule",
    "value": "Dhule"
  },
  {
    "label": "Dhuri",
    "value": "Dhuri"
  },
  {
    "label": "Dibrugarh",
    "value": "Dibrugarh"
  },
  {
    "label": "Dicholi",
    "value": "Dicholi"
  },
  {
    "label": "Dimapur",
    "value": "Dimapur"
  },
  {
    "label": "Dinanagar",
    "value": "Dinanagar"
  },
  {
    "label": "Dindigul",
    "value": "Dindigul"
  },
  {
    "label": "Dindori",
    "value": "Dindori"
  },
  {
    "label": "Dipas",
    "value": "Dipas"
  },
  {
    "label": "Dogadda",
    "value": "Dogadda"
  },
  {
    "label": "Dona Paula",
    "value": "Dona Paula"
  },
  {
    "label": "Dumka",
    "value": "Dumka"
  },
  {
    "label": "Durg",
    "value": "Durg"
  },
  {
    "label": "Durgapur",
    "value": "Durgapur"
  },
  {
    "label": "Dwarahat",
    "value": "Dwarahat"
  },
  {
    "label": "Dwarka",
    "value": "Dwarka"
  },
  {
    "label": "Edavanna",
    "value": "Edavanna"
  },
  {
    "label": "Ekkattuthangal",
    "value": "Ekkattuthangal"
  },
  {
    "label": "Ellora Caves",
    "value": "Ellora Caves"
  },
  {
    "label": "Eluru",
    "value": "Eluru"
  },
  {
    "label": "Eral",
    "value": "Eral"
  },
  {
    "label": "Ernakulam",
    "value": "Ernakulam"
  },
  {
    "label": "Erode",
    "value": "Erode"
  },
  {
    "label": "Etawah",
    "value": "Etawah"
  },
  {
    "label": "Faizabad",
    "value": "Faizabad"
  },
  {
    "label": "Farakka",
    "value": "Farakka"
  },
  {
    "label": "Faridabad",
    "value": "Faridabad"
  },
  {
    "label": "Faridkot",
    "value": "Faridkot"
  },
  {
    "label": "Fatehabad",
    "value": "Fatehabad"
  },
  {
    "label": "Fatehgarh",
    "value": "Fatehgarh"
  },
  {
    "label": "Fatehpur",
    "value": "Fatehpur"
  },
  {
    "label": "Firozabad",
    "value": "Firozabad"
  },
  {
    "label": "Firozpur",
    "value": "Firozpur"
  },
  {
    "label": "Fort",
    "value": "Fort"
  },
  {
    "label": "Gadag",
    "value": "Gadag"
  },
  {
    "label": "Gampalagudem",
    "value": "Gampalagudem"
  },
  {
    "label": "Gandhidham",
    "value": "Gandhidham"
  },
  {
    "label": "Gandhigram",
    "value": "Gandhigram"
  },
  {
    "label": "Gandhinagar",
    "value": "Gandhinagar"
  },
  {
    "label": "Ganga",
    "value": "Ganga"
  },
  {
    "label": "Ganganagar",
    "value": "Ganganagar"
  },
  {
    "label": "Gangapur",
    "value": "Gangapur"
  },
  {
    "label": "Gangrar",
    "value": "Gangrar"
  },
  {
    "label": "Gangtok",
    "value": "Gangtok"
  },
  {
    "label": "Gannavaram",
    "value": "Gannavaram"
  },
  {
    "label": "Ganpat",
    "value": "Ganpat"
  },
  {
    "label": "Gargoti",
    "value": "Gargoti"
  },
  {
    "label": "Garhshankar",
    "value": "Garhshankar"
  },
  {
    "label": "Gaya",
    "value": "Gaya"
  },
  {
    "label": "Ghana",
    "value": "Ghana"
  },
  {
    "label": "Ghatal",
    "value": "Ghatal"
  },
  {
    "label": "Ghatkopar",
    "value": "Ghatkopar"
  },
  {
    "label": "Ghaziabad",
    "value": "Ghaziabad"
  },
  {
    "label": "Goa",
    "value": "Goa"
  },
  {
    "label": "Gobichettipalayam",
    "value": "Gobichettipalayam"
  },
  {
    "label": "Godhra",
    "value": "Godhra"
  },
  {
    "label": "Gohana",
    "value": "Gohana"
  },
  {
    "label": "Golaghat",
    "value": "Golaghat"
  },
  {
    "label": "Gold",
    "value": "Gold"
  },
  {
    "label": "Gonda",
    "value": "Gonda"
  },
  {
    "label": "Gorakhpur",
    "value": "Gorakhpur"
  },
  {
    "label": "Goregaon",
    "value": "Goregaon"
  },
  {
    "label": "Goshaingaon",
    "value": "Goshaingaon"
  },
  {
    "label": "Gudivada",
    "value": "Gudivada"
  },
  {
    "label": "Gudur",
    "value": "Gudur"
  },
  {
    "label": "Guindy",
    "value": "Guindy"
  },
  {
    "label": "Gujrat",
    "value": "Gujrat"
  },
  {
    "label": "Gulbarga",
    "value": "Gulbarga"
  },
  {
    "label": "Guna",
    "value": "Guna"
  },
  {
    "label": "Guntur",
    "value": "Guntur"
  },
  {
    "label": "Gurdaspur",
    "value": "Gurdaspur"
  },
  {
    "label": "Gurgaon",
    "value": "Gurgaon"
  },
  {
    "label": "Guruvayur",
    "value": "Guruvayur"
  },
  {
    "label": "Guwahati",
    "value": "Guwahati"
  },
  {
    "label": "Gwalior",
    "value": "Gwalior"
  },
  {
    "label": "Habra",
    "value": "Habra"
  },
  {
    "label": "Hadadi",
    "value": "Hadadi"
  },
  {
    "label": "Haldia",
    "value": "Haldia"
  },
  {
    "label": "Haldwani",
    "value": "Haldwani"
  },
  {
    "label": "Hamirpur",
    "value": "Hamirpur"
  },
  {
    "label": "Hansi",
    "value": "Hansi"
  },
  {
    "label": "Hapur",
    "value": "Hapur"
  },
  {
    "label": "Hari",
    "value": "Hari"
  },
  {
    "label": "Haridwar",
    "value": "Haridwar"
  },
  {
    "label": "Haripad",
    "value": "Haripad"
  },
  {
    "label": "Haripur",
    "value": "Haripur"
  },
  {
    "label": "Haryana",
    "value": "Haryana"
  },
  {
    "label": "Hassan",
    "value": "Hassan"
  },
  {
    "label": "Haveri",
    "value": "Haveri"
  },
  {
    "label": "Hazaribagh",
    "value": "Hazaribagh"
  },
  {
    "label": "Himatnagar",
    "value": "Himatnagar"
  },
  {
    "label": "Hinganghat",
    "value": "Hinganghat"
  },
  {
    "label": "Hingoli",
    "value": "Hingoli"
  },
  {
    "label": "Hira",
    "value": "Hira"
  },
  {
    "label": "Hiriyur",
    "value": "Hiriyur"
  },
  {
    "label": "Hisar",
    "value": "Hisar"
  },
  {
    "label": "Honavar",
    "value": "Honavar"
  },
  {
    "label": "Hong",
    "value": "Hong"
  },
  {
    "label": "Hoshangabad",
    "value": "Hoshangabad"
  },
  {
    "label": "Hoshiarpur",
    "value": "Hoshiarpur"
  },
  {
    "label": "Hosur",
    "value": "Hosur"
  },
  {
    "label": "Howrah",
    "value": "Howrah"
  },
  {
    "label": "Hubli",
    "value": "Hubli"
  },
  {
    "label": "Hugli",
    "value": "Hugli"
  },
  {
    "label": "Hyderabad",
    "value": "Hyderabad"
  },
  {
    "label": "Ichalkaranji",
    "value": "Ichalkaranji"
  },
  {
    "label": "Idukki",
    "value": "Idukki"
  },
  {
    "label": "Igatpuri",
    "value": "Igatpuri"
  },
  {
    "label": "Iglas",
    "value": "Iglas"
  },
  {
    "label": "Imphal",
    "value": "Imphal"
  },
  {
    "label": "Indore",
    "value": "Indore"
  },
  {
    "label": "Indraprast",
    "value": "Indraprast"
  },
  {
    "label": "Irinjalakuda",
    "value": "Irinjalakuda"
  },
  {
    "label": "Itanagar",
    "value": "Itanagar"
  },
  {
    "label": "Jabalpur",
    "value": "Jabalpur"
  },
  {
    "label": "Jadabpur",
    "value": "Jadabpur"
  },
  {
    "label": "Jagdalpur",
    "value": "Jagdalpur"
  },
  {
    "label": "Jagraon",
    "value": "Jagraon"
  },
  {
    "label": "Jaipur",
    "value": "Jaipur"
  },
  {
    "label": "Jaisalmer",
    "value": "Jaisalmer"
  },
  {
    "label": "Jajpur",
    "value": "Jajpur"
  },
  {
    "label": "Jalalabad",
    "value": "Jalalabad"
  },
  {
    "label": "Jalalpur",
    "value": "Jalalpur"
  },
  {
    "label": "Jalandhar",
    "value": "Jalandhar"
  },
  {
    "label": "Jalesar",
    "value": "Jalesar"
  },
  {
    "label": "Jalgaon Jamod",
    "value": "Jalgaon Jamod"
  },
  {
    "label": "Jalna",
    "value": "Jalna"
  },
  {
    "label": "Jalpaiguri",
    "value": "Jalpaiguri"
  },
  {
    "label": "Jamal",
    "value": "Jamal"
  },
  {
    "label": "Jammu",
    "value": "Jammu"
  },
  {
    "label": "Jamnagar",
    "value": "Jamnagar"
  },
  {
    "label": "Jamshedpur",
    "value": "Jamshedpur"
  },
  {
    "label": "Janjgir",
    "value": "Janjgir"
  },
  {
    "label": "Jaspur",
    "value": "Jaspur"
  },
  {
    "label": "Jatani",
    "value": "Jatani"
  },
  {
    "label": "Jaunpur",
    "value": "Jaunpur"
  },
  {
    "label": "Jayanti",
    "value": "Jayanti"
  },
  {
    "label": "Jaynagar",
    "value": "Jaynagar"
  },
  {
    "label": "Jaypur",
    "value": "Jaypur"
  },
  {
    "label": "Jha Jha",
    "value": "Jha Jha"
  },
  {
    "label": "Jhajjar",
    "value": "Jhajjar"
  },
  {
    "label": "Jhalawar",
    "value": "Jhalawar"
  },
  {
    "label": "Jhansi",
    "value": "Jhansi"
  },
  {
    "label": "Jhargram",
    "value": "Jhargram"
  },
  {
    "label": "Jharsuguda",
    "value": "Jharsuguda"
  },
  {
    "label": "Jhunjhunun",
    "value": "Jhunjhunun"
  },
  {
    "label": "Jind",
    "value": "Jind"
  },
  {
    "label": "Jodhpur",
    "value": "Jodhpur"
  },
  {
    "label": "Jorhat",
    "value": "Jorhat"
  },
  {
    "label": "Junagadh",
    "value": "Junagadh"
  },
  {
    "label": "Kadapa",
    "value": "Kadapa"
  },
  {
    "label": "Kagal",
    "value": "Kagal"
  },
  {
    "label": "Kailaras",
    "value": "Kailaras"
  },
  {
    "label": "Kaimganj",
    "value": "Kaimganj"
  },
  {
    "label": "Kaithal",
    "value": "Kaithal"
  },
  {
    "label": "Kakdwip",
    "value": "Kakdwip"
  },
  {
    "label": "Kakinada",
    "value": "Kakinada"
  },
  {
    "label": "Kaladi",
    "value": "Kaladi"
  },
  {
    "label": "Kalam",
    "value": "Kalam"
  },
  {
    "label": "Kalamboli",
    "value": "Kalamboli"
  },
  {
    "label": "Kalan",
    "value": "Kalan"
  },
  {
    "label": "Kalinga",
    "value": "Kalinga"
  },
  {
    "label": "Kalka",
    "value": "Kalka"
  },
  {
    "label": "Kalkaji Devi",
    "value": "Kalkaji Devi"
  },
  {
    "label": "Kalol",
    "value": "Kalol"
  },
  {
    "label": "Kalpakkam",
    "value": "Kalpakkam"
  },
  {
    "label": "Kalpetta",
    "value": "Kalpetta"
  },
  {
    "label": "Kalra",
    "value": "Kalra"
  },
  {
    "label": "Kalyan",
    "value": "Kalyan"
  },
  {
    "label": "Kalyani",
    "value": "Kalyani"
  },
  {
    "label": "Kamalpur",
    "value": "Kamalpur"
  },
  {
    "label": "Kamalpura",
    "value": "Kamalpura"
  },
  {
    "label": "Kamat",
    "value": "Kamat"
  },
  {
    "label": "Kanakpura",
    "value": "Kanakpura"
  },
  {
    "label": "Kanchipuram",
    "value": "Kanchipuram"
  },
  {
    "label": "Kanchrapara",
    "value": "Kanchrapara"
  },
  {
    "label": "Kandi",
    "value": "Kandi"
  },
  {
    "label": "Kangayam",
    "value": "Kangayam"
  },
  {
    "label": "Kangra",
    "value": "Kangra"
  },
  {
    "label": "Kanhangad",
    "value": "Kanhangad"
  },
  {
    "label": "Kanigiri",
    "value": "Kanigiri"
  },
  {
    "label": "Kaniyambadi",
    "value": "Kaniyambadi"
  },
  {
    "label": "Kankauli",
    "value": "Kankauli"
  },
  {
    "label": "Kanniyakumari",
    "value": "Kanniyakumari"
  },
  {
    "label": "Kannur",
    "value": "Kannur"
  },
  {
    "label": "Kanpur",
    "value": "Kanpur"
  },
  {
    "label": "Kapurthala Town",
    "value": "Kapurthala Town"
  },
  {
    "label": "Karad",
    "value": "Karad"
  },
  {
    "label": "Karaikal",
    "value": "Karaikal"
  },
  {
    "label": "Karaikudi",
    "value": "Karaikudi"
  },
  {
    "label": "Karamadai",
    "value": "Karamadai"
  },
  {
    "label": "Karamsad",
    "value": "Karamsad"
  },
  {
    "label": "Karanja",
    "value": "Karanja"
  },
  {
    "label": "Karari",
    "value": "Karari"
  },
  {
    "label": "Kargil",
    "value": "Kargil"
  },
  {
    "label": "Karimganj",
    "value": "Karimganj"
  },
  {
    "label": "Karimnagar",
    "value": "Karimnagar"
  },
  {
    "label": "Karjat",
    "value": "Karjat"
  },
  {
    "label": "Karnal",
    "value": "Karnal"
  },
  {
    "label": "Karsiyang",
    "value": "Karsiyang"
  },
  {
    "label": "Karur",
    "value": "Karur"
  },
  {
    "label": "Karwar",
    "value": "Karwar"
  },
  {
    "label": "Kasal",
    "value": "Kasal"
  },
  {
    "label": "Kasaragod",
    "value": "Kasaragod"
  },
  {
    "label": "Kasganj",
    "value": "Kasganj"
  },
  {
    "label": "Kashipur",
    "value": "Kashipur"
  },
  {
    "label": "Kasia",
    "value": "Kasia"
  },
  {
    "label": "Kataria",
    "value": "Kataria"
  },
  {
    "label": "Kathua",
    "value": "Kathua"
  },
  {
    "label": "Katni",
    "value": "Katni"
  },
  {
    "label": "Katoya",
    "value": "Katoya"
  },
  {
    "label": "Katra",
    "value": "Katra"
  },
  {
    "label": "Kaul",
    "value": "Kaul"
  },
  {
    "label": "Kavali",
    "value": "Kavali"
  },
  {
    "label": "Kavaratti",
    "value": "Kavaratti"
  },
  {
    "label": "Kayamkulam",
    "value": "Kayamkulam"
  },
  {
    "label": "Keshod",
    "value": "Keshod"
  },
  {
    "label": "Khajuraho Group of Monuments",
    "value": "Khajuraho Group of Monuments"
  },
  {
    "label": "Khalapur",
    "value": "Khalapur"
  },
  {
    "label": "Khambhat",
    "value": "Khambhat"
  },
  {
    "label": "Khammam",
    "value": "Khammam"
  },
  {
    "label": "Khan",
    "value": "Khan"
  },
  {
    "label": "Khanna",
    "value": "Khanna"
  },
  {
    "label": "Kharagpur",
    "value": "Kharagpur"
  },
  {
    "label": "Kharar",
    "value": "Kharar"
  },
  {
    "label": "Khargone",
    "value": "Khargone"
  },
  {
    "label": "Khatauli",
    "value": "Khatauli"
  },
  {
    "label": "Kheda",
    "value": "Kheda"
  },
  {
    "label": "Khergam",
    "value": "Khergam"
  },
  {
    "label": "Kheri",
    "value": "Kheri"
  },
  {
    "label": "Khinwara",
    "value": "Khinwara"
  },
  {
    "label": "Khopoli",
    "value": "Khopoli"
  },
  {
    "label": "Khurda",
    "value": "Khurda"
  },
  {
    "label": "Khurja",
    "value": "Khurja"
  },
  {
    "label": "Kishangarh",
    "value": "Kishangarh"
  },
  {
    "label": "Koch Bihar",
    "value": "Koch Bihar"
  },
  {
    "label": "Kochi",
    "value": "Kochi"
  },
  {
    "label": "Kodaikanal",
    "value": "Kodaikanal"
  },
  {
    "label": "Kodungallur",
    "value": "Kodungallur"
  },
  {
    "label": "Kohima",
    "value": "Kohima"
  },
  {
    "label": "Kokrajhar",
    "value": "Kokrajhar"
  },
  {
    "label": "Kolar",
    "value": "Kolar"
  },
  {
    "label": "Kolayat",
    "value": "Kolayat"
  },
  {
    "label": "Kolhapur",
    "value": "Kolhapur"
  },
  {
    "label": "Kolkata",
    "value": "Kolkata"
  },
  {
    "label": "Kollam",
    "value": "Kollam"
  },
  {
    "label": "Kollegal",
    "value": "Kollegal"
  },
  {
    "label": "Koni",
    "value": "Koni"
  },
  {
    "label": "Konnagar",
    "value": "Konnagar"
  },
  {
    "label": "Koothanallur",
    "value": "Koothanallur"
  },
  {
    "label": "Koppal",
    "value": "Koppal"
  },
  {
    "label": "Koraput",
    "value": "Koraput"
  },
  {
    "label": "Korba",
    "value": "Korba"
  },
  {
    "label": "Kosamba",
    "value": "Kosamba"
  },
  {
    "label": "Kot Isa Khan",
    "value": "Kot Isa Khan"
  },
  {
    "label": "Kota",
    "value": "Kota"
  },
  {
    "label": "Kotian",
    "value": "Kotian"
  },
  {
    "label": "Kottagudem",
    "value": "Kottagudem"
  },
  {
    "label": "Kottakkal",
    "value": "Kottakkal"
  },
  {
    "label": "Kottarakara",
    "value": "Kottarakara"
  },
  {
    "label": "Kottayam",
    "value": "Kottayam"
  },
  {
    "label": "Kovilpatti",
    "value": "Kovilpatti"
  },
  {
    "label": "Kovvur",
    "value": "Kovvur"
  },
  {
    "label": "Kozhikode",
    "value": "Kozhikode"
  },
  {
    "label": "Krishnagiri",
    "value": "Krishnagiri"
  },
  {
    "label": "Kulti",
    "value": "Kulti"
  },
  {
    "label": "Kumar",
    "value": "Kumar"
  },
  {
    "label": "Kumbakonam",
    "value": "Kumbakonam"
  },
  {
    "label": "Kumhari",
    "value": "Kumhari"
  },
  {
    "label": "Kundan",
    "value": "Kundan"
  },
  {
    "label": "Kunwar",
    "value": "Kunwar"
  },
  {
    "label": "Kuppam",
    "value": "Kuppam"
  },
  {
    "label": "Kurali",
    "value": "Kurali"
  },
  {
    "label": "Kurnool",
    "value": "Kurnool"
  },
  {
    "label": "Kushalnagar",
    "value": "Kushalnagar"
  },
  {
    "label": "Kuzhithurai",
    "value": "Kuzhithurai"
  },
  {
    "label": "Ladwa",
    "value": "Ladwa"
  },
  {
    "label": "Lakhimpur",
    "value": "Lakhimpur"
  },
  {
    "label": "Lala",
    "value": "Lala"
  },
  {
    "label": "Lalgudi",
    "value": "Lalgudi"
  },
  {
    "label": "Lamba Harisingh",
    "value": "Lamba Harisingh"
  },
  {
    "label": "Lanka",
    "value": "Lanka"
  },
  {
    "label": "Latur",
    "value": "Latur"
  },
  {
    "label": "Liluah",
    "value": "Liluah"
  },
  {
    "label": "Lohaghat",
    "value": "Lohaghat"
  },
  {
    "label": "Lucknow",
    "value": "Lucknow"
  },
  {
    "label": "Ludhiana",
    "value": "Ludhiana"
  },
  {
    "label": "Machhiwara",
    "value": "Machhiwara"
  },
  {
    "label": "Machilipatnam",
    "value": "Machilipatnam"
  },
  {
    "label": "Madanapalle",
    "value": "Madanapalle"
  },
  {
    "label": "Madgaon",
    "value": "Madgaon"
  },
  {
    "label": "Madhoganj",
    "value": "Madhoganj"
  },
  {
    "label": "Madikeri",
    "value": "Madikeri"
  },
  {
    "label": "Madurai",
    "value": "Madurai"
  },
  {
    "label": "Madurantakam",
    "value": "Madurantakam"
  },
  {
    "label": "Mahabalipuram",
    "value": "Mahabalipuram"
  },
  {
    "label": "Mahad",
    "value": "Mahad"
  },
  {
    "label": "Mahajan",
    "value": "Mahajan"
  },
  {
    "label": "Mahal",
    "value": "Mahal"
  },
  {
    "label": "Maharaj",
    "value": "Maharaj"
  },
  {
    "label": "Mahatma",
    "value": "Mahatma"
  },
  {
    "label": "Mahesana",
    "value": "Mahesana"
  },
  {
    "label": "Mahesh",
    "value": "Mahesh"
  },
  {
    "label": "Mahim",
    "value": "Mahim"
  },
  {
    "label": "Mahulia",
    "value": "Mahulia"
  },
  {
    "label": "Malappuram",
    "value": "Malappuram"
  },
  {
    "label": "Maldah",
    "value": "Maldah"
  },
  {
    "label": "Malpur",
    "value": "Malpur"
  },
  {
    "label": "Manali",
    "value": "Manali"
  },
  {
    "label": "Mancherial",
    "value": "Mancherial"
  },
  {
    "label": "Mandal",
    "value": "Mandal"
  },
  {
    "label": "Mandapeta",
    "value": "Mandapeta"
  },
  {
    "label": "Mandi",
    "value": "Mandi"
  },
  {
    "label": "Mandla",
    "value": "Mandla"
  },
  {
    "label": "Mandsaur",
    "value": "Mandsaur"
  },
  {
    "label": "Mandvi",
    "value": "Mandvi"
  },
  {
    "label": "Mandya",
    "value": "Mandya"
  },
  {
    "label": "Mangalagiri",
    "value": "Mangalagiri"
  },
  {
    "label": "Mangalore",
    "value": "Mangalore"
  },
  {
    "label": "Mangaon",
    "value": "Mangaon"
  },
  {
    "label": "Manipala",
    "value": "Manipala"
  },
  {
    "label": "Manipur",
    "value": "Manipur"
  },
  {
    "label": "Manjeri",
    "value": "Manjeri"
  },
  {
    "label": "Manna",
    "value": "Manna"
  },
  {
    "label": "Mannargudi",
    "value": "Mannargudi"
  },
  {
    "label": "Manor",
    "value": "Manor"
  },
  {
    "label": "Mansa",
    "value": "Mansa"
  },
  {
    "label": "Manu",
    "value": "Manu"
  },
  {
    "label": "Markal",
    "value": "Markal"
  },
  {
    "label": "Markapur",
    "value": "Markapur"
  },
  {
    "label": "Marmagao",
    "value": "Marmagao"
  },
  {
    "label": "Maru",
    "value": "Maru"
  },
  {
    "label": "Mashobra",
    "value": "Mashobra"
  },
  {
    "label": "Matar",
    "value": "Matar"
  },
  {
    "label": "Mathan",
    "value": "Mathan"
  },
  {
    "label": "Mathura",
    "value": "Mathura"
  },
  {
    "label": "Mattanur",
    "value": "Mattanur"
  },
  {
    "label": "Mavelikara",
    "value": "Mavelikara"
  },
  {
    "label": "Mawana",
    "value": "Mawana"
  },
  {
    "label": "Mayapur",
    "value": "Mayapur"
  },
  {
    "label": "Medak",
    "value": "Medak"
  },
  {
    "label": "Medarametla",
    "value": "Medarametla"
  },
  {
    "label": "Medchal",
    "value": "Medchal"
  },
  {
    "label": "Medinipur",
    "value": "Medinipur"
  },
  {
    "label": "Meerut",
    "value": "Meerut"
  },
  {
    "label": "Mehra",
    "value": "Mehra"
  },
  {
    "label": "Mettur",
    "value": "Mettur"
  },
  {
    "label": "Mhow",
    "value": "Mhow"
  },
  {
    "label": "Mill",
    "value": "Mill"
  },
  {
    "label": "Miraj",
    "value": "Miraj"
  },
  {
    "label": "Mirza Murad",
    "value": "Mirza Murad"
  },
  {
    "label": "Mirzapur",
    "value": "Mirzapur"
  },
  {
    "label": "Mithapur",
    "value": "Mithapur"
  },
  {
    "label": "Modasa",
    "value": "Modasa"
  },
  {
    "label": "Moga",
    "value": "Moga"
  },
  {
    "label": "Mohala",
    "value": "Mohala"
  },
  {
    "label": "Mohali",
    "value": "Mohali"
  },
  {
    "label": "Mohan",
    "value": "Mohan"
  },
  {
    "label": "Moradabad",
    "value": "Moradabad"
  },
  {
    "label": "Morena",
    "value": "Morena"
  },
  {
    "label": "Morinda",
    "value": "Morinda"
  },
  {
    "label": "Morvi",
    "value": "Morvi"
  },
  {
    "label": "Motihari",
    "value": "Motihari"
  },
  {
    "label": "Mount Abu",
    "value": "Mount Abu"
  },
  {
    "label": "Muddanuru",
    "value": "Muddanuru"
  },
  {
    "label": "Mukerian",
    "value": "Mukerian"
  },
  {
    "label": "Muktsar",
    "value": "Muktsar"
  },
  {
    "label": "Multi",
    "value": "Multi"
  },
  {
    "label": "Mumbai",
    "value": "Mumbai"
  },
  {
    "label": "Mundgod",
    "value": "Mundgod"
  },
  {
    "label": "Mundra",
    "value": "Mundra"
  },
  {
    "label": "Munger",
    "value": "Munger"
  },
  {
    "label": "Murshidabad",
    "value": "Murshidabad"
  },
  {
    "label": "Mussoorie",
    "value": "Mussoorie"
  },
  {
    "label": "Muzaffarnagar",
    "value": "Muzaffarnagar"
  },
  {
    "label": "Muzaffarpur",
    "value": "Muzaffarpur"
  },
  {
    "label": "Mylapore",
    "value": "Mylapore"
  },
  {
    "label": "Mysore",
    "value": "Mysore"
  },
  {
    "label": "Nabadwip",
    "value": "Nabadwip"
  },
  {
    "label": "Nabha",
    "value": "Nabha"
  },
  {
    "label": "Nadgaon",
    "value": "Nadgaon"
  },
  {
    "label": "Nadia",
    "value": "Nadia"
  },
  {
    "label": "Nadiad",
    "value": "Nadiad"
  },
  {
    "label": "Nagal",
    "value": "Nagal"
  },
  {
    "label": "Nagapattinam",
    "value": "Nagapattinam"
  },
  {
    "label": "Nagar",
    "value": "Nagar"
  },
  {
    "label": "Nagara",
    "value": "Nagara"
  },
  {
    "label": "Nagari",
    "value": "Nagari"
  },
  {
    "label": "Nagaur",
    "value": "Nagaur"
  },
  {
    "label": "Nagercoil",
    "value": "Nagercoil"
  },
  {
    "label": "Nagpur",
    "value": "Nagpur"
  },
  {
    "label": "Nagwa",
    "value": "Nagwa"
  },
  {
    "label": "Naini",
    "value": "Naini"
  },
  {
    "label": "Nalagarh",
    "value": "Nalagarh"
  },
  {
    "label": "Nalbari",
    "value": "Nalbari"
  },
  {
    "label": "Nalgonda",
    "value": "Nalgonda"
  },
  {
    "label": "Namakkal",
    "value": "Namakkal"
  },
  {
    "label": "Namrup",
    "value": "Namrup"
  },
  {
    "label": "Nanda",
    "value": "Nanda"
  },
  {
    "label": "Nanded",
    "value": "Nanded"
  },
  {
    "label": "Nandi",
    "value": "Nandi"
  },
  {
    "label": "Nandigama",
    "value": "Nandigama"
  },
  {
    "label": "Nandurbar",
    "value": "Nandurbar"
  },
  {
    "label": "Nandyal",
    "value": "Nandyal"
  },
  {
    "label": "Naraina",
    "value": "Naraina"
  },
  {
    "label": "Narasaraopet",
    "value": "Narasaraopet"
  },
  {
    "label": "Narayangaon",
    "value": "Narayangaon"
  },
  {
    "label": "Narela",
    "value": "Narela"
  },
  {
    "label": "Narnaul",
    "value": "Narnaul"
  },
  {
    "label": "Narsapur",
    "value": "Narsapur"
  },
  {
    "label": "Nashik",
    "value": "Nashik"
  },
  {
    "label": "Nathdwara",
    "value": "Nathdwara"
  },
  {
    "label": "Navelim",
    "value": "Navelim"
  },
  {
    "label": "Navsari",
    "value": "Navsari"
  },
  {
    "label": "Nayagarh",
    "value": "Nayagarh"
  },
  {
    "label": "Nazira",
    "value": "Nazira"
  },
  {
    "label": "Nehra",
    "value": "Nehra"
  },
  {
    "label": "Nellore",
    "value": "Nellore"
  },
  {
    "label": "Neral",
    "value": "Neral"
  },
  {
    "label": "Neri",
    "value": "Neri"
  },
  {
    "label": "New Delhi",
    "value": "New Delhi"
  },
  {
    "label": "Neyveli",
    "value": "Neyveli"
  },
  {
    "label": "Nila",
    "value": "Nila"
  },
  {
    "label": "Nilambur",
    "value": "Nilambur"
  },
  {
    "label": "Nilokheri",
    "value": "Nilokheri"
  },
  {
    "label": "Nizamabad",
    "value": "Nizamabad"
  },
  {
    "label": "Noida",
    "value": "Noida"
  },
  {
    "label": "Nongpoh",
    "value": "Nongpoh"
  },
  {
    "label": "Nongstoin",
    "value": "Nongstoin"
  },
  {
    "label": "North Lakhimpur",
    "value": "North Lakhimpur"
  },
  {
    "label": "Nurpur",
    "value": "Nurpur"
  },
  {
    "label": "Nuzvid",
    "value": "Nuzvid"
  },
  {
    "label": "Odhan",
    "value": "Odhan"
  },
  {
    "label": "Omalur",
    "value": "Omalur"
  },
  {
    "label": "Ongole",
    "value": "Ongole"
  },
  {
    "label": "Ooty",
    "value": "Ooty"
  },
  {
    "label": "Orai",
    "value": "Orai"
  },
  {
    "label": "Osmanabad",
    "value": "Osmanabad"
  },
  {
    "label": "Ottappalam",
    "value": "Ottappalam"
  },
  {
    "label": "Pachmarhi",
    "value": "Pachmarhi"
  },
  {
    "label": "Padrauna",
    "value": "Padrauna"
  },
  {
    "label": "Pahalgam",
    "value": "Pahalgam"
  },
  {
    "label": "Pakala",
    "value": "Pakala"
  },
  {
    "label": "Pala",
    "value": "Pala"
  },
  {
    "label": "Palakkad",
    "value": "Palakkad"
  },
  {
    "label": "Palampur",
    "value": "Palampur"
  },
  {
    "label": "Palani",
    "value": "Palani"
  },
  {
    "label": "Palayam",
    "value": "Palayam"
  },
  {
    "label": "Palghar",
    "value": "Palghar"
  },
  {
    "label": "Pali",
    "value": "Pali"
  },
  {
    "label": "Palladam",
    "value": "Palladam"
  },
  {
    "label": "Paloncha",
    "value": "Paloncha"
  },
  {
    "label": "Palus",
    "value": "Palus"
  },
  {
    "label": "Palwal",
    "value": "Palwal"
  },
  {
    "label": "Panchal",
    "value": "Panchal"
  },
  {
    "label": "Panchgani",
    "value": "Panchgani"
  },
  {
    "label": "Pandharpur",
    "value": "Pandharpur"
  },
  {
    "label": "Panipat",
    "value": "Panipat"
  },
  {
    "label": "Panjim",
    "value": "Panjim"
  },
  {
    "label": "Panruti",
    "value": "Panruti"
  },
  {
    "label": "Pantnagar",
    "value": "Pantnagar"
  },
  {
    "label": "Panvel",
    "value": "Panvel"
  },
  {
    "label": "Paonta Sahib",
    "value": "Paonta Sahib"
  },
  {
    "label": "Parappanangadi",
    "value": "Parappanangadi"
  },
  {
    "label": "Paravur",
    "value": "Paravur"
  },
  {
    "label": "Parbhani",
    "value": "Parbhani"
  },
  {
    "label": "Parel",
    "value": "Parel"
  },
  {
    "label": "Parra",
    "value": "Parra"
  },
  {
    "label": "Patan",
    "value": "Patan"
  },
  {
    "label": "Patancheru",
    "value": "Patancheru"
  },
  {
    "label": "Patel",
    "value": "Patel"
  },
  {
    "label": "Patelguda",
    "value": "Patelguda"
  },
  {
    "label": "Pathanamthitta",
    "value": "Pathanamthitta"
  },
  {
    "label": "Pathankot",
    "value": "Pathankot"
  },
  {
    "label": "Patiala",
    "value": "Patiala"
  },
  {
    "label": "Patna",
    "value": "Patna"
  },
  {
    "label": "Pattambi",
    "value": "Pattambi"
  },
  {
    "label": "Pattukkottai",
    "value": "Pattukkottai"
  },
  {
    "label": "Pauri",
    "value": "Pauri"
  },
  {
    "label": "Payyanur",
    "value": "Payyanur"
  },
  {
    "label": "Peddapuram",
    "value": "Peddapuram"
  },
  {
    "label": "Pehowa",
    "value": "Pehowa"
  },
  {
    "label": "Perambalur",
    "value": "Perambalur"
  },
  {
    "label": "Peranampattu",
    "value": "Peranampattu"
  },
  {
    "label": "Perundurai",
    "value": "Perundurai"
  },
  {
    "label": "Petlad",
    "value": "Petlad"
  },
  {
    "label": "Phagwara",
    "value": "Phagwara"
  },
  {
    "label": "Phaphamau",
    "value": "Phaphamau"
  },
  {
    "label": "Piduguralla",
    "value": "Piduguralla"
  },
  {
    "label": "Pilani",
    "value": "Pilani"
  },
  {
    "label": "Pileru",
    "value": "Pileru"
  },
  {
    "label": "Pilkhuwa",
    "value": "Pilkhuwa"
  },
  {
    "label": "Pimpri",
    "value": "Pimpri"
  },
  {
    "label": "Pitampura",
    "value": "Pitampura"
  },
  {
    "label": "Pithapuram",
    "value": "Pithapuram"
  },
  {
    "label": "Pithoragarh",
    "value": "Pithoragarh"
  },
  {
    "label": "Pochampalli",
    "value": "Pochampalli"
  },
  {
    "label": "Pollachi",
    "value": "Pollachi"
  },
  {
    "label": "Ponda",
    "value": "Ponda"
  },
  {
    "label": "Ponnani",
    "value": "Ponnani"
  },
  {
    "label": "Ponneri",
    "value": "Ponneri"
  },
  {
    "label": "Porbandar",
    "value": "Porbandar"
  },
  {
    "label": "Port Blair",
    "value": "Port Blair"
  },
  {
    "label": "Potti",
    "value": "Potti"
  },
  {
    "label": "Powai",
    "value": "Powai"
  },
  {
    "label": "Proddatur",
    "value": "Proddatur"
  },
  {
    "label": "Puducherry",
    "value": "Puducherry"
  },
  {
    "label": "Pudukkottai",
    "value": "Pudukkottai"
  },
  {
    "label": "Puliyur",
    "value": "Puliyur"
  },
  {
    "label": "Punalur",
    "value": "Punalur"
  },
  {
    "label": "Pune",
    "value": "Pune"
  },
  {
    "label": "Puras",
    "value": "Puras"
  },
  {
    "label": "Puri",
    "value": "Puri"
  },
  {
    "label": "Purnea",
    "value": "Purnea"
  },
  {
    "label": "Puruliya",
    "value": "Puruliya"
  },
  {
    "label": "Pusa",
    "value": "Pusa"
  },
  {
    "label": "Pushkar",
    "value": "Pushkar"
  },
  {
    "label": "Puttur",
    "value": "Puttur"
  },
  {
    "label": "Quepem",
    "value": "Quepem"
  },
  {
    "label": "Raichur",
    "value": "Raichur"
  },
  {
    "label": "Raigarh",
    "value": "Raigarh"
  },
  {
    "label": "Raipur",
    "value": "Raipur"
  },
  {
    "label": "Rajahmundry",
    "value": "Rajahmundry"
  },
  {
    "label": "Rajapalaiyam",
    "value": "Rajapalaiyam"
  },
  {
    "label": "Rajapur",
    "value": "Rajapur"
  },
  {
    "label": "Rajkot",
    "value": "Rajkot"
  },
  {
    "label": "Rajpur",
    "value": "Rajpur"
  },
  {
    "label": "Rajpura",
    "value": "Rajpura"
  },
  {
    "label": "Raju",
    "value": "Raju"
  },
  {
    "label": "Rama",
    "value": "Rama"
  },
  {
    "label": "Ramanagaram",
    "value": "Ramanagaram"
  },
  {
    "label": "Ramanathapuram",
    "value": "Ramanathapuram"
  },
  {
    "label": "Ramapuram",
    "value": "Ramapuram"
  },
  {
    "label": "Ramavaram",
    "value": "Ramavaram"
  },
  {
    "label": "Ramgarh",
    "value": "Ramgarh"
  },
  {
    "label": "Ramnagar",
    "value": "Ramnagar"
  },
  {
    "label": "Rampur",
    "value": "Rampur"
  },
  {
    "label": "Rana",
    "value": "Rana"
  },
  {
    "label": "Ranaghat",
    "value": "Ranaghat"
  },
  {
    "label": "Ranchi",
    "value": "Ranchi"
  },
  {
    "label": "Rander",
    "value": "Rander"
  },
  {
    "label": "Raniganj",
    "value": "Raniganj"
  },
  {
    "label": "Ranippettai",
    "value": "Ranippettai"
  },
  {
    "label": "Ranjan",
    "value": "Ranjan"
  },
  {
    "label": "Ratlam",
    "value": "Ratlam"
  },
  {
    "label": "Ratnagiri",
    "value": "Ratnagiri"
  },
  {
    "label": "Raurkela",
    "value": "Raurkela"
  },
  {
    "label": "Rawal",
    "value": "Rawal"
  },
  {
    "label": "Raxaul",
    "value": "Raxaul"
  },
  {
    "label": "Rayagada",
    "value": "Rayagada"
  },
  {
    "label": "Rewa",
    "value": "Rewa"
  },
  {
    "label": "Rewari",
    "value": "Rewari"
  },
  {
    "label": "Ring",
    "value": "Ring"
  },
  {
    "label": "Rishikesh",
    "value": "Rishikesh"
  },
  {
    "label": "Rohtak",
    "value": "Rohtak"
  },
  {
    "label": "Roorkee",
    "value": "Roorkee"
  },
  {
    "label": "Roshan",
    "value": "Roshan"
  },
  {
    "label": "Rudrapur",
    "value": "Rudrapur"
  },
  {
    "label": "Rupnagar",
    "value": "Rupnagar"
  },
  {
    "label": "Rupnarayanpur",
    "value": "Rupnarayanpur"
  },
  {
    "label": "Sachin",
    "value": "Sachin"
  },
  {
    "label": "Sagar",
    "value": "Sagar"
  },
  {
    "label": "Saha",
    "value": "Saha"
  },
  {
    "label": "Saharanpur",
    "value": "Saharanpur"
  },
  {
    "label": "Sahibabad",
    "value": "Sahibabad"
  },
  {
    "label": "Sakri",
    "value": "Sakri"
  },
  {
    "label": "Salem",
    "value": "Salem"
  },
  {
    "label": "Saligao",
    "value": "Saligao"
  },
  {
    "label": "Salt Lake City",
    "value": "Salt Lake City"
  },
  {
    "label": "Samastipur",
    "value": "Samastipur"
  },
  {
    "label": "Sambalpur",
    "value": "Sambalpur"
  },
  {
    "label": "Sanand",
    "value": "Sanand"
  },
  {
    "label": "Sandur",
    "value": "Sandur"
  },
  {
    "label": "Sangam",
    "value": "Sangam"
  },
  {
    "label": "Sangamner",
    "value": "Sangamner"
  },
  {
    "label": "Sangli",
    "value": "Sangli"
  },
  {
    "label": "Sangola",
    "value": "Sangola"
  },
  {
    "label": "Sangrur",
    "value": "Sangrur"
  },
  {
    "label": "Sanquelim",
    "value": "Sanquelim"
  },
  {
    "label": "Saranga",
    "value": "Saranga"
  },
  {
    "label": "Sarangi",
    "value": "Sarangi"
  },
  {
    "label": "Sarwar",
    "value": "Sarwar"
  },
  {
    "label": "Satara",
    "value": "Satara"
  },
  {
    "label": "Satna",
    "value": "Satna"
  },
  {
    "label": "Sattur",
    "value": "Sattur"
  },
  {
    "label": "Sawi",
    "value": "Sawi"
  },
  {
    "label": "Secunderabad",
    "value": "Secunderabad"
  },
  {
    "label": "Sehore",
    "value": "Sehore"
  },
  {
    "label": "Sendhwa",
    "value": "Sendhwa"
  },
  {
    "label": "Serampore",
    "value": "Serampore"
  },
  {
    "label": "Shadnagar",
    "value": "Shadnagar"
  },
  {
    "label": "Shahabad",
    "value": "Shahabad"
  },
  {
    "label": "Shahapur",
    "value": "Shahapur"
  },
  {
    "label": "Shahdara",
    "value": "Shahdara"
  },
  {
    "label": "Shahdol",
    "value": "Shahdol"
  },
  {
    "label": "Shahjahanpur",
    "value": "Shahjahanpur"
  },
  {
    "label": "Shahkot",
    "value": "Shahkot"
  },
  {
    "label": "Shamsabad",
    "value": "Shamsabad"
  },
  {
    "label": "Shanti Grama",
    "value": "Shanti Grama"
  },
  {
    "label": "Shillong",
    "value": "Shillong"
  },
  {
    "label": "Shimla",
    "value": "Shimla"
  },
  {
    "label": "Shimoga",
    "value": "Shimoga"
  },
  {
    "label": "Shirgaon",
    "value": "Shirgaon"
  },
  {
    "label": "Shiv",
    "value": "Shiv"
  },
  {
    "label": "Sholavandan",
    "value": "Sholavandan"
  },
  {
    "label": "Shoranur",
    "value": "Shoranur"
  },
  {
    "label": "Shrigonda",
    "value": "Shrigonda"
  },
  {
    "label": "Shyamnagar",
    "value": "Shyamnagar"
  },
  {
    "label": "Sibsagar",
    "value": "Sibsagar"
  },
  {
    "label": "Sidhi",
    "value": "Sidhi"
  },
  {
    "label": "Sidhpur",
    "value": "Sidhpur"
  },
  {
    "label": "Sikar",
    "value": "Sikar"
  },
  {
    "label": "Sikka",
    "value": "Sikka"
  },
  {
    "label": "Silchar",
    "value": "Silchar"
  },
  {
    "label": "Siliguri",
    "value": "Siliguri"
  },
  {
    "label": "Silvassa",
    "value": "Silvassa"
  },
  {
    "label": "Singarayakonda",
    "value": "Singarayakonda"
  },
  {
    "label": "Singtam",
    "value": "Singtam"
  },
  {
    "label": "Sinnar",
    "value": "Sinnar"
  },
  {
    "label": "Sion",
    "value": "Sion"
  },
  {
    "label": "Sirhind",
    "value": "Sirhind"
  },
  {
    "label": "Sirkazhi",
    "value": "Sirkazhi"
  },
  {
    "label": "Sirohi",
    "value": "Sirohi"
  },
  {
    "label": "Sirsa",
    "value": "Sirsa"
  },
  {
    "label": "Sirsi",
    "value": "Sirsi"
  },
  {
    "label": "Siruguppa",
    "value": "Siruguppa"
  },
  {
    "label": "Siruseri",
    "value": "Siruseri"
  },
  {
    "label": "Sirwani",
    "value": "Sirwani"
  },
  {
    "label": "Sitapur",
    "value": "Sitapur"
  },
  {
    "label": "Siuri",
    "value": "Siuri"
  },
  {
    "label": "Sivaganga",
    "value": "Sivaganga"
  },
  {
    "label": "Sivakasi",
    "value": "Sivakasi"
  },
  {
    "label": "Sodhi",
    "value": "Sodhi"
  },
  {
    "label": "Sojat",
    "value": "Sojat"
  },
  {
    "label": "Solan",
    "value": "Solan"
  },
  {
    "label": "Solapur",
    "value": "Solapur"
  },
  {
    "label": "Solim",
    "value": "Solim"
  },
  {
    "label": "Somnath",
    "value": "Somnath"
  },
  {
    "label": "Soni",
    "value": "Soni"
  },
  {
    "label": "Sonipat",
    "value": "Sonipat"
  },
  {
    "label": "Sopara",
    "value": "Sopara"
  },
  {
    "label": "Srikakulam",
    "value": "Srikakulam"
  },
  {
    "label": "Srikalahasti",
    "value": "Srikalahasti"
  },
  {
    "label": "Srinagar",
    "value": "Srinagar"
  },
  {
    "label": "Sriperumbudur",
    "value": "Sriperumbudur"
  },
  {
    "label": "Srirangam",
    "value": "Srirangam"
  },
  {
    "label": "Srivilliputhur",
    "value": "Srivilliputhur"
  },
  {
    "label": "Sukma",
    "value": "Sukma"
  },
  {
    "label": "Sultan",
    "value": "Sultan"
  },
  {
    "label": "Sultanpur",
    "value": "Sultanpur"
  },
  {
    "label": "Sultans Battery",
    "value": "Sultans Battery"
  },
  {
    "label": "Suman",
    "value": "Suman"
  },
  {
    "label": "Sunam",
    "value": "Sunam"
  },
  {
    "label": "Sundargarh",
    "value": "Sundargarh"
  },
  {
    "label": "Surana",
    "value": "Surana"
  },
  {
    "label": "Suratgarh",
    "value": "Suratgarh"
  },
  {
    "label": "Surendranagar",
    "value": "Surendranagar"
  },
  {
    "label": "Suriapet",
    "value": "Suriapet"
  },
  {
    "label": "Tadepallegudem",
    "value": "Tadepallegudem"
  },
  {
    "label": "Tala",
    "value": "Tala"
  },
  {
    "label": "Talcher",
    "value": "Talcher"
  },
  {
    "label": "Talegaon Dabhade",
    "value": "Talegaon Dabhade"
  },
  {
    "label": "Talwandi Sabo",
    "value": "Talwandi Sabo"
  },
  {
    "label": "Tambaram",
    "value": "Tambaram"
  },
  {
    "label": "Tanda",
    "value": "Tanda"
  },
  {
    "label": "Tanuku",
    "value": "Tanuku"
  },
  {
    "label": "Tarn Taran",
    "value": "Tarn Taran"
  },
  {
    "label": "Teri",
    "value": "Teri"
  },
  {
    "label": "Tezpur",
    "value": "Tezpur"
  },
  {
    "label": "Thalassery",
    "value": "Thalassery"
  },
  {
    "label": "Thane",
    "value": "Thane"
  },
  {
    "label": "Thanjavur",
    "value": "Thanjavur"
  },
  {
    "label": "Thasra",
    "value": "Thasra"
  },
  {
    "label": "Thenali",
    "value": "Thenali"
  },
  {
    "label": "Thenkasi",
    "value": "Thenkasi"
  },
  {
    "label": "Thirumangalam",
    "value": "Thirumangalam"
  },
  {
    "label": "Thiruthani",
    "value": "Thiruthani"
  },
  {
    "label": "Thiruvananthapuram",
    "value": "Thiruvananthapuram"
  },
  {
    "label": "Thiruvarur",
    "value": "Thiruvarur"
  },
  {
    "label": "Thoothukudi",
    "value": "Thoothukudi"
  },
  {
    "label": "Thrissur",
    "value": "Thrissur"
  },
  {
    "label": "Tikamgarh",
    "value": "Tikamgarh"
  },
  {
    "label": "Tindivanam",
    "value": "Tindivanam"
  },
  {
    "label": "Tinsukia",
    "value": "Tinsukia"
  },
  {
    "label": "Tiptur",
    "value": "Tiptur"
  },
  {
    "label": "Tiruchchendur",
    "value": "Tiruchchendur"
  },
  {
    "label": "Tiruchi",
    "value": "Tiruchi"
  },
  {
    "label": "Tirumala",
    "value": "Tirumala"
  },
  {
    "label": "Tirumala - Tirupati",
    "value": "Tirumala - Tirupati"
  },
  {
    "label": "Tirunelveli",
    "value": "Tirunelveli"
  },
  {
    "label": "Tiruppur",
    "value": "Tiruppur"
  },
  {
    "label": "Tirur",
    "value": "Tirur"
  },
  {
    "label": "Tiruvalla",
    "value": "Tiruvalla"
  },
  {
    "label": "Tiruvallur",
    "value": "Tiruvallur"
  },
  {
    "label": "Tiruvannamalai",
    "value": "Tiruvannamalai"
  },
  {
    "label": "Tohana",
    "value": "Tohana"
  },
  {
    "label": "Tonk",
    "value": "Tonk"
  },
  {
    "label": "Trimbak",
    "value": "Trimbak"
  },
  {
    "label": "Tuljapur",
    "value": "Tuljapur"
  },
  {
    "label": "Turaiyur",
    "value": "Turaiyur"
  },
  {
    "label": "Udaigiri",
    "value": "Udaigiri"
  },
  {
    "label": "Udaipur",
    "value": "Udaipur"
  },
  {
    "label": "Udupi",
    "value": "Udupi"
  },
  {
    "label": "Ujjain",
    "value": "Ujjain"
  },
  {
    "label": "Ulhasnagar",
    "value": "Ulhasnagar"
  },
  {
    "label": "Ulubari",
    "value": "Ulubari"
  },
  {
    "label": "Umred",
    "value": "Umred"
  },
  {
    "label": "Unnao",
    "value": "Unnao"
  },
  {
    "label": "Uppal",
    "value": "Uppal"
  },
  {
    "label": "Uttarkashi",
    "value": "Uttarkashi"
  },
  {
    "label": "Vadamadurai",
    "value": "Vadamadurai"
  },
  {
    "label": "Vadner",
    "value": "Vadner"
  },
  {
    "label": "Vadodara",
    "value": "Vadodara"
  },
  {
    "label": "Vaikam",
    "value": "Vaikam"
  },
  {
    "label": "Vainguinim",
    "value": "Vainguinim"
  },
  {
    "label": "Valsad",
    "value": "Valsad"
  },
  {
    "label": "Vandalur",
    "value": "Vandalur"
  },
  {
    "label": "Vandavasi",
    "value": "Vandavasi"
  },
  {
    "label": "Vaniyambadi",
    "value": "Vaniyambadi"
  },
  {
    "label": "Vapi",
    "value": "Vapi"
  },
  {
    "label": "Varanasi",
    "value": "Varanasi"
  },
  {
    "label": "Vasai",
    "value": "Vasai"
  },
  {
    "label": "Vasco",
    "value": "Vasco"
  },
  {
    "label": "Vashi",
    "value": "Vashi"
  },
  {
    "label": "Vazhakulam",
    "value": "Vazhakulam"
  },
  {
    "label": "Vellore",
    "value": "Vellore"
  },
  {
    "label": "Verna",
    "value": "Verna"
  },
  {
    "label": "Vidisha",
    "value": "Vidisha"
  },
  {
    "label": "Vijapur",
    "value": "Vijapur"
  },
  {
    "label": "Vijayawada",
    "value": "Vijayawada"
  },
  {
    "label": "Vikarabad",
    "value": "Vikarabad"
  },
  {
    "label": "Vikasnagar",
    "value": "Vikasnagar"
  },
  {
    "label": "Villupuram",
    "value": "Villupuram"
  },
  {
    "label": "Vinukonda",
    "value": "Vinukonda"
  },
  {
    "label": "Virar",
    "value": "Virar"
  },
  {
    "label": "Visakhapatnam",
    "value": "Visakhapatnam"
  },
  {
    "label": "Visnagar",
    "value": "Visnagar"
  },
  {
    "label": "Vizianagaram",
    "value": "Vizianagaram"
  },
  {
    "label": "Wai",
    "value": "Wai"
  },
  {
    "label": "Warangal",
    "value": "Warangal"
  },
  {
    "label": "Wardha",
    "value": "Wardha"
  },
  {
    "label": "Wellington",
    "value": "Wellington"
  },
  {
    "label": "Yadgir",
    "value": "Yadgir"
  },
  {
    "label": "Yamunanagar",
    "value": "Yamunanagar"
  },
  {
    "label": "Yanam",
    "value": "Yanam"
  },
  {
    "label": "Yavatmal",
    "value": "Yavatmal"
  },
  {
    "label": "Yeola",
    "value": "Yeola"
  },
  {
    "label": "Yercaud",
    "value": "Yercaud"
  },
  {
    "label": "Bamboo Flat",
    "value": "Bamboo Flat"
  },
  {
    "label": "Nicobar",
    "value": "Nicobar"
  },
  {
    "label": "South Andaman",
    "value": "South Andaman"
  },
  {
    "label": "Addanki",
    "value": "Addanki"
  },
  {
    "label": "Ādoni",
    "value": "Ādoni"
  },
  {
    "label": "Akasahebpet",
    "value": "Akasahebpet"
  },
  {
    "label": "Akivīdu",
    "value": "Akivīdu"
  },
  {
    "label": "Akkarampalle",
    "value": "Akkarampalle"
  },
  {
    "label": "Amalāpuram",
    "value": "Amalāpuram"
  },
  {
    "label": "Amudālavalasa",
    "value": "Amudālavalasa"
  },
  {
    "label": "Anakāpalle",
    "value": "Anakāpalle"
  },
  {
    "label": "Atmakūr",
    "value": "Atmakūr"
  },
  {
    "label": "Attili",
    "value": "Attili"
  },
  {
    "label": "Badvel",
    "value": "Badvel"
  },
  {
    "label": "Banganapalle",
    "value": "Banganapalle"
  },
  {
    "label": "Bāpatla",
    "value": "Bāpatla"
  },
  {
    "label": "Betamcherla",
    "value": "Betamcherla"
  },
  {
    "label": "Bhattiprolu",
    "value": "Bhattiprolu"
  },
  {
    "label": "Bhīmavaram",
    "value": "Bhīmavaram"
  },
  {
    "label": "Bhīmunipatnam",
    "value": "Bhīmunipatnam"
  },
  {
    "label": "Challapalle",
    "value": "Challapalle"
  },
  {
    "label": "Chemmumiahpet",
    "value": "Chemmumiahpet"
  },
  {
    "label": "Chilakalūrupet",
    "value": "Chilakalūrupet"
  },
  {
    "label": "Chinnachowk",
    "value": "Chinnachowk"
  },
  {
    "label": "Chīpurupalle",
    "value": "Chīpurupalle"
  },
  {
    "label": "Chīrāla",
    "value": "Chīrāla"
  },
  {
    "label": "Chodavaram",
    "value": "Chodavaram"
  },
  {
    "label": "Cuddapah",
    "value": "Cuddapah"
  },
  {
    "label": "Dharmavaram",
    "value": "Dharmavaram"
  },
  {
    "label": "Diguvametta",
    "value": "Diguvametta"
  },
  {
    "label": "East Godāvari",
    "value": "East Godāvari"
  },
  {
    "label": "Elamanchili",
    "value": "Elamanchili"
  },
  {
    "label": "Ellore",
    "value": "Ellore"
  },
  {
    "label": "Emmiganūr",
    "value": "Emmiganūr"
  },
  {
    "label": "Erraguntla",
    "value": "Erraguntla"
  },
  {
    "label": "Etikoppāka",
    "value": "Etikoppāka"
  },
  {
    "label": "Gajuwaka",
    "value": "Gajuwaka"
  },
  {
    "label": "Ganguvāda",
    "value": "Ganguvāda"
  },
  {
    "label": "Giddalūr",
    "value": "Giddalūr"
  },
  {
    "label": "Gokavaram",
    "value": "Gokavaram"
  },
  {
    "label": "Gorantla",
    "value": "Gorantla"
  },
  {
    "label": "Govindapuram,Chilakaluripet,Guntur",
    "value": "Govindapuram,Chilakaluripet,Guntur"
  },
  {
    "label": "Gudivāda",
    "value": "Gudivāda"
  },
  {
    "label": "Gudlavalleru",
    "value": "Gudlavalleru"
  },
  {
    "label": "Gūdūr",
    "value": "Gūdūr"
  },
  {
    "label": "Guntakal Junction",
    "value": "Guntakal Junction"
  },
  {
    "label": "Guntūr",
    "value": "Guntūr"
  },
  {
    "label": "Hindupur",
    "value": "Hindupur"
  },
  {
    "label": "Ichchāpuram",
    "value": "Ichchāpuram"
  },
  {
    "label": "Jaggayyapeta",
    "value": "Jaggayyapeta"
  },
  {
    "label": "Jammalamadugu",
    "value": "Jammalamadugu"
  },
  {
    "label": "Kadiri",
    "value": "Kadiri"
  },
  {
    "label": "Kaikalūr",
    "value": "Kaikalūr"
  },
  {
    "label": "Kākināda",
    "value": "Kākināda"
  },
  {
    "label": "Kalyandurg",
    "value": "Kalyandurg"
  },
  {
    "label": "Kāmalāpuram",
    "value": "Kāmalāpuram"
  },
  {
    "label": "Kandukūr",
    "value": "Kandukūr"
  },
  {
    "label": "Kankipādu",
    "value": "Kankipādu"
  },
  {
    "label": "Kanuru",
    "value": "Kanuru"
  },
  {
    "label": "Kāvali",
    "value": "Kāvali"
  },
  {
    "label": "Kolanukonda",
    "value": "Kolanukonda"
  },
  {
    "label": "Kondapalle",
    "value": "Kondapalle"
  },
  {
    "label": "Korukollu",
    "value": "Korukollu"
  },
  {
    "label": "Kosigi",
    "value": "Kosigi"
  },
  {
    "label": "Kovūr",
    "value": "Kovūr"
  },
  {
    "label": "Kovvūr",
    "value": "Kovvūr"
  },
  {
    "label": "Krishna",
    "value": "Krishna"
  },
  {
    "label": "Mācherla",
    "value": "Mācherla"
  },
  {
    "label": "Machilīpatnam",
    "value": "Machilīpatnam"
  },
  {
    "label": "Mādugula",
    "value": "Mādugula"
  },
  {
    "label": "Mandasa",
    "value": "Mandasa"
  },
  {
    "label": "Mārkāpur",
    "value": "Mārkāpur"
  },
  {
    "label": "Nāgireddipalli",
    "value": "Nāgireddipalli"
  },
  {
    "label": "Nandigāma",
    "value": "Nandigāma"
  },
  {
    "label": "Nandikotkūr",
    "value": "Nandikotkūr"
  },
  {
    "label": "Nandyāl",
    "value": "Nandyāl"
  },
  {
    "label": "Narasannapeta",
    "value": "Narasannapeta"
  },
  {
    "label": "Narasapur",
    "value": "Narasapur"
  },
  {
    "label": "Narasingāpuram",
    "value": "Narasingāpuram"
  },
  {
    "label": "Nārāyanavanam",
    "value": "Nārāyanavanam"
  },
  {
    "label": "Narsīpatnam",
    "value": "Narsīpatnam"
  },
  {
    "label": "Nāyudupet",
    "value": "Nāyudupet"
  },
  {
    "label": "Nidadavole",
    "value": "Nidadavole"
  },
  {
    "label": "Nūzvīd",
    "value": "Nūzvīd"
  },
  {
    "label": "Pākāla",
    "value": "Pākāla"
  },
  {
    "label": "Pālakollu",
    "value": "Pālakollu"
  },
  {
    "label": "Palāsa",
    "value": "Palāsa"
  },
  {
    "label": "Pālkonda",
    "value": "Pālkonda"
  },
  {
    "label": "Pallevāda",
    "value": "Pallevāda"
  },
  {
    "label": "Palmaner",
    "value": "Palmaner"
  },
  {
    "label": "Parlākimidi",
    "value": "Parlākimidi"
  },
  {
    "label": "Pārvatipuram",
    "value": "Pārvatipuram"
  },
  {
    "label": "Pavuluru",
    "value": "Pavuluru"
  },
  {
    "label": "Pedana",
    "value": "Pedana"
  },
  {
    "label": "pedda nakkalapalem",
    "value": "pedda nakkalapalem"
  },
  {
    "label": "Peddāpuram",
    "value": "Peddāpuram"
  },
  {
    "label": "Penugonda",
    "value": "Penugonda"
  },
  {
    "label": "Penukonda",
    "value": "Penukonda"
  },
  {
    "label": "Phirangipuram",
    "value": "Phirangipuram"
  },
  {
    "label": "Pippara",
    "value": "Pippara"
  },
  {
    "label": "Pithāpuram",
    "value": "Pithāpuram"
  },
  {
    "label": "Polavaram",
    "value": "Polavaram"
  },
  {
    "label": "Ponnur",
    "value": "Ponnur"
  },
  {
    "label": "Ponnūru",
    "value": "Ponnūru"
  },
  {
    "label": "Prakasam",
    "value": "Prakasam"
  },
  {
    "label": "Proddatūr",
    "value": "Proddatūr"
  },
  {
    "label": "Pulivendla",
    "value": "Pulivendla"
  },
  {
    "label": "Punganūru",
    "value": "Punganūru"
  },
  {
    "label": "Puttaparthi",
    "value": "Puttaparthi"
  },
  {
    "label": "Puttūr",
    "value": "Puttūr"
  },
  {
    "label": "Rājahmundry",
    "value": "Rājahmundry"
  },
  {
    "label": "Rāmachandrapuram",
    "value": "Rāmachandrapuram"
  },
  {
    "label": "Ramanayyapeta",
    "value": "Ramanayyapeta"
  },
  {
    "label": "Rāmāpuram",
    "value": "Rāmāpuram"
  },
  {
    "label": "Rampachodavaram",
    "value": "Rampachodavaram"
  },
  {
    "label": "Rāyachoti",
    "value": "Rāyachoti"
  },
  {
    "label": "Rāyadrug",
    "value": "Rāyadrug"
  },
  {
    "label": "Rāzām",
    "value": "Rāzām"
  },
  {
    "label": "Rāzampeta",
    "value": "Rāzampeta"
  },
  {
    "label": "Rāzole",
    "value": "Rāzole"
  },
  {
    "label": "Renigunta",
    "value": "Renigunta"
  },
  {
    "label": "Repalle",
    "value": "Repalle"
  },
  {
    "label": "Sālūr",
    "value": "Sālūr"
  },
  {
    "label": "Sāmalkot",
    "value": "Sāmalkot"
  },
  {
    "label": "Sattenapalle",
    "value": "Sattenapalle"
  },
  {
    "label": "Singarāyakonda",
    "value": "Singarāyakonda"
  },
  {
    "label": "Sompeta",
    "value": "Sompeta"
  },
  {
    "label": "Srīkākulam",
    "value": "Srīkākulam"
  },
  {
    "label": "Srīsailain",
    "value": "Srīsailain"
  },
  {
    "label": "Sūlūru",
    "value": "Sūlūru"
  },
  {
    "label": "Tādepalle",
    "value": "Tādepalle"
  },
  {
    "label": "Tādepallegūdem",
    "value": "Tādepallegūdem"
  },
  {
    "label": "Tādpatri",
    "value": "Tādpatri"
  },
  {
    "label": "Tekkali",
    "value": "Tekkali"
  },
  {
    "label": "Tirupati",
    "value": "Tirupati"
  },
  {
    "label": "Tuni",
    "value": "Tuni"
  },
  {
    "label": "Uravakonda",
    "value": "Uravakonda"
  },
  {
    "label": "vadlamuru",
    "value": "vadlamuru"
  },
  {
    "label": "Vadlapūdi",
    "value": "Vadlapūdi"
  },
  {
    "label": "Venkatagiri",
    "value": "Venkatagiri"
  },
  {
    "label": "Vepagunta",
    "value": "Vepagunta"
  },
  {
    "label": "Vetapālem",
    "value": "Vetapālem"
  },
  {
    "label": "Vishākhapatnam",
    "value": "Vishākhapatnam"
  },
  {
    "label": "Vizianagaram District",
    "value": "Vizianagaram District"
  },
  {
    "label": "Vuyyūru",
    "value": "Vuyyūru"
  },
  {
    "label": "West Godāvari",
    "value": "West Godāvari"
  },
  {
    "label": "Yanamalakuduru",
    "value": "Yanamalakuduru"
  },
  {
    "label": "Yārāda",
    "value": "Yārāda"
  },
  {
    "label": "Along",
    "value": "Along"
  },
  {
    "label": "Anjaw",
    "value": "Anjaw"
  },
  {
    "label": "Bāsār",
    "value": "Bāsār"
  },
  {
    "label": "Bomdila",
    "value": "Bomdila"
  },
  {
    "label": "Changlang",
    "value": "Changlang"
  },
  {
    "label": "Dibāng Valley",
    "value": "Dibāng Valley"
  },
  {
    "label": "East Kameng",
    "value": "East Kameng"
  },
  {
    "label": "East Siang",
    "value": "East Siang"
  },
  {
    "label": "Hayuliang",
    "value": "Hayuliang"
  },
  {
    "label": "Itānagar",
    "value": "Itānagar"
  },
  {
    "label": "Khonsa",
    "value": "Khonsa"
  },
  {
    "label": "Kurung Kumey",
    "value": "Kurung Kumey"
  },
  {
    "label": "Lohit District",
    "value": "Lohit District"
  },
  {
    "label": "Lower Dibang Valley",
    "value": "Lower Dibang Valley"
  },
  {
    "label": "Lower Subansiri",
    "value": "Lower Subansiri"
  },
  {
    "label": "Margherita",
    "value": "Margherita"
  },
  {
    "label": "Naharlagun",
    "value": "Naharlagun"
  },
  {
    "label": "Pāsighāt",
    "value": "Pāsighāt"
  },
  {
    "label": "Tawang",
    "value": "Tawang"
  },
  {
    "label": "Tezu",
    "value": "Tezu"
  },
  {
    "label": "Tirāp",
    "value": "Tirāp"
  },
  {
    "label": "Upper Siang",
    "value": "Upper Siang"
  },
  {
    "label": "Upper Subansiri",
    "value": "Upper Subansiri"
  },
  {
    "label": "West Kameng",
    "value": "West Kameng"
  },
  {
    "label": "West Siang",
    "value": "West Siang"
  },
  {
    "label": "Ziro",
    "value": "Ziro"
  },
  {
    "label": "Abhayāpuri",
    "value": "Abhayāpuri"
  },
  {
    "label": "Amguri",
    "value": "Amguri"
  },
  {
    "label": "Badarpur",
    "value": "Badarpur"
  },
  {
    "label": "Baksa",
    "value": "Baksa"
  },
  {
    "label": "Barpathār",
    "value": "Barpathār"
  },
  {
    "label": "Barpeta Road",
    "value": "Barpeta Road"
  },
  {
    "label": "Bāsugaon",
    "value": "Bāsugaon"
  },
  {
    "label": "Bihpuriāgaon",
    "value": "Bihpuriāgaon"
  },
  {
    "label": "Bijni",
    "value": "Bijni"
  },
  {
    "label": "Bilāsipāra",
    "value": "Bilāsipāra"
  },
  {
    "label": "Bokajān",
    "value": "Bokajān"
  },
  {
    "label": "Bokākhāt",
    "value": "Bokākhāt"
  },
  {
    "label": "Bongaigaon",
    "value": "Bongaigaon"
  },
  {
    "label": "Cāchār",
    "value": "Cāchār"
  },
  {
    "label": "Chābua",
    "value": "Chābua"
  },
  {
    "label": "Chāpar",
    "value": "Chāpar"
  },
  {
    "label": "Chirang",
    "value": "Chirang"
  },
  {
    "label": "Darrang",
    "value": "Darrang"
  },
  {
    "label": "Dergaon",
    "value": "Dergaon"
  },
  {
    "label": "Dhekiajuli",
    "value": "Dhekiajuli"
  },
  {
    "label": "Dhemaji",
    "value": "Dhemaji"
  },
  {
    "label": "Dhemāji",
    "value": "Dhemāji"
  },
  {
    "label": "Dhing",
    "value": "Dhing"
  },
  {
    "label": "Dhuburi",
    "value": "Dhuburi"
  },
  {
    "label": "Digboi",
    "value": "Digboi"
  },
  {
    "label": "Dima Hasao District",
    "value": "Dima Hasao District"
  },
  {
    "label": "Diphu",
    "value": "Diphu"
  },
  {
    "label": "Dispur",
    "value": "Dispur"
  },
  {
    "label": "Duliāgaon",
    "value": "Duliāgaon"
  },
  {
    "label": "Dum Duma",
    "value": "Dum Duma"
  },
  {
    "label": "Gauripur",
    "value": "Gauripur"
  },
  {
    "label": "Goālpāra",
    "value": "Goālpāra"
  },
  {
    "label": "Gohpur",
    "value": "Gohpur"
  },
  {
    "label": "Golāghāt",
    "value": "Golāghāt"
  },
  {
    "label": "Golakganj",
    "value": "Golakganj"
  },
  {
    "label": "Hāflong",
    "value": "Hāflong"
  },
  {
    "label": "Hailakandi",
    "value": "Hailakandi"
  },
  {
    "label": "Hailākāndi",
    "value": "Hailākāndi"
  },
  {
    "label": "Hājo",
    "value": "Hājo"
  },
  {
    "label": "Hojāi",
    "value": "Hojāi"
  },
  {
    "label": "Howli",
    "value": "Howli"
  },
  {
    "label": "Jogīghopa",
    "value": "Jogīghopa"
  },
  {
    "label": "Jorhāt",
    "value": "Jorhāt"
  },
  {
    "label": "Kāmrūp",
    "value": "Kāmrūp"
  },
  {
    "label": "Kamrup Metropolitan",
    "value": "Kamrup Metropolitan"
  },
  {
    "label": "Kārbi Ānglong",
    "value": "Kārbi Ānglong"
  },
  {
    "label": "Karīmganj",
    "value": "Karīmganj"
  },
  {
    "label": "Khārupatia",
    "value": "Khārupatia"
  },
  {
    "label": "Lakhipur",
    "value": "Lakhipur"
  },
  {
    "label": "Lāla",
    "value": "Lāla"
  },
  {
    "label": "Lumding Railway Colony",
    "value": "Lumding Railway Colony"
  },
  {
    "label": "Mahur",
    "value": "Mahur"
  },
  {
    "label": "Maibong",
    "value": "Maibong"
  },
  {
    "label": "Mākum",
    "value": "Mākum"
  },
  {
    "label": "Mangaldai",
    "value": "Mangaldai"
  },
  {
    "label": "Mariāni",
    "value": "Mariāni"
  },
  {
    "label": "Morānha",
    "value": "Morānha"
  },
  {
    "label": "Morigaon",
    "value": "Morigaon"
  },
  {
    "label": "Nagaon",
    "value": "Nagaon"
  },
  {
    "label": "Nahorkatiya",
    "value": "Nahorkatiya"
  },
  {
    "label": "Nāmrup",
    "value": "Nāmrup"
  },
  {
    "label": "Nāzirā",
    "value": "Nāzirā"
  },
  {
    "label": "North Guwāhāti",
    "value": "North Guwāhāti"
  },
  {
    "label": "Numāligarh",
    "value": "Numāligarh"
  },
  {
    "label": "Palāsbāri",
    "value": "Palāsbāri"
  },
  {
    "label": "Rahā",
    "value": "Rahā"
  },
  {
    "label": "Rangāpāra",
    "value": "Rangāpāra"
  },
  {
    "label": "Rangia",
    "value": "Rangia"
  },
  {
    "label": "Sapatgrām",
    "value": "Sapatgrām"
  },
  {
    "label": "Sarupathar",
    "value": "Sarupathar"
  },
  {
    "label": "Sibsāgar",
    "value": "Sibsāgar"
  },
  {
    "label": "Silapathar",
    "value": "Silapathar"
  },
  {
    "label": "Soalkuchi",
    "value": "Soalkuchi"
  },
  {
    "label": "Sonāri",
    "value": "Sonāri"
  },
  {
    "label": "Sonitpur",
    "value": "Sonitpur"
  },
  {
    "label": "Sorbhog",
    "value": "Sorbhog"
  },
  {
    "label": "Titābar",
    "value": "Titābar"
  },
  {
    "label": "Udalguri",
    "value": "Udalguri"
  },
  {
    "label": "Amarpur",
    "value": "Amarpur"
  },
  {
    "label": "Araria",
    "value": "Araria"
  },
  {
    "label": "Arāria",
    "value": "Arāria"
  },
  {
    "label": "Arrah",
    "value": "Arrah"
  },
  {
    "label": "Arwal",
    "value": "Arwal"
  },
  {
    "label": "Asarganj",
    "value": "Asarganj"
  },
  {
    "label": "Aurangābād",
    "value": "Aurangābād"
  },
  {
    "label": "Bagaha",
    "value": "Bagaha"
  },
  {
    "label": "Bahādurganj",
    "value": "Bahādurganj"
  },
  {
    "label": "Bairāgnia",
    "value": "Bairāgnia"
  },
  {
    "label": "Baisi",
    "value": "Baisi"
  },
  {
    "label": "Bakhtiyārpur",
    "value": "Bakhtiyārpur"
  },
  {
    "label": "Bānka",
    "value": "Bānka"
  },
  {
    "label": "Banmankhi",
    "value": "Banmankhi"
  },
  {
    "label": "Bar Bigha",
    "value": "Bar Bigha"
  },
  {
    "label": "Barauli",
    "value": "Barauli"
  },
  {
    "label": "Bārh",
    "value": "Bārh"
  },
  {
    "label": "Barhiya",
    "value": "Barhiya"
  },
  {
    "label": "Bariārpur",
    "value": "Bariārpur"
  },
  {
    "label": "Bāruni",
    "value": "Bāruni"
  },
  {
    "label": "Begusarāi",
    "value": "Begusarāi"
  },
  {
    "label": "Belsand",
    "value": "Belsand"
  },
  {
    "label": "Bettiah",
    "value": "Bettiah"
  },
  {
    "label": "Bhabhua",
    "value": "Bhabhua"
  },
  {
    "label": "Bhāgalpur",
    "value": "Bhāgalpur"
  },
  {
    "label": "Bhagirathpur",
    "value": "Bhagirathpur"
  },
  {
    "label": "Bhawanipur",
    "value": "Bhawanipur"
  },
  {
    "label": "Bhojpur",
    "value": "Bhojpur"
  },
  {
    "label": "Bihār Sharīf",
    "value": "Bihār Sharīf"
  },
  {
    "label": "Bihārīganj",
    "value": "Bihārīganj"
  },
  {
    "label": "Bikramganj",
    "value": "Bikramganj"
  },
  {
    "label": "Bīrpur",
    "value": "Bīrpur"
  },
  {
    "label": "Buddh Gaya",
    "value": "Buddh Gaya"
  },
  {
    "label": "Buxar",
    "value": "Buxar"
  },
  {
    "label": "Chākia",
    "value": "Chākia"
  },
  {
    "label": "Chāpra",
    "value": "Chāpra"
  },
  {
    "label": "Chhātāpur",
    "value": "Chhātāpur"
  },
  {
    "label": "Colgong",
    "value": "Colgong"
  },
  {
    "label": "Dalsingh Sarai",
    "value": "Dalsingh Sarai"
  },
  {
    "label": "Daudnagar",
    "value": "Daudnagar"
  },
  {
    "label": "Dehri",
    "value": "Dehri"
  },
  {
    "label": "Dhāka",
    "value": "Dhāka"
  },
  {
    "label": "Dighwāra",
    "value": "Dighwāra"
  },
  {
    "label": "Dinapore",
    "value": "Dinapore"
  },
  {
    "label": "Dumra",
    "value": "Dumra"
  },
  {
    "label": "Dumraon",
    "value": "Dumraon"
  },
  {
    "label": "Fatwa",
    "value": "Fatwa"
  },
  {
    "label": "Forbesganj",
    "value": "Forbesganj"
  },
  {
    "label": "Gayā",
    "value": "Gayā"
  },
  {
    "label": "Ghoga",
    "value": "Ghoga"
  },
  {
    "label": "Gopālganj",
    "value": "Gopālganj"
  },
  {
    "label": "Hājīpur",
    "value": "Hājīpur"
  },
  {
    "label": "Hilsa",
    "value": "Hilsa"
  },
  {
    "label": "Hisuā",
    "value": "Hisuā"
  },
  {
    "label": "Islāmpur",
    "value": "Islāmpur"
  },
  {
    "label": "Jagdīspur",
    "value": "Jagdīspur"
  },
  {
    "label": "Jahānābād",
    "value": "Jahānābād"
  },
  {
    "label": "Jamālpur",
    "value": "Jamālpur"
  },
  {
    "label": "Jamui",
    "value": "Jamui"
  },
  {
    "label": "Jamūī",
    "value": "Jamūī"
  },
  {
    "label": "Jehanabad",
    "value": "Jehanabad"
  },
  {
    "label": "Jhā-Jhā",
    "value": "Jhā-Jhā"
  },
  {
    "label": "Jhanjhārpur",
    "value": "Jhanjhārpur"
  },
  {
    "label": "Jogbani",
    "value": "Jogbani"
  },
  {
    "label": "Kaimur District",
    "value": "Kaimur District"
  },
  {
    "label": "Kasba",
    "value": "Kasba"
  },
  {
    "label": "Katihar",
    "value": "Katihar"
  },
  {
    "label": "Khagaria",
    "value": "Khagaria"
  },
  {
    "label": "Khagaul",
    "value": "Khagaul"
  },
  {
    "label": "Khusropur",
    "value": "Khusropur"
  },
  {
    "label": "Kishanganj",
    "value": "Kishanganj"
  },
  {
    "label": "Koāth",
    "value": "Koāth"
  },
  {
    "label": "Koelwār",
    "value": "Koelwār"
  },
  {
    "label": "Lakhisarai",
    "value": "Lakhisarai"
  },
  {
    "label": "Lālganj",
    "value": "Lālganj"
  },
  {
    "label": "Luckeesarai",
    "value": "Luckeesarai"
  },
  {
    "label": "Madhepura",
    "value": "Madhepura"
  },
  {
    "label": "Madhipura",
    "value": "Madhipura"
  },
  {
    "label": "Madhubani",
    "value": "Madhubani"
  },
  {
    "label": "Mahārājgani",
    "value": "Mahārājgani"
  },
  {
    "label": "Mairwa",
    "value": "Mairwa"
  },
  {
    "label": "Maner",
    "value": "Maner"
  },
  {
    "label": "Manihāri",
    "value": "Manihāri"
  },
  {
    "label": "Marhaura",
    "value": "Marhaura"
  },
  {
    "label": "Masaurhi Buzurg",
    "value": "Masaurhi Buzurg"
  },
  {
    "label": "Mohiuddinnagar",
    "value": "Mohiuddinnagar"
  },
  {
    "label": "Mokameh",
    "value": "Mokameh"
  },
  {
    "label": "Monghyr",
    "value": "Monghyr"
  },
  {
    "label": "Mothīhāri",
    "value": "Mothīhāri"
  },
  {
    "label": "Murlīganj",
    "value": "Murlīganj"
  },
  {
    "label": "Nabīnagar",
    "value": "Nabīnagar"
  },
  {
    "label": "Nālanda",
    "value": "Nālanda"
  },
  {
    "label": "Nāsriganj",
    "value": "Nāsriganj"
  },
  {
    "label": "Naugachhia",
    "value": "Naugachhia"
  },
  {
    "label": "Nawāda",
    "value": "Nawāda"
  },
  {
    "label": "Nirmāli",
    "value": "Nirmāli"
  },
  {
    "label": "Pashchim Champāran",
    "value": "Pashchim Champāran"
  },
  {
    "label": "Piro",
    "value": "Piro"
  },
  {
    "label": "Pupri",
    "value": "Pupri"
  },
  {
    "label": "Pūrba Champāran",
    "value": "Pūrba Champāran"
  },
  {
    "label": "Purnia",
    "value": "Purnia"
  },
  {
    "label": "Rafiganj",
    "value": "Rafiganj"
  },
  {
    "label": "Raghunāthpur",
    "value": "Raghunāthpur"
  },
  {
    "label": "Rājgīr",
    "value": "Rājgīr"
  },
  {
    "label": "Rāmnagar",
    "value": "Rāmnagar"
  },
  {
    "label": "Revelganj",
    "value": "Revelganj"
  },
  {
    "label": "Rohtās",
    "value": "Rohtās"
  },
  {
    "label": "Rusera",
    "value": "Rusera"
  },
  {
    "label": "Sagauli",
    "value": "Sagauli"
  },
  {
    "label": "Saharsa",
    "value": "Saharsa"
  },
  {
    "label": "Samastīpur",
    "value": "Samastīpur"
  },
  {
    "label": "Samāstipur",
    "value": "Samāstipur"
  },
  {
    "label": "Sāran",
    "value": "Sāran"
  },
  {
    "label": "Shahbazpur",
    "value": "Shahbazpur"
  },
  {
    "label": "Shāhpur",
    "value": "Shāhpur"
  },
  {
    "label": "Sheikhpura",
    "value": "Sheikhpura"
  },
  {
    "label": "Sheohar",
    "value": "Sheohar"
  },
  {
    "label": "Sherghāti",
    "value": "Sherghāti"
  },
  {
    "label": "Silao",
    "value": "Silao"
  },
  {
    "label": "Sītāmarhi",
    "value": "Sītāmarhi"
  },
  {
    "label": "Siwān",
    "value": "Siwān"
  },
  {
    "label": "Supaul",
    "value": "Supaul"
  },
  {
    "label": "Teghra",
    "value": "Teghra"
  },
  {
    "label": "Tekāri",
    "value": "Tekāri"
  },
  {
    "label": "Thākurganj",
    "value": "Thākurganj"
  },
  {
    "label": "Vaishāli",
    "value": "Vaishāli"
  },
  {
    "label": "Wāris Alīganj",
    "value": "Wāris Alīganj"
  },
  {
    "label": "Akaltara",
    "value": "Akaltara"
  },
  {
    "label": "Ambāgarh Chauki",
    "value": "Ambāgarh Chauki"
  },
  {
    "label": "Ambikāpur",
    "value": "Ambikāpur"
  },
  {
    "label": "Arang",
    "value": "Arang"
  },
  {
    "label": "Baikunthpur",
    "value": "Baikunthpur"
  },
  {
    "label": "Balod",
    "value": "Balod"
  },
  {
    "label": "Baloda",
    "value": "Baloda"
  },
  {
    "label": "Baloda Bāzār",
    "value": "Baloda Bāzār"
  },
  {
    "label": "Basna",
    "value": "Basna"
  },
  {
    "label": "Bastar",
    "value": "Bastar"
  },
  {
    "label": "Bemetāra",
    "value": "Bemetāra"
  },
  {
    "label": "Bhānpurī",
    "value": "Bhānpurī"
  },
  {
    "label": "Bhātāpāra",
    "value": "Bhātāpāra"
  },
  {
    "label": "Bhatgaon",
    "value": "Bhatgaon"
  },
  {
    "label": "Bilāspur",
    "value": "Bilāspur"
  },
  {
    "label": "Chāmpa",
    "value": "Chāmpa"
  },
  {
    "label": "Chhuīkhadān",
    "value": "Chhuīkhadān"
  },
  {
    "label": "Deori",
    "value": "Deori"
  },
  {
    "label": "Dongargaon",
    "value": "Dongargaon"
  },
  {
    "label": "Dongargarh",
    "value": "Dongargarh"
  },
  {
    "label": "Gandai",
    "value": "Gandai"
  },
  {
    "label": "Gariāband",
    "value": "Gariāband"
  },
  {
    "label": "Gaurela",
    "value": "Gaurela"
  },
  {
    "label": "Gharghoda",
    "value": "Gharghoda"
  },
  {
    "label": "Gīdam",
    "value": "Gīdam"
  },
  {
    "label": "Jānjgīr",
    "value": "Jānjgīr"
  },
  {
    "label": "Janjgir-Champa",
    "value": "Janjgir-Champa"
  },
  {
    "label": "Jashpur",
    "value": "Jashpur"
  },
  {
    "label": "Jashpurnagar",
    "value": "Jashpurnagar"
  },
  {
    "label": "Jūnāgarh",
    "value": "Jūnāgarh"
  },
  {
    "label": "Kabeerdham",
    "value": "Kabeerdham"
  },
  {
    "label": "Kānker",
    "value": "Kānker"
  },
  {
    "label": "Katghora",
    "value": "Katghora"
  },
  {
    "label": "Kawardha",
    "value": "Kawardha"
  },
  {
    "label": "Khairāgarh",
    "value": "Khairāgarh"
  },
  {
    "label": "Khamharia",
    "value": "Khamharia"
  },
  {
    "label": "Kharod",
    "value": "Kharod"
  },
  {
    "label": "Kharsia",
    "value": "Kharsia"
  },
  {
    "label": "Kirandul",
    "value": "Kirandul"
  },
  {
    "label": "Kondagaon",
    "value": "Kondagaon"
  },
  {
    "label": "Koriya",
    "value": "Koriya"
  },
  {
    "label": "Kotā",
    "value": "Kotā"
  },
  {
    "label": "Kotapārh",
    "value": "Kotapārh"
  },
  {
    "label": "Kumhāri",
    "value": "Kumhāri"
  },
  {
    "label": "Kurud",
    "value": "Kurud"
  },
  {
    "label": "Lormi",
    "value": "Lormi"
  },
  {
    "label": "Mahasamund",
    "value": "Mahasamund"
  },
  {
    "label": "Mahāsamund",
    "value": "Mahāsamund"
  },
  {
    "label": "Mungeli",
    "value": "Mungeli"
  },
  {
    "label": "Narayanpur",
    "value": "Narayanpur"
  },
  {
    "label": "Narharpur",
    "value": "Narharpur"
  },
  {
    "label": "Pandaria",
    "value": "Pandaria"
  },
  {
    "label": "Pāndātarai",
    "value": "Pāndātarai"
  },
  {
    "label": "Pasān",
    "value": "Pasān"
  },
  {
    "label": "Pātan",
    "value": "Pātan"
  },
  {
    "label": "Pathalgaon",
    "value": "Pathalgaon"
  },
  {
    "label": "Pendra",
    "value": "Pendra"
  },
  {
    "label": "Pithora",
    "value": "Pithora"
  },
  {
    "label": "Rāj Nāndgaon",
    "value": "Rāj Nāndgaon"
  },
  {
    "label": "Rāj-Nāndgaon",
    "value": "Rāj-Nāndgaon"
  },
  {
    "label": "Rāmānuj Ganj",
    "value": "Rāmānuj Ganj"
  },
  {
    "label": "Ratanpur",
    "value": "Ratanpur"
  },
  {
    "label": "Saktī",
    "value": "Saktī"
  },
  {
    "label": "Saraipali",
    "value": "Saraipali"
  },
  {
    "label": "Sārangarh",
    "value": "Sārangarh"
  },
  {
    "label": "Seorīnārāyan",
    "value": "Seorīnārāyan"
  },
  {
    "label": "Simga",
    "value": "Simga"
  },
  {
    "label": "Surguja",
    "value": "Surguja"
  },
  {
    "label": "Takhatpur",
    "value": "Takhatpur"
  },
  {
    "label": "Umarkot",
    "value": "Umarkot"
  },
  {
    "label": "Uttar Bastar Kanker",
    "value": "Uttar Bastar Kanker"
  },
  {
    "label": "Āmli",
    "value": "Āmli"
  },
  {
    "label": "Dadra",
    "value": "Dadra"
  },
  {
    "label": "Dadra & Nagar Haveli",
    "value": "Dadra & Nagar Haveli"
  },
  {
    "label": "Daman District",
    "value": "Daman District"
  },
  {
    "label": "Diu",
    "value": "Diu"
  },
  {
    "label": "Alīpur",
    "value": "Alīpur"
  },
  {
    "label": "Bawāna",
    "value": "Bawāna"
  },
  {
    "label": "Central Delhi",
    "value": "Central Delhi"
  },
  {
    "label": "Deoli",
    "value": "Deoli"
  },
  {
    "label": "East Delhi",
    "value": "East Delhi"
  },
  {
    "label": "Karol Bāgh",
    "value": "Karol Bāgh"
  },
  {
    "label": "Najafgarh",
    "value": "Najafgarh"
  },
  {
    "label": "Nāngloi Jāt",
    "value": "Nāngloi Jāt"
  },
  {
    "label": "North Delhi",
    "value": "North Delhi"
  },
  {
    "label": "North East Delhi",
    "value": "North East Delhi"
  },
  {
    "label": "North West Delhi",
    "value": "North West Delhi"
  },
  {
    "label": "Rohini",
    "value": "Rohini"
  },
  {
    "label": "South Delhi",
    "value": "South Delhi"
  },
  {
    "label": "South West Delhi",
    "value": "South West Delhi"
  },
  {
    "label": "West Delhi",
    "value": "West Delhi"
  },
  {
    "label": "Arambol",
    "value": "Arambol"
  },
  {
    "label": "Baga",
    "value": "Baga"
  },
  {
    "label": "Bandora",
    "value": "Bandora"
  },
  {
    "label": "Benaulim",
    "value": "Benaulim"
  },
  {
    "label": "Carapur",
    "value": "Carapur"
  },
  {
    "label": "Cavelossim",
    "value": "Cavelossim"
  },
  {
    "label": "Chinchinim",
    "value": "Chinchinim"
  },
  {
    "label": "Colovale",
    "value": "Colovale"
  },
  {
    "label": "Colva",
    "value": "Colva"
  },
  {
    "label": "Cortalim",
    "value": "Cortalim"
  },
  {
    "label": "Curti",
    "value": "Curti"
  },
  {
    "label": "Davorlim",
    "value": "Davorlim"
  },
  {
    "label": "Goa Velha",
    "value": "Goa Velha"
  },
  {
    "label": "Guirim",
    "value": "Guirim"
  },
  {
    "label": "Jua",
    "value": "Jua"
  },
  {
    "label": "Kānkon",
    "value": "Kānkon"
  },
  {
    "label": "Māpuca",
    "value": "Māpuca"
  },
  {
    "label": "Morjim",
    "value": "Morjim"
  },
  {
    "label": "Mormugao",
    "value": "Mormugao"
  },
  {
    "label": "North Goa",
    "value": "North Goa"
  },
  {
    "label": "Palle",
    "value": "Palle"
  },
  {
    "label": "Panaji",
    "value": "Panaji"
  },
  {
    "label": "Pernem",
    "value": "Pernem"
  },
  {
    "label": "Queula",
    "value": "Queula"
  },
  {
    "label": "Raia",
    "value": "Raia"
  },
  {
    "label": "Sancoale",
    "value": "Sancoale"
  },
  {
    "label": "Sanguem",
    "value": "Sanguem"
  },
  {
    "label": "Sanvordem",
    "value": "Sanvordem"
  },
  {
    "label": "Serula",
    "value": "Serula"
  },
  {
    "label": "South Goa",
    "value": "South Goa"
  },
  {
    "label": "Taleigao",
    "value": "Taleigao"
  },
  {
    "label": "Vagator",
    "value": "Vagator"
  },
  {
    "label": "Valpoy",
    "value": "Valpoy"
  },
  {
    "label": "Varca",
    "value": "Varca"
  },
  {
    "label": "Vasco da Gama",
    "value": "Vasco da Gama"
  },
  {
    "label": "Abrama",
    "value": "Abrama"
  },
  {
    "label": "Adalaj",
    "value": "Adalaj"
  },
  {
    "label": "Ahmadābād",
    "value": "Ahmadābād"
  },
  {
    "label": "Ahwa",
    "value": "Ahwa"
  },
  {
    "label": "Amod",
    "value": "Amod"
  },
  {
    "label": "Amroli",
    "value": "Amroli"
  },
  {
    "label": "Anjar",
    "value": "Anjar"
  },
  {
    "label": "Babra",
    "value": "Babra"
  },
  {
    "label": "Bagasara",
    "value": "Bagasara"
  },
  {
    "label": "Bagasra",
    "value": "Bagasra"
  },
  {
    "label": "Banas Kantha",
    "value": "Banas Kantha"
  },
  {
    "label": "Bantva",
    "value": "Bantva"
  },
  {
    "label": "Bedi",
    "value": "Bedi"
  },
  {
    "label": "Bhachau",
    "value": "Bhachau"
  },
  {
    "label": "Bhanvad",
    "value": "Bhanvad"
  },
  {
    "label": "Bhayavadar",
    "value": "Bhayavadar"
  },
  {
    "label": "Bilkha",
    "value": "Bilkha"
  },
  {
    "label": "Borsad",
    "value": "Borsad"
  },
  {
    "label": "Chaklasi",
    "value": "Chaklasi"
  },
  {
    "label": "Chalala",
    "value": "Chalala"
  },
  {
    "label": "Chanasma",
    "value": "Chanasma"
  },
  {
    "label": "Chhala",
    "value": "Chhala"
  },
  {
    "label": "Chikhli",
    "value": "Chikhli"
  },
  {
    "label": "Chotila",
    "value": "Chotila"
  },
  {
    "label": "Dabhoi",
    "value": "Dabhoi"
  },
  {
    "label": "Dahegam",
    "value": "Dahegam"
  },
  {
    "label": "Dakor",
    "value": "Dakor"
  },
  {
    "label": "Damnagar",
    "value": "Damnagar"
  },
  {
    "label": "Dangs (India)",
    "value": "Dangs (India)"
  },
  {
    "label": "Dayapar",
    "value": "Dayapar"
  },
  {
    "label": "Delvada",
    "value": "Delvada"
  },
  {
    "label": "Delwada",
    "value": "Delwada"
  },
  {
    "label": "Devbhumi Dwarka",
    "value": "Devbhumi Dwarka"
  },
  {
    "label": "Devgadh Bariya",
    "value": "Devgadh Bariya"
  },
  {
    "label": "Dhandhuka",
    "value": "Dhandhuka"
  },
  {
    "label": "Dhanera",
    "value": "Dhanera"
  },
  {
    "label": "Dharampur",
    "value": "Dharampur"
  },
  {
    "label": "Dhāri",
    "value": "Dhāri"
  },
  {
    "label": "Dhola",
    "value": "Dhola"
  },
  {
    "label": "Dholka",
    "value": "Dholka"
  },
  {
    "label": "Dhoraji",
    "value": "Dhoraji"
  },
  {
    "label": "Dhrangadhra",
    "value": "Dhrangadhra"
  },
  {
    "label": "Dhuwaran",
    "value": "Dhuwaran"
  },
  {
    "label": "Disa",
    "value": "Disa"
  },
  {
    "label": "Dohad",
    "value": "Dohad"
  },
  {
    "label": "Dungarpur",
    "value": "Dungarpur"
  },
  {
    "label": "Gadhada",
    "value": "Gadhada"
  },
  {
    "label": "Gandevi",
    "value": "Gandevi"
  },
  {
    "label": "Gariadhar",
    "value": "Gariadhar"
  },
  {
    "label": "Ghogha",
    "value": "Ghogha"
  },
  {
    "label": "Gir Somnath",
    "value": "Gir Somnath"
  },
  {
    "label": "Gondal",
    "value": "Gondal"
  },
  {
    "label": "Halol",
    "value": "Halol"
  },
  {
    "label": "Halvad",
    "value": "Halvad"
  },
  {
    "label": "Hansot",
    "value": "Hansot"
  },
  {
    "label": "Harij",
    "value": "Harij"
  },
  {
    "label": "Jalalpore",
    "value": "Jalalpore"
  },
  {
    "label": "Jambusar",
    "value": "Jambusar"
  },
  {
    "label": "Jasdan",
    "value": "Jasdan"
  },
  {
    "label": "Jetalsar",
    "value": "Jetalsar"
  },
  {
    "label": "Jetpur",
    "value": "Jetpur"
  },
  {
    "label": "Jhulasan",
    "value": "Jhulasan"
  },
  {
    "label": "Jodia",
    "value": "Jodia"
  },
  {
    "label": "Jodiya Bandar",
    "value": "Jodiya Bandar"
  },
  {
    "label": "Kachchh",
    "value": "Kachchh"
  },
  {
    "label": "Kadi",
    "value": "Kadi"
  },
  {
    "label": "Kadod",
    "value": "Kadod"
  },
  {
    "label": "Kalavad",
    "value": "Kalavad"
  },
  {
    "label": "Kālol",
    "value": "Kālol"
  },
  {
    "label": "Kandla",
    "value": "Kandla"
  },
  {
    "label": "Kanodar",
    "value": "Kanodar"
  },
  {
    "label": "Kapadvanj",
    "value": "Kapadvanj"
  },
  {
    "label": "Kathor",
    "value": "Kathor"
  },
  {
    "label": "Katpur",
    "value": "Katpur"
  },
  {
    "label": "Kavant",
    "value": "Kavant"
  },
  {
    "label": "Kawant",
    "value": "Kawant"
  },
  {
    "label": "Khambhalia",
    "value": "Khambhalia"
  },
  {
    "label": "Khambhaliya",
    "value": "Khambhaliya"
  },
  {
    "label": "Khedbrahma",
    "value": "Khedbrahma"
  },
  {
    "label": "Kheralu",
    "value": "Kheralu"
  },
  {
    "label": "Kodīnar",
    "value": "Kodīnar"
  },
  {
    "label": "Kundla",
    "value": "Kundla"
  },
  {
    "label": "Kutch district",
    "value": "Kutch district"
  },
  {
    "label": "Kutiyana",
    "value": "Kutiyana"
  },
  {
    "label": "Lakhtar",
    "value": "Lakhtar"
  },
  {
    "label": "Lalpur",
    "value": "Lalpur"
  },
  {
    "label": "Lathi",
    "value": "Lathi"
  },
  {
    "label": "Limbdi",
    "value": "Limbdi"
  },
  {
    "label": "Lunavada",
    "value": "Lunavada"
  },
  {
    "label": "Mahemdavad",
    "value": "Mahemdavad"
  },
  {
    "label": "Mahudha",
    "value": "Mahudha"
  },
  {
    "label": "Manavadar",
    "value": "Manavadar"
  },
  {
    "label": "Māndal",
    "value": "Māndal"
  },
  {
    "label": "Mandvi (Surat)",
    "value": "Mandvi (Surat)"
  },
  {
    "label": "Mangrol",
    "value": "Mangrol"
  },
  {
    "label": "Meghraj",
    "value": "Meghraj"
  },
  {
    "label": "Mehsana",
    "value": "Mehsana"
  },
  {
    "label": "Mendarda",
    "value": "Mendarda"
  },
  {
    "label": "Morbi",
    "value": "Morbi"
  },
  {
    "label": "Morva (Hadaf)",
    "value": "Morva (Hadaf)"
  },
  {
    "label": "Morwa",
    "value": "Morwa"
  },
  {
    "label": "Naliya",
    "value": "Naliya"
  },
  {
    "label": "Narmada",
    "value": "Narmada"
  },
  {
    "label": "Naroda",
    "value": "Naroda"
  },
  {
    "label": "Okha",
    "value": "Okha"
  },
  {
    "label": "Olpad",
    "value": "Olpad"
  },
  {
    "label": "Paddhari",
    "value": "Paddhari"
  },
  {
    "label": "Padra",
    "value": "Padra"
  },
  {
    "label": "Palanpur",
    "value": "Palanpur"
  },
  {
    "label": "Palitana",
    "value": "Palitana"
  },
  {
    "label": "Paliyad",
    "value": "Paliyad"
  },
  {
    "label": "Panch Mahals",
    "value": "Panch Mahals"
  },
  {
    "label": "Panchmahal district",
    "value": "Panchmahal district"
  },
  {
    "label": "Pardi",
    "value": "Pardi"
  },
  {
    "label": "Parnera",
    "value": "Parnera"
  },
  {
    "label": "Pavi Jetpur",
    "value": "Pavi Jetpur"
  },
  {
    "label": "Radhanpur",
    "value": "Radhanpur"
  },
  {
    "label": "Rajpipla",
    "value": "Rajpipla"
  },
  {
    "label": "Rajula",
    "value": "Rajula"
  },
  {
    "label": "Ranavav",
    "value": "Ranavav"
  },
  {
    "label": "Rapar",
    "value": "Rapar"
  },
  {
    "label": "Roha",
    "value": "Roha"
  },
  {
    "label": "Sabar Kantha",
    "value": "Sabar Kantha"
  },
  {
    "label": "Salaya",
    "value": "Salaya"
  },
  {
    "label": "Sankheda",
    "value": "Sankheda"
  },
  {
    "label": "Sarkhej",
    "value": "Sarkhej"
  },
  {
    "label": "Savarkundla",
    "value": "Savarkundla"
  },
  {
    "label": "Sayla",
    "value": "Sayla"
  },
  {
    "label": "Shahpur",
    "value": "Shahpur"
  },
  {
    "label": "Shivrajpur",
    "value": "Shivrajpur"
  },
  {
    "label": "Siddhapur",
    "value": "Siddhapur"
  },
  {
    "label": "Siddhpur",
    "value": "Siddhpur"
  },
  {
    "label": "Sihor",
    "value": "Sihor"
  },
  {
    "label": "Sinor",
    "value": "Sinor"
  },
  {
    "label": "Sojitra",
    "value": "Sojitra"
  },
  {
    "label": "Songadh",
    "value": "Songadh"
  },
  {
    "label": "Surat",
    "value": "Surat"
  },
  {
    "label": "Talaja",
    "value": "Talaja"
  },
  {
    "label": "Tankara",
    "value": "Tankara"
  },
  {
    "label": "Tapi",
    "value": "Tapi"
  },
  {
    "label": "Than",
    "value": "Than"
  },
  {
    "label": "Thangadh",
    "value": "Thangadh"
  },
  {
    "label": "Tharad",
    "value": "Tharad"
  },
  {
    "label": "The Dāngs",
    "value": "The Dāngs"
  },
  {
    "label": "Umrala",
    "value": "Umrala"
  },
  {
    "label": "Umreth",
    "value": "Umreth"
  },
  {
    "label": "Un",
    "value": "Un"
  },
  {
    "label": "Una",
    "value": "Una"
  },
  {
    "label": "Unjha",
    "value": "Unjha"
  },
  {
    "label": "Upleta",
    "value": "Upleta"
  },
  {
    "label": "Utran",
    "value": "Utran"
  },
  {
    "label": "Vadnagar",
    "value": "Vadnagar"
  },
  {
    "label": "Vaghodia",
    "value": "Vaghodia"
  },
  {
    "label": "Valabhipur",
    "value": "Valabhipur"
  },
  {
    "label": "Vallabh Vidyanagar",
    "value": "Vallabh Vidyanagar"
  },
  {
    "label": "Vallabhipur",
    "value": "Vallabhipur"
  },
  {
    "label": "Vansada",
    "value": "Vansada"
  },
  {
    "label": "Vansda",
    "value": "Vansda"
  },
  {
    "label": "Vartej",
    "value": "Vartej"
  },
  {
    "label": "Vasa",
    "value": "Vasa"
  },
  {
    "label": "Vaso",
    "value": "Vaso"
  },
  {
    "label": "Vejalpur",
    "value": "Vejalpur"
  },
  {
    "label": "Veraval",
    "value": "Veraval"
  },
  {
    "label": "Vinchhiya",
    "value": "Vinchhiya"
  },
  {
    "label": "Vinchia",
    "value": "Vinchia"
  },
  {
    "label": "Virpur",
    "value": "Virpur"
  },
  {
    "label": "Visavadar",
    "value": "Visavadar"
  },
  {
    "label": "Vyara",
    "value": "Vyara"
  },
  {
    "label": "Wadhai",
    "value": "Wadhai"
  },
  {
    "label": "Wadhwan",
    "value": "Wadhwan"
  },
  {
    "label": "Waghai",
    "value": "Waghai"
  },
  {
    "label": "Wankaner",
    "value": "Wankaner"
  },
  {
    "label": "Ambāla",
    "value": "Ambāla"
  },
  {
    "label": "Āsandh",
    "value": "Āsandh"
  },
  {
    "label": "Ateli Mandi",
    "value": "Ateli Mandi"
  },
  {
    "label": "Bahādurgarh",
    "value": "Bahādurgarh"
  },
  {
    "label": "Bara Uchāna",
    "value": "Bara Uchāna"
  },
  {
    "label": "Barwāla",
    "value": "Barwāla"
  },
  {
    "label": "Bāwal",
    "value": "Bāwal"
  },
  {
    "label": "Beri Khās",
    "value": "Beri Khās"
  },
  {
    "label": "Bhiwāni",
    "value": "Bhiwāni"
  },
  {
    "label": "Būriya",
    "value": "Būriya"
  },
  {
    "label": "Charkhi Dādri",
    "value": "Charkhi Dādri"
  },
  {
    "label": "Dabwāli",
    "value": "Dabwāli"
  },
  {
    "label": "Dhāruhera",
    "value": "Dhāruhera"
  },
  {
    "label": "Ellenabad",
    "value": "Ellenabad"
  },
  {
    "label": "Faridabad District",
    "value": "Faridabad District"
  },
  {
    "label": "Farrukhnagar",
    "value": "Farrukhnagar"
  },
  {
    "label": "Fatehābād",
    "value": "Fatehābād"
  },
  {
    "label": "Fatehabad District",
    "value": "Fatehabad District"
  },
  {
    "label": "Fīrozpur Jhirka",
    "value": "Fīrozpur Jhirka"
  },
  {
    "label": "Gharaunda",
    "value": "Gharaunda"
  },
  {
    "label": "Gohāna",
    "value": "Gohāna"
  },
  {
    "label": "Hānsi",
    "value": "Hānsi"
  },
  {
    "label": "Hasanpur",
    "value": "Hasanpur"
  },
  {
    "label": "Hisār",
    "value": "Hisār"
  },
  {
    "label": "Hodal",
    "value": "Hodal"
  },
  {
    "label": "Inda Chhoi",
    "value": "Inda Chhoi"
  },
  {
    "label": "Indri",
    "value": "Indri"
  },
  {
    "label": "Jagādhri",
    "value": "Jagādhri"
  },
  {
    "label": "Jākhal",
    "value": "Jākhal"
  },
  {
    "label": "Jīnd",
    "value": "Jīnd"
  },
  {
    "label": "Kalānaur",
    "value": "Kalānaur"
  },
  {
    "label": "Kālānwāli",
    "value": "Kālānwāli"
  },
  {
    "label": "Kanīna Khās",
    "value": "Kanīna Khās"
  },
  {
    "label": "Karnāl",
    "value": "Karnāl"
  },
  {
    "label": "Kharkhauda",
    "value": "Kharkhauda"
  },
  {
    "label": "Kheri Sāmpla",
    "value": "Kheri Sāmpla"
  },
  {
    "label": "Kurukshetra",
    "value": "Kurukshetra"
  },
  {
    "label": "Lādwa",
    "value": "Lādwa"
  },
  {
    "label": "Lohāru",
    "value": "Lohāru"
  },
  {
    "label": "Maham",
    "value": "Maham"
  },
  {
    "label": "Mahendragarh",
    "value": "Mahendragarh"
  },
  {
    "label": "Mandholi Kalān",
    "value": "Mandholi Kalān"
  },
  {
    "label": "Mustafābād",
    "value": "Mustafābād"
  },
  {
    "label": "Narāyangarh",
    "value": "Narāyangarh"
  },
  {
    "label": "Nārnaul",
    "value": "Nārnaul"
  },
  {
    "label": "Nārnaund",
    "value": "Nārnaund"
  },
  {
    "label": "Narwāna",
    "value": "Narwāna"
  },
  {
    "label": "Nīlokheri",
    "value": "Nīlokheri"
  },
  {
    "label": "Nūh",
    "value": "Nūh"
  },
  {
    "label": "Panchkula",
    "value": "Panchkula"
  },
  {
    "label": "Pānīpat",
    "value": "Pānīpat"
  },
  {
    "label": "Pataudi",
    "value": "Pataudi"
  },
  {
    "label": "Pinjaur",
    "value": "Pinjaur"
  },
  {
    "label": "Pūnāhāna",
    "value": "Pūnāhāna"
  },
  {
    "label": "Pūndri",
    "value": "Pūndri"
  },
  {
    "label": "Radaur",
    "value": "Radaur"
  },
  {
    "label": "Rānia",
    "value": "Rānia"
  },
  {
    "label": "Ratia",
    "value": "Ratia"
  },
  {
    "label": "Rewāri",
    "value": "Rewāri"
  },
  {
    "label": "Rewari District",
    "value": "Rewari District"
  },
  {
    "label": "Safidon",
    "value": "Safidon"
  },
  {
    "label": "Samālkha",
    "value": "Samālkha"
  },
  {
    "label": "Shādīpur Julāna",
    "value": "Shādīpur Julāna"
  },
  {
    "label": "Shāhābād",
    "value": "Shāhābād"
  },
  {
    "label": "Sohna",
    "value": "Sohna"
  },
  {
    "label": "Sonīpat",
    "value": "Sonīpat"
  },
  {
    "label": "Tāoru",
    "value": "Tāoru"
  },
  {
    "label": "Thānesar",
    "value": "Thānesar"
  },
  {
    "label": "Tohāna",
    "value": "Tohāna"
  },
  {
    "label": "Toshām",
    "value": "Toshām"
  },
  {
    "label": "Uklāna",
    "value": "Uklāna"
  },
  {
    "label": "Yamunānagar",
    "value": "Yamunānagar"
  },
  {
    "label": "Arki",
    "value": "Arki"
  },
  {
    "label": "Banjār",
    "value": "Banjār"
  },
  {
    "label": "Chaupāl",
    "value": "Chaupāl"
  },
  {
    "label": "Chowari",
    "value": "Chowari"
  },
  {
    "label": "Chuāri Khās",
    "value": "Chuāri Khās"
  },
  {
    "label": "Dagshai",
    "value": "Dagshai"
  },
  {
    "label": "Dalhousie",
    "value": "Dalhousie"
  },
  {
    "label": "Daulatpur",
    "value": "Daulatpur"
  },
  {
    "label": "Dera Gopipur",
    "value": "Dera Gopipur"
  },
  {
    "label": "Gagret",
    "value": "Gagret"
  },
  {
    "label": "Ghumārwīn",
    "value": "Ghumārwīn"
  },
  {
    "label": "Hamīrpur",
    "value": "Hamīrpur"
  },
  {
    "label": "Jawāla Mukhi",
    "value": "Jawāla Mukhi"
  },
  {
    "label": "Jogindarnagar",
    "value": "Jogindarnagar"
  },
  {
    "label": "Jubbal",
    "value": "Jubbal"
  },
  {
    "label": "Jutogh",
    "value": "Jutogh"
  },
  {
    "label": "Kālka",
    "value": "Kālka"
  },
  {
    "label": "Kāngar",
    "value": "Kāngar"
  },
  {
    "label": "Kāngra",
    "value": "Kāngra"
  },
  {
    "label": "Kasauli",
    "value": "Kasauli"
  },
  {
    "label": "Kinnaur",
    "value": "Kinnaur"
  },
  {
    "label": "Kotkhai",
    "value": "Kotkhai"
  },
  {
    "label": "Kotla",
    "value": "Kotla"
  },
  {
    "label": "Kulu",
    "value": "Kulu"
  },
  {
    "label": "Kyelang",
    "value": "Kyelang"
  },
  {
    "label": "Lāhul and Spiti",
    "value": "Lāhul and Spiti"
  },
  {
    "label": "Manāli",
    "value": "Manāli"
  },
  {
    "label": "Nādaun",
    "value": "Nādaun"
  },
  {
    "label": "Nagrota",
    "value": "Nagrota"
  },
  {
    "label": "Nāhan",
    "value": "Nāhan"
  },
  {
    "label": "Nālāgarh",
    "value": "Nālāgarh"
  },
  {
    "label": "Pālampur",
    "value": "Pālampur"
  },
  {
    "label": "Pāndoh",
    "value": "Pāndoh"
  },
  {
    "label": "Pāonta Sāhib",
    "value": "Pāonta Sāhib"
  },
  {
    "label": "Parwanoo",
    "value": "Parwanoo"
  },
  {
    "label": "Rājgarh",
    "value": "Rājgarh"
  },
  {
    "label": "Rāmpur",
    "value": "Rāmpur"
  },
  {
    "label": "Rohru",
    "value": "Rohru"
  },
  {
    "label": "Sabāthu",
    "value": "Sabāthu"
  },
  {
    "label": "Santokhgarh",
    "value": "Santokhgarh"
  },
  {
    "label": "Sarāhan",
    "value": "Sarāhan"
  },
  {
    "label": "Sarka Ghāt",
    "value": "Sarka Ghāt"
  },
  {
    "label": "Seoni",
    "value": "Seoni"
  },
  {
    "label": "Sirmaur",
    "value": "Sirmaur"
  },
  {
    "label": "Sundarnagar",
    "value": "Sundarnagar"
  },
  {
    "label": "Theog",
    "value": "Theog"
  },
  {
    "label": "Tīra Sujānpur",
    "value": "Tīra Sujānpur"
  },
  {
    "label": "Yol",
    "value": "Yol"
  },
  {
    "label": "Akhnūr",
    "value": "Akhnūr"
  },
  {
    "label": "Anantnāg",
    "value": "Anantnāg"
  },
  {
    "label": "Awantipur",
    "value": "Awantipur"
  },
  {
    "label": "Badgam",
    "value": "Badgam"
  },
  {
    "label": "Bandipore",
    "value": "Bandipore"
  },
  {
    "label": "Bandipura",
    "value": "Bandipura"
  },
  {
    "label": "Banihāl",
    "value": "Banihāl"
  },
  {
    "label": "Bāramūla",
    "value": "Bāramūla"
  },
  {
    "label": "Batoti",
    "value": "Batoti"
  },
  {
    "label": "Bhadarwāh",
    "value": "Bhadarwāh"
  },
  {
    "label": "Bijbehara",
    "value": "Bijbehara"
  },
  {
    "label": "Bishnāh",
    "value": "Bishnāh"
  },
  {
    "label": "Doda",
    "value": "Doda"
  },
  {
    "label": "Gāndarbal",
    "value": "Gāndarbal"
  },
  {
    "label": "Ganderbal",
    "value": "Ganderbal"
  },
  {
    "label": "Gho Brāhmanān de",
    "value": "Gho Brāhmanān de"
  },
  {
    "label": "Hājan",
    "value": "Hājan"
  },
  {
    "label": "Hirānagar",
    "value": "Hirānagar"
  },
  {
    "label": "Jauriān",
    "value": "Jauriān"
  },
  {
    "label": "Khaur",
    "value": "Khaur"
  },
  {
    "label": "Kishtwar",
    "value": "Kishtwar"
  },
  {
    "label": "Kishtwār",
    "value": "Kishtwār"
  },
  {
    "label": "Kūd",
    "value": "Kūd"
  },
  {
    "label": "Kulgam",
    "value": "Kulgam"
  },
  {
    "label": "Kupwara",
    "value": "Kupwara"
  },
  {
    "label": "Kupwāra",
    "value": "Kupwāra"
  },
  {
    "label": "Ladākh",
    "value": "Ladākh"
  },
  {
    "label": "Māgām",
    "value": "Māgām"
  },
  {
    "label": "Nawānshahr",
    "value": "Nawānshahr"
  },
  {
    "label": "Norīa",
    "value": "Norīa"
  },
  {
    "label": "Padam",
    "value": "Padam"
  },
  {
    "label": "Pahlgām",
    "value": "Pahlgām"
  },
  {
    "label": "Parol",
    "value": "Parol"
  },
  {
    "label": "Pattan",
    "value": "Pattan"
  },
  {
    "label": "Pulwama",
    "value": "Pulwama"
  },
  {
    "label": "Punch",
    "value": "Punch"
  },
  {
    "label": "Pūnch",
    "value": "Pūnch"
  },
  {
    "label": "Qāzigund",
    "value": "Qāzigund"
  },
  {
    "label": "Rajaori",
    "value": "Rajaori"
  },
  {
    "label": "Rājauri",
    "value": "Rājauri"
  },
  {
    "label": "Rāmban",
    "value": "Rāmban"
  },
  {
    "label": "Ramban",
    "value": "Ramban"
  },
  {
    "label": "Rāmgarh",
    "value": "Rāmgarh"
  },
  {
    "label": "Riāsi",
    "value": "Riāsi"
  },
  {
    "label": "Samba",
    "value": "Samba"
  },
  {
    "label": "Sāmba",
    "value": "Sāmba"
  },
  {
    "label": "Shupiyan",
    "value": "Shupiyan"
  },
  {
    "label": "Shupīyan",
    "value": "Shupīyan"
  },
  {
    "label": "Sopur",
    "value": "Sopur"
  },
  {
    "label": "Soyībug",
    "value": "Soyībug"
  },
  {
    "label": "Srīnagar",
    "value": "Srīnagar"
  },
  {
    "label": "Sumbal",
    "value": "Sumbal"
  },
  {
    "label": "Thang",
    "value": "Thang"
  },
  {
    "label": "Thanna Mandi",
    "value": "Thanna Mandi"
  },
  {
    "label": "Trāl",
    "value": "Trāl"
  },
  {
    "label": "Tsrār Sharīf",
    "value": "Tsrār Sharīf"
  },
  {
    "label": "Udhampur",
    "value": "Udhampur"
  },
  {
    "label": "Uri",
    "value": "Uri"
  },
  {
    "label": "Bagra",
    "value": "Bagra"
  },
  {
    "label": "Barkā Kānā",
    "value": "Barkā Kānā"
  },
  {
    "label": "Barki Saria",
    "value": "Barki Saria"
  },
  {
    "label": "Barwādih",
    "value": "Barwādih"
  },
  {
    "label": "Bhojudih",
    "value": "Bhojudih"
  },
  {
    "label": "Bokāro",
    "value": "Bokāro"
  },
  {
    "label": "Būndu",
    "value": "Būndu"
  },
  {
    "label": "Chāībāsa",
    "value": "Chāībāsa"
  },
  {
    "label": "Chakradharpur",
    "value": "Chakradharpur"
  },
  {
    "label": "Chākuliā",
    "value": "Chākuliā"
  },
  {
    "label": "Chāndil",
    "value": "Chāndil"
  },
  {
    "label": "Chās",
    "value": "Chās"
  },
  {
    "label": "Chatrā",
    "value": "Chatrā"
  },
  {
    "label": "Chiria",
    "value": "Chiria"
  },
  {
    "label": "Daltonganj",
    "value": "Daltonganj"
  },
  {
    "label": "Deogarh",
    "value": "Deogarh"
  },
  {
    "label": "Dhanbād",
    "value": "Dhanbād"
  },
  {
    "label": "Dhānbād",
    "value": "Dhānbād"
  },
  {
    "label": "Dhanwār",
    "value": "Dhanwār"
  },
  {
    "label": "Dugda",
    "value": "Dugda"
  },
  {
    "label": "Garhwa",
    "value": "Garhwa"
  },
  {
    "label": "Ghātsīla",
    "value": "Ghātsīla"
  },
  {
    "label": "Girīdīh",
    "value": "Girīdīh"
  },
  {
    "label": "Gīrīdīh",
    "value": "Gīrīdīh"
  },
  {
    "label": "Gobindpur",
    "value": "Gobindpur"
  },
  {
    "label": "Godda",
    "value": "Godda"
  },
  {
    "label": "Gomoh",
    "value": "Gomoh"
  },
  {
    "label": "Gopināthpur",
    "value": "Gopināthpur"
  },
  {
    "label": "Gua",
    "value": "Gua"
  },
  {
    "label": "Gumia",
    "value": "Gumia"
  },
  {
    "label": "Gumla",
    "value": "Gumla"
  },
  {
    "label": "Gumlā",
    "value": "Gumlā"
  },
  {
    "label": "Hazārībāg",
    "value": "Hazārībāg"
  },
  {
    "label": "Hazāribāgh",
    "value": "Hazāribāgh"
  },
  {
    "label": "Hesla",
    "value": "Hesla"
  },
  {
    "label": "Husainābād",
    "value": "Husainābād"
  },
  {
    "label": "Jagannāthpur",
    "value": "Jagannāthpur"
  },
  {
    "label": "Jāmadoba",
    "value": "Jāmadoba"
  },
  {
    "label": "Jamtara",
    "value": "Jamtara"
  },
  {
    "label": "Jāmtāra",
    "value": "Jāmtāra"
  },
  {
    "label": "Jasidih",
    "value": "Jasidih"
  },
  {
    "label": "Jharia",
    "value": "Jharia"
  },
  {
    "label": "Jugsālai",
    "value": "Jugsālai"
  },
  {
    "label": "Jumri Tilaiyā",
    "value": "Jumri Tilaiyā"
  },
  {
    "label": "Kālikāpur",
    "value": "Kālikāpur"
  },
  {
    "label": "Kāndra",
    "value": "Kāndra"
  },
  {
    "label": "Kānke",
    "value": "Kānke"
  },
  {
    "label": "Kātrās",
    "value": "Kātrās"
  },
  {
    "label": "Kenduadīh",
    "value": "Kenduadīh"
  },
  {
    "label": "Kharsāwān",
    "value": "Kharsāwān"
  },
  {
    "label": "Khunti",
    "value": "Khunti"
  },
  {
    "label": "Kodarmā",
    "value": "Kodarmā"
  },
  {
    "label": "Kuju",
    "value": "Kuju"
  },
  {
    "label": "Latehar",
    "value": "Latehar"
  },
  {
    "label": "Lātehār",
    "value": "Lātehār"
  },
  {
    "label": "Lohardaga",
    "value": "Lohardaga"
  },
  {
    "label": "Lohārdagā",
    "value": "Lohārdagā"
  },
  {
    "label": "Madhupur",
    "value": "Madhupur"
  },
  {
    "label": "Malkera",
    "value": "Malkera"
  },
  {
    "label": "Manoharpur",
    "value": "Manoharpur"
  },
  {
    "label": "Mugma",
    "value": "Mugma"
  },
  {
    "label": "Mushābani",
    "value": "Mushābani"
  },
  {
    "label": "Neturhāt",
    "value": "Neturhāt"
  },
  {
    "label": "Nirsā",
    "value": "Nirsā"
  },
  {
    "label": "Noāmundi",
    "value": "Noāmundi"
  },
  {
    "label": "Pakur",
    "value": "Pakur"
  },
  {
    "label": "Palāmu",
    "value": "Palāmu"
  },
  {
    "label": "Pashchim Singhbhūm",
    "value": "Pashchim Singhbhūm"
  },
  {
    "label": "patamda",
    "value": "patamda"
  },
  {
    "label": "Pāthardih",
    "value": "Pāthardih"
  },
  {
    "label": "Purba Singhbhum",
    "value": "Purba Singhbhum"
  },
  {
    "label": "Rānchī",
    "value": "Rānchī"
  },
  {
    "label": "Rāy",
    "value": "Rāy"
  },
  {
    "label": "Sahibganj",
    "value": "Sahibganj"
  },
  {
    "label": "Sāhibganj",
    "value": "Sāhibganj"
  },
  {
    "label": "Saraikela",
    "value": "Saraikela"
  },
  {
    "label": "Sāruberā",
    "value": "Sāruberā"
  },
  {
    "label": "Sijua",
    "value": "Sijua"
  },
  {
    "label": "Simdega",
    "value": "Simdega"
  },
  {
    "label": "Sini",
    "value": "Sini"
  },
  {
    "label": "Topchānchi",
    "value": "Topchānchi"
  },
  {
    "label": "Afzalpur",
    "value": "Afzalpur"
  },
  {
    "label": "Ajjampur",
    "value": "Ajjampur"
  },
  {
    "label": "Aland",
    "value": "Aland"
  },
  {
    "label": "Alnāvar",
    "value": "Alnāvar"
  },
  {
    "label": "Alūr",
    "value": "Alūr"
  },
  {
    "label": "Anekal",
    "value": "Anekal"
  },
  {
    "label": "Ankola",
    "value": "Ankola"
  },
  {
    "label": "Annigeri",
    "value": "Annigeri"
  },
  {
    "label": "Arkalgūd",
    "value": "Arkalgūd"
  },
  {
    "label": "Arsikere",
    "value": "Arsikere"
  },
  {
    "label": "Athni",
    "value": "Athni"
  },
  {
    "label": "Aurād",
    "value": "Aurād"
  },
  {
    "label": "Bādāmi",
    "value": "Bādāmi"
  },
  {
    "label": "Bāgepalli",
    "value": "Bāgepalli"
  },
  {
    "label": "Bail-Hongal",
    "value": "Bail-Hongal"
  },
  {
    "label": "Ballari",
    "value": "Ballari"
  },
  {
    "label": "Bānāvar",
    "value": "Bānāvar"
  },
  {
    "label": "Bangalore Rural",
    "value": "Bangalore Rural"
  },
  {
    "label": "Bangalore Urban",
    "value": "Bangalore Urban"
  },
  {
    "label": "Bangarapet",
    "value": "Bangarapet"
  },
  {
    "label": "Bannūr",
    "value": "Bannūr"
  },
  {
    "label": "Bantvāl",
    "value": "Bantvāl"
  },
  {
    "label": "Basavakalyān",
    "value": "Basavakalyān"
  },
  {
    "label": "Basavana Bāgevādi",
    "value": "Basavana Bāgevādi"
  },
  {
    "label": "Bellūru",
    "value": "Bellūru"
  },
  {
    "label": "Beltangadi",
    "value": "Beltangadi"
  },
  {
    "label": "Belūr",
    "value": "Belūr"
  },
  {
    "label": "Bengaluru",
    "value": "Bengaluru"
  },
  {
    "label": "Bhadrāvati",
    "value": "Bhadrāvati"
  },
  {
    "label": "Bhālki",
    "value": "Bhālki"
  },
  {
    "label": "Bīdar",
    "value": "Bīdar"
  },
  {
    "label": "Bijāpur",
    "value": "Bijāpur"
  },
  {
    "label": "Bilgi",
    "value": "Bilgi"
  },
  {
    "label": "Birūr",
    "value": "Birūr"
  },
  {
    "label": "Byādgi",
    "value": "Byādgi"
  },
  {
    "label": "Byndoor",
    "value": "Byndoor"
  },
  {
    "label": "Canacona",
    "value": "Canacona"
  },
  {
    "label": "Challakere",
    "value": "Challakere"
  },
  {
    "label": "Chamrajnagar",
    "value": "Chamrajnagar"
  },
  {
    "label": "Channagiri",
    "value": "Channagiri"
  },
  {
    "label": "Channarāyapatna",
    "value": "Channarāyapatna"
  },
  {
    "label": "Chik Ballāpur",
    "value": "Chik Ballāpur"
  },
  {
    "label": "Chikkaballapur",
    "value": "Chikkaballapur"
  },
  {
    "label": "Chikmagalur",
    "value": "Chikmagalur"
  },
  {
    "label": "Chikmagalūr",
    "value": "Chikmagalūr"
  },
  {
    "label": "Chiknāyakanhalli",
    "value": "Chiknāyakanhalli"
  },
  {
    "label": "Chincholi",
    "value": "Chincholi"
  },
  {
    "label": "Chintāmani",
    "value": "Chintāmani"
  },
  {
    "label": "Chītāpur",
    "value": "Chītāpur"
  },
  {
    "label": "Closepet",
    "value": "Closepet"
  },
  {
    "label": "Coondapoor",
    "value": "Coondapoor"
  },
  {
    "label": "Dakshina Kannada",
    "value": "Dakshina Kannada"
  },
  {
    "label": "Dandeli",
    "value": "Dandeli"
  },
  {
    "label": "Davanagere",
    "value": "Davanagere"
  },
  {
    "label": "Devanhalli",
    "value": "Devanhalli"
  },
  {
    "label": "Dod Ballāpur",
    "value": "Dod Ballāpur"
  },
  {
    "label": "French Rocks",
    "value": "French Rocks"
  },
  {
    "label": "Gadag-Betageri",
    "value": "Gadag-Betageri"
  },
  {
    "label": "Gajendragarh",
    "value": "Gajendragarh"
  },
  {
    "label": "Gangāwati",
    "value": "Gangāwati"
  },
  {
    "label": "Gangolli",
    "value": "Gangolli"
  },
  {
    "label": "Gokak",
    "value": "Gokak"
  },
  {
    "label": "Gokarna",
    "value": "Gokarna"
  },
  {
    "label": "Goribidnūr",
    "value": "Goribidnūr"
  },
  {
    "label": "Gorūr",
    "value": "Gorūr"
  },
  {
    "label": "Gubbi",
    "value": "Gubbi"
  },
  {
    "label": "Gudibanda",
    "value": "Gudibanda"
  },
  {
    "label": "Guledagudda",
    "value": "Guledagudda"
  },
  {
    "label": "Gundlupēt",
    "value": "Gundlupēt"
  },
  {
    "label": "Gurmatkāl",
    "value": "Gurmatkāl"
  },
  {
    "label": "Hadagalli",
    "value": "Hadagalli"
  },
  {
    "label": "Haliyal",
    "value": "Haliyal"
  },
  {
    "label": "Hampi",
    "value": "Hampi"
  },
  {
    "label": "Hāngal",
    "value": "Hāngal"
  },
  {
    "label": "Harihar",
    "value": "Harihar"
  },
  {
    "label": "Harpanahalli",
    "value": "Harpanahalli"
  },
  {
    "label": "Hāveri",
    "value": "Hāveri"
  },
  {
    "label": "Heggadadevankote",
    "value": "Heggadadevankote"
  },
  {
    "label": "Hirekerūr",
    "value": "Hirekerūr"
  },
  {
    "label": "Hiriyūr",
    "value": "Hiriyūr"
  },
  {
    "label": "Holalkere",
    "value": "Holalkere"
  },
  {
    "label": "Hole Narsipur",
    "value": "Hole Narsipur"
  },
  {
    "label": "Homnābād",
    "value": "Homnābād"
  },
  {
    "label": "Honāvar",
    "value": "Honāvar"
  },
  {
    "label": "Honnāli",
    "value": "Honnāli"
  },
  {
    "label": "Hosakote",
    "value": "Hosakote"
  },
  {
    "label": "Hosanagara",
    "value": "Hosanagara"
  },
  {
    "label": "Hosangadi",
    "value": "Hosangadi"
  },
  {
    "label": "Hosdurga",
    "value": "Hosdurga"
  },
  {
    "label": "Hoskote",
    "value": "Hoskote"
  },
  {
    "label": "Hospet",
    "value": "Hospet"
  },
  {
    "label": "Hukeri",
    "value": "Hukeri"
  },
  {
    "label": "Hungund",
    "value": "Hungund"
  },
  {
    "label": "Hunsūr",
    "value": "Hunsūr"
  },
  {
    "label": "Ilkal",
    "value": "Ilkal"
  },
  {
    "label": "Indi",
    "value": "Indi"
  },
  {
    "label": "Jagalūr",
    "value": "Jagalūr"
  },
  {
    "label": "Jamkhandi",
    "value": "Jamkhandi"
  },
  {
    "label": "Jevargi",
    "value": "Jevargi"
  },
  {
    "label": "Kadūr",
    "value": "Kadūr"
  },
  {
    "label": "Kalghatgi",
    "value": "Kalghatgi"
  },
  {
    "label": "Kampli",
    "value": "Kampli"
  },
  {
    "label": "Kānkānhalli",
    "value": "Kānkānhalli"
  },
  {
    "label": "Kārkala",
    "value": "Kārkala"
  },
  {
    "label": "Kavalūr",
    "value": "Kavalūr"
  },
  {
    "label": "Kerūr",
    "value": "Kerūr"
  },
  {
    "label": "Khānāpur",
    "value": "Khānāpur"
  },
  {
    "label": "Kodagu",
    "value": "Kodagu"
  },
  {
    "label": "Kodigenahalli",
    "value": "Kodigenahalli"
  },
  {
    "label": "Kodlipet",
    "value": "Kodlipet"
  },
  {
    "label": "Kolār",
    "value": "Kolār"
  },
  {
    "label": "Kollegāl",
    "value": "Kollegāl"
  },
  {
    "label": "Konanūr",
    "value": "Konanūr"
  },
  {
    "label": "Konnūr",
    "value": "Konnūr"
  },
  {
    "label": "Koppa",
    "value": "Koppa"
  },
  {
    "label": "Koratagere",
    "value": "Koratagere"
  },
  {
    "label": "Kottūru",
    "value": "Kottūru"
  },
  {
    "label": "Krishnarājpet",
    "value": "Krishnarājpet"
  },
  {
    "label": "Kudachi",
    "value": "Kudachi"
  },
  {
    "label": "Kūdligi",
    "value": "Kūdligi"
  },
  {
    "label": "Kumsi",
    "value": "Kumsi"
  },
  {
    "label": "Kumta",
    "value": "Kumta"
  },
  {
    "label": "Kundgol",
    "value": "Kundgol"
  },
  {
    "label": "Kunigal",
    "value": "Kunigal"
  },
  {
    "label": "Kurgunta",
    "value": "Kurgunta"
  },
  {
    "label": "Kushālnagar",
    "value": "Kushālnagar"
  },
  {
    "label": "Kushtagi",
    "value": "Kushtagi"
  },
  {
    "label": "Lakshmeshwar",
    "value": "Lakshmeshwar"
  },
  {
    "label": "Lingsugūr",
    "value": "Lingsugūr"
  },
  {
    "label": "Londa",
    "value": "Londa"
  },
  {
    "label": "Maddagiri",
    "value": "Maddagiri"
  },
  {
    "label": "Maddūr",
    "value": "Maddūr"
  },
  {
    "label": "Māgadi",
    "value": "Māgadi"
  },
  {
    "label": "Mahālingpur",
    "value": "Mahālingpur"
  },
  {
    "label": "Malavalli",
    "value": "Malavalli"
  },
  {
    "label": "Malpe",
    "value": "Malpe"
  },
  {
    "label": "Mālūr",
    "value": "Mālūr"
  },
  {
    "label": "Manipal",
    "value": "Manipal"
  },
  {
    "label": "Mānvi",
    "value": "Mānvi"
  },
  {
    "label": "Māyakonda",
    "value": "Māyakonda"
  },
  {
    "label": "Melukote",
    "value": "Melukote"
  },
  {
    "label": "Mūdbidri",
    "value": "Mūdbidri"
  },
  {
    "label": "Muddebihāl",
    "value": "Muddebihāl"
  },
  {
    "label": "Mudgal",
    "value": "Mudgal"
  },
  {
    "label": "Mudgere",
    "value": "Mudgere"
  },
  {
    "label": "Mudhol",
    "value": "Mudhol"
  },
  {
    "label": "Mulbāgal",
    "value": "Mulbāgal"
  },
  {
    "label": "Mulgund",
    "value": "Mulgund"
  },
  {
    "label": "Mūlki",
    "value": "Mūlki"
  },
  {
    "label": "Mundargi",
    "value": "Mundargi"
  },
  {
    "label": "Munirābād",
    "value": "Munirābād"
  },
  {
    "label": "Murudeshwara",
    "value": "Murudeshwara"
  },
  {
    "label": "Nāgamangala",
    "value": "Nāgamangala"
  },
  {
    "label": "Nanjangūd",
    "value": "Nanjangūd"
  },
  {
    "label": "Narasimharājapura",
    "value": "Narasimharājapura"
  },
  {
    "label": "Naregal",
    "value": "Naregal"
  },
  {
    "label": "Nargund",
    "value": "Nargund"
  },
  {
    "label": "Navalgund",
    "value": "Navalgund"
  },
  {
    "label": "Nelamangala",
    "value": "Nelamangala"
  },
  {
    "label": "Nyāmti",
    "value": "Nyāmti"
  },
  {
    "label": "Pāngāla",
    "value": "Pāngāla"
  },
  {
    "label": "Pāvugada",
    "value": "Pāvugada"
  },
  {
    "label": "Piriyāpatna",
    "value": "Piriyāpatna"
  },
  {
    "label": "Ponnampet",
    "value": "Ponnampet"
  },
  {
    "label": "Rabkavi",
    "value": "Rabkavi"
  },
  {
    "label": "Rāichūr",
    "value": "Rāichūr"
  },
  {
    "label": "Ramanagara",
    "value": "Ramanagara"
  },
  {
    "label": "Rānībennur",
    "value": "Rānībennur"
  },
  {
    "label": "Rāybāg",
    "value": "Rāybāg"
  },
  {
    "label": "Robertsonpet",
    "value": "Robertsonpet"
  },
  {
    "label": "Ron",
    "value": "Ron"
  },
  {
    "label": "Sadalgi",
    "value": "Sadalgi"
  },
  {
    "label": "Sāgar",
    "value": "Sāgar"
  },
  {
    "label": "Sakleshpur",
    "value": "Sakleshpur"
  },
  {
    "label": "Sandūr",
    "value": "Sandūr"
  },
  {
    "label": "Sanivārsante",
    "value": "Sanivārsante"
  },
  {
    "label": "Sankeshwar",
    "value": "Sankeshwar"
  },
  {
    "label": "Sargūr",
    "value": "Sargūr"
  },
  {
    "label": "Saundatti",
    "value": "Saundatti"
  },
  {
    "label": "Savanūr",
    "value": "Savanūr"
  },
  {
    "label": "Seram",
    "value": "Seram"
  },
  {
    "label": "Shiggaon",
    "value": "Shiggaon"
  },
  {
    "label": "Shikārpur",
    "value": "Shikārpur"
  },
  {
    "label": "Shirhatti",
    "value": "Shirhatti"
  },
  {
    "label": "Shorāpur",
    "value": "Shorāpur"
  },
  {
    "label": "Shrīrangapattana",
    "value": "Shrīrangapattana"
  },
  {
    "label": "Siddāpur",
    "value": "Siddāpur"
  },
  {
    "label": "Sidlaghatta",
    "value": "Sidlaghatta"
  },
  {
    "label": "Sindgi",
    "value": "Sindgi"
  },
  {
    "label": "Sindhnūr",
    "value": "Sindhnūr"
  },
  {
    "label": "Sīra",
    "value": "Sīra"
  },
  {
    "label": "Someshwar",
    "value": "Someshwar"
  },
  {
    "label": "Somvārpet",
    "value": "Somvārpet"
  },
  {
    "label": "Sorab",
    "value": "Sorab"
  },
  {
    "label": "Srāvana Belgola",
    "value": "Srāvana Belgola"
  },
  {
    "label": "Sringeri",
    "value": "Sringeri"
  },
  {
    "label": "Srīnivāspur",
    "value": "Srīnivāspur"
  },
  {
    "label": "Sulya",
    "value": "Sulya"
  },
  {
    "label": "Suntikoppa",
    "value": "Suntikoppa"
  },
  {
    "label": "Tālīkota",
    "value": "Tālīkota"
  },
  {
    "label": "Tarikere",
    "value": "Tarikere"
  },
  {
    "label": "Tekkalakote",
    "value": "Tekkalakote"
  },
  {
    "label": "Terdāl",
    "value": "Terdāl"
  },
  {
    "label": "Tiptūr",
    "value": "Tiptūr"
  },
  {
    "label": "Tīrthahalli",
    "value": "Tīrthahalli"
  },
  {
    "label": "Tirumakūdal Narsipur",
    "value": "Tirumakūdal Narsipur"
  },
  {
    "label": "Tumkur",
    "value": "Tumkur"
  },
  {
    "label": "Tumkūr",
    "value": "Tumkūr"
  },
  {
    "label": "Turuvekere",
    "value": "Turuvekere"
  },
  {
    "label": "Ullal",
    "value": "Ullal"
  },
  {
    "label": "Uttar Kannada",
    "value": "Uttar Kannada"
  },
  {
    "label": "Vadigenhalli",
    "value": "Vadigenhalli"
  },
  {
    "label": "Vīrarājendrapet",
    "value": "Vīrarājendrapet"
  },
  {
    "label": "Wādi",
    "value": "Wādi"
  },
  {
    "label": "Yādgīr",
    "value": "Yādgīr"
  },
  {
    "label": "Yelahanka",
    "value": "Yelahanka"
  },
  {
    "label": "Yelandūr",
    "value": "Yelandūr"
  },
  {
    "label": "Yelbarga",
    "value": "Yelbarga"
  },
  {
    "label": "Yellāpur",
    "value": "Yellāpur"
  },
  {
    "label": "Adūr",
    "value": "Adūr"
  },
  {
    "label": "Alwaye",
    "value": "Alwaye"
  },
  {
    "label": "Angamāli",
    "value": "Angamāli"
  },
  {
    "label": "Aroor",
    "value": "Aroor"
  },
  {
    "label": "Arukutti",
    "value": "Arukutti"
  },
  {
    "label": "Avanoor",
    "value": "Avanoor"
  },
  {
    "label": "Azhikkal",
    "value": "Azhikkal"
  },
  {
    "label": "Badagara",
    "value": "Badagara"
  },
  {
    "label": "Beypore",
    "value": "Beypore"
  },
  {
    "label": "Changanācheri",
    "value": "Changanācheri"
  },
  {
    "label": "Chēlakara",
    "value": "Chēlakara"
  },
  {
    "label": "Chengannūr",
    "value": "Chengannūr"
  },
  {
    "label": "Cherpulassery",
    "value": "Cherpulassery"
  },
  {
    "label": "Cherthala",
    "value": "Cherthala"
  },
  {
    "label": "Chetwayi",
    "value": "Chetwayi"
  },
  {
    "label": "Chittūr",
    "value": "Chittūr"
  },
  {
    "label": "Cochin",
    "value": "Cochin"
  },
  {
    "label": "Dharmadam",
    "value": "Dharmadam"
  },
  {
    "label": "Edakkulam",
    "value": "Edakkulam"
  },
  {
    "label": "Elūr",
    "value": "Elūr"
  },
  {
    "label": "Erāttupetta",
    "value": "Erāttupetta"
  },
  {
    "label": "Ernākulam",
    "value": "Ernākulam"
  },
  {
    "label": "Ferokh",
    "value": "Ferokh"
  },
  {
    "label": "Guruvāyūr",
    "value": "Guruvāyūr"
  },
  {
    "label": "Iringal",
    "value": "Iringal"
  },
  {
    "label": "Irinjālakuda",
    "value": "Irinjālakuda"
  },
  {
    "label": "Kadakkavoor",
    "value": "Kadakkavoor"
  },
  {
    "label": "Kalamassery",
    "value": "Kalamassery"
  },
  {
    "label": "Kalavoor",
    "value": "Kalavoor"
  },
  {
    "label": "Kalpatta",
    "value": "Kalpatta"
  },
  {
    "label": "Kānnangād",
    "value": "Kānnangād"
  },
  {
    "label": "Kannavam",
    "value": "Kannavam"
  },
  {
    "label": "Kāsaragod",
    "value": "Kāsaragod"
  },
  {
    "label": "Kāsaragod District",
    "value": "Kāsaragod District"
  },
  {
    "label": "Kattanam",
    "value": "Kattanam"
  },
  {
    "label": "Kāyankulam",
    "value": "Kāyankulam"
  },
  {
    "label": "Kizhake Chālakudi",
    "value": "Kizhake Chālakudi"
  },
  {
    "label": "Kodungallūr",
    "value": "Kodungallūr"
  },
  {
    "label": "Kotamangalam",
    "value": "Kotamangalam"
  },
  {
    "label": "Kovalam",
    "value": "Kovalam"
  },
  {
    "label": "Kumbalam",
    "value": "Kumbalam"
  },
  {
    "label": "Kunnamangalam",
    "value": "Kunnamangalam"
  },
  {
    "label": "Kunnamkulam",
    "value": "Kunnamkulam"
  },
  {
    "label": "Kunnumma",
    "value": "Kunnumma"
  },
  {
    "label": "Kutiatodu",
    "value": "Kutiatodu"
  },
  {
    "label": "Kuttampuzha",
    "value": "Kuttampuzha"
  },
  {
    "label": "Lālam",
    "value": "Lālam"
  },
  {
    "label": "Mahē",
    "value": "Mahē"
  },
  {
    "label": "Manjēshvar",
    "value": "Manjēshvar"
  },
  {
    "label": "Mannārakkāt",
    "value": "Mannārakkāt"
  },
  {
    "label": "Marayur",
    "value": "Marayur"
  },
  {
    "label": "Māvelikara",
    "value": "Māvelikara"
  },
  {
    "label": "Mavoor",
    "value": "Mavoor"
  },
  {
    "label": "Muluppilagadu",
    "value": "Muluppilagadu"
  },
  {
    "label": "Munnar",
    "value": "Munnar"
  },
  {
    "label": "Mūvattupula",
    "value": "Mūvattupula"
  },
  {
    "label": "Muvattupuzha",
    "value": "Muvattupuzha"
  },
  {
    "label": "Nādāpuram",
    "value": "Nādāpuram"
  },
  {
    "label": "Naduvannūr",
    "value": "Naduvannūr"
  },
  {
    "label": "Nedumangād",
    "value": "Nedumangād"
  },
  {
    "label": "Neyyāttinkara",
    "value": "Neyyāttinkara"
  },
  {
    "label": "Nīlēshwar",
    "value": "Nīlēshwar"
  },
  {
    "label": "Ottappālam",
    "value": "Ottappālam"
  },
  {
    "label": "Palackattumala",
    "value": "Palackattumala"
  },
  {
    "label": "Palakkad district",
    "value": "Palakkad district"
  },
  {
    "label": "Pālghāt",
    "value": "Pālghāt"
  },
  {
    "label": "Panamaram",
    "value": "Panamaram"
  },
  {
    "label": "Pāppinisshēri",
    "value": "Pāppinisshēri"
  },
  {
    "label": "Paravūr Tekkumbhāgam",
    "value": "Paravūr Tekkumbhāgam"
  },
  {
    "label": "Pariyāpuram",
    "value": "Pariyāpuram"
  },
  {
    "label": "Pattanamtitta",
    "value": "Pattanamtitta"
  },
  {
    "label": "Payyannūr",
    "value": "Payyannūr"
  },
  {
    "label": "Perumbavoor",
    "value": "Perumbavoor"
  },
  {
    "label": "Perumpāvūr",
    "value": "Perumpāvūr"
  },
  {
    "label": "Perya",
    "value": "Perya"
  },
  {
    "label": "Piravam",
    "value": "Piravam"
  },
  {
    "label": "Ponmana",
    "value": "Ponmana"
  },
  {
    "label": "Ponnāni",
    "value": "Ponnāni"
  },
  {
    "label": "Punalūr",
    "value": "Punalūr"
  },
  {
    "label": "Rāmamangalam",
    "value": "Rāmamangalam"
  },
  {
    "label": "Shertallai",
    "value": "Shertallai"
  },
  {
    "label": "Shōranūr",
    "value": "Shōranūr"
  },
  {
    "label": "Talipparamba",
    "value": "Talipparamba"
  },
  {
    "label": "Tellicherry",
    "value": "Tellicherry"
  },
  {
    "label": "Thanniyam",
    "value": "Thanniyam"
  },
  {
    "label": "Thrissur District",
    "value": "Thrissur District"
  },
  {
    "label": "Varkala",
    "value": "Varkala"
  },
  {
    "label": "Vayalār",
    "value": "Vayalār"
  },
  {
    "label": "Vettūr",
    "value": "Vettūr"
  },
  {
    "label": "Wayanad",
    "value": "Wayanad"
  },
  {
    "label": "Leh",
    "value": "Leh"
  },
  {
    "label": "Lakshadweep",
    "value": "Lakshadweep"
  },
  {
    "label": "Agar",
    "value": "Agar"
  },
  {
    "label": "Ajaigarh",
    "value": "Ajaigarh"
  },
  {
    "label": "Akodia",
    "value": "Akodia"
  },
  {
    "label": "Alampur",
    "value": "Alampur"
  },
  {
    "label": "Alirajpur",
    "value": "Alirajpur"
  },
  {
    "label": "Alot",
    "value": "Alot"
  },
  {
    "label": "Amānganj",
    "value": "Amānganj"
  },
  {
    "label": "Amarkantak",
    "value": "Amarkantak"
  },
  {
    "label": "Amarpātan",
    "value": "Amarpātan"
  },
  {
    "label": "Amarwāra",
    "value": "Amarwāra"
  },
  {
    "label": "Ambāh",
    "value": "Ambāh"
  },
  {
    "label": "Amla",
    "value": "Amla"
  },
  {
    "label": "Anjad",
    "value": "Anjad"
  },
  {
    "label": "Antri",
    "value": "Antri"
  },
  {
    "label": "Anuppur",
    "value": "Anuppur"
  },
  {
    "label": "Anūppur",
    "value": "Anūppur"
  },
  {
    "label": "Āron",
    "value": "Āron"
  },
  {
    "label": "Ashoknagar",
    "value": "Ashoknagar"
  },
  {
    "label": "Ashta",
    "value": "Ashta"
  },
  {
    "label": "Bābai",
    "value": "Bābai"
  },
  {
    "label": "Badarwās",
    "value": "Badarwās"
  },
  {
    "label": "Badnāwar",
    "value": "Badnāwar"
  },
  {
    "label": "Bāg",
    "value": "Bāg"
  },
  {
    "label": "Bāgli",
    "value": "Bāgli"
  },
  {
    "label": "Baihar",
    "value": "Baihar"
  },
  {
    "label": "Bakshwāho",
    "value": "Bakshwāho"
  },
  {
    "label": "Bālāghāt",
    "value": "Bālāghāt"
  },
  {
    "label": "Baldeogarh",
    "value": "Baldeogarh"
  },
  {
    "label": "Bamna",
    "value": "Bamna"
  },
  {
    "label": "Bāmor Kalān",
    "value": "Bāmor Kalān"
  },
  {
    "label": "Bamora",
    "value": "Bamora"
  },
  {
    "label": "Barela",
    "value": "Barela"
  },
  {
    "label": "Barghāt",
    "value": "Barghāt"
  },
  {
    "label": "Bargi",
    "value": "Bargi"
  },
  {
    "label": "Barhi",
    "value": "Barhi"
  },
  {
    "label": "Barwani",
    "value": "Barwani"
  },
  {
    "label": "Barwāni",
    "value": "Barwāni"
  },
  {
    "label": "Bāsoda",
    "value": "Bāsoda"
  },
  {
    "label": "Begamganj",
    "value": "Begamganj"
  },
  {
    "label": "Beohāri",
    "value": "Beohāri"
  },
  {
    "label": "Betma",
    "value": "Betma"
  },
  {
    "label": "Betūl",
    "value": "Betūl"
  },
  {
    "label": "Betūl Bazār",
    "value": "Betūl Bazār"
  },
  {
    "label": "Bhābhra",
    "value": "Bhābhra"
  },
  {
    "label": "Bhainsdehi",
    "value": "Bhainsdehi"
  },
  {
    "label": "Bhānder",
    "value": "Bhānder"
  },
  {
    "label": "Bhānpura",
    "value": "Bhānpura"
  },
  {
    "label": "Bhawāniganj",
    "value": "Bhawāniganj"
  },
  {
    "label": "Bhikangaon",
    "value": "Bhikangaon"
  },
  {
    "label": "Bhind",
    "value": "Bhind"
  },
  {
    "label": "Bhitarwār",
    "value": "Bhitarwār"
  },
  {
    "label": "Bhopāl",
    "value": "Bhopāl"
  },
  {
    "label": "Biaora",
    "value": "Biaora"
  },
  {
    "label": "Bijāwar",
    "value": "Bijāwar"
  },
  {
    "label": "Bijrauni",
    "value": "Bijrauni"
  },
  {
    "label": "Bodri",
    "value": "Bodri"
  },
  {
    "label": "Burhanpur",
    "value": "Burhanpur"
  },
  {
    "label": "Burhānpur",
    "value": "Burhānpur"
  },
  {
    "label": "Burhar",
    "value": "Burhar"
  },
  {
    "label": "Chanderi",
    "value": "Chanderi"
  },
  {
    "label": "Chandia",
    "value": "Chandia"
  },
  {
    "label": "Chandla",
    "value": "Chandla"
  },
  {
    "label": "Chhatarpur",
    "value": "Chhatarpur"
  },
  {
    "label": "Chhindwāra",
    "value": "Chhindwāra"
  },
  {
    "label": "Chichli",
    "value": "Chichli"
  },
  {
    "label": "Chorhat",
    "value": "Chorhat"
  },
  {
    "label": "Daboh",
    "value": "Daboh"
  },
  {
    "label": "Dabra",
    "value": "Dabra"
  },
  {
    "label": "Datia",
    "value": "Datia"
  },
  {
    "label": "Deori Khās",
    "value": "Deori Khās"
  },
  {
    "label": "Depālpur",
    "value": "Depālpur"
  },
  {
    "label": "Dhāmnod",
    "value": "Dhāmnod"
  },
  {
    "label": "Dhāna",
    "value": "Dhāna"
  },
  {
    "label": "Dhār",
    "value": "Dhār"
  },
  {
    "label": "Dharampuri",
    "value": "Dharampuri"
  },
  {
    "label": "Etāwa",
    "value": "Etāwa"
  },
  {
    "label": "Gādarwāra",
    "value": "Gādarwāra"
  },
  {
    "label": "Garha Brahman",
    "value": "Garha Brahman"
  },
  {
    "label": "Garhākota",
    "value": "Garhākota"
  },
  {
    "label": "Gautampura",
    "value": "Gautampura"
  },
  {
    "label": "Ghansor",
    "value": "Ghansor"
  },
  {
    "label": "Gogāpur",
    "value": "Gogāpur"
  },
  {
    "label": "Gohadi",
    "value": "Gohadi"
  },
  {
    "label": "Govindgarh",
    "value": "Govindgarh"
  },
  {
    "label": "Gurh",
    "value": "Gurh"
  },
  {
    "label": "Harda",
    "value": "Harda"
  },
  {
    "label": "Harda Khās",
    "value": "Harda Khās"
  },
  {
    "label": "Harpālpur",
    "value": "Harpālpur"
  },
  {
    "label": "Harrai",
    "value": "Harrai"
  },
  {
    "label": "Harsūd",
    "value": "Harsūd"
  },
  {
    "label": "Hātod",
    "value": "Hātod"
  },
  {
    "label": "Hatta",
    "value": "Hatta"
  },
  {
    "label": "Hindoria",
    "value": "Hindoria"
  },
  {
    "label": "Hoshangābād",
    "value": "Hoshangābād"
  },
  {
    "label": "Iāwar",
    "value": "Iāwar"
  },
  {
    "label": "Ichhāwar",
    "value": "Ichhāwar"
  },
  {
    "label": "Iklehra",
    "value": "Iklehra"
  },
  {
    "label": "Isāgarh",
    "value": "Isāgarh"
  },
  {
    "label": "Itārsi",
    "value": "Itārsi"
  },
  {
    "label": "Jaisinghnagar",
    "value": "Jaisinghnagar"
  },
  {
    "label": "Jaithāri",
    "value": "Jaithāri"
  },
  {
    "label": "Jāmai",
    "value": "Jāmai"
  },
  {
    "label": "Jaorā",
    "value": "Jaorā"
  },
  {
    "label": "Jatāra",
    "value": "Jatāra"
  },
  {
    "label": "Jāwad",
    "value": "Jāwad"
  },
  {
    "label": "Jhābua",
    "value": "Jhābua"
  },
  {
    "label": "Jīran",
    "value": "Jīran"
  },
  {
    "label": "Jobat",
    "value": "Jobat"
  },
  {
    "label": "Jora",
    "value": "Jora"
  },
  {
    "label": "Kailāras",
    "value": "Kailāras"
  },
  {
    "label": "Kaimori",
    "value": "Kaimori"
  },
  {
    "label": "Kannod",
    "value": "Kannod"
  },
  {
    "label": "Kareli",
    "value": "Kareli"
  },
  {
    "label": "Karera",
    "value": "Karera"
  },
  {
    "label": "Karrāpur",
    "value": "Karrāpur"
  },
  {
    "label": "Kasrāwad",
    "value": "Kasrāwad"
  },
  {
    "label": "Katangi",
    "value": "Katangi"
  },
  {
    "label": "Khāchrod",
    "value": "Khāchrod"
  },
  {
    "label": "Khailār",
    "value": "Khailār"
  },
  {
    "label": "Khamaria",
    "value": "Khamaria"
  },
  {
    "label": "Khandwa",
    "value": "Khandwa"
  },
  {
    "label": "Khandwa district",
    "value": "Khandwa district"
  },
  {
    "label": "Khargāpur",
    "value": "Khargāpur"
  },
  {
    "label": "Khātegaon",
    "value": "Khātegaon"
  },
  {
    "label": "Khilchipur",
    "value": "Khilchipur"
  },
  {
    "label": "Khirkiyān",
    "value": "Khirkiyān"
  },
  {
    "label": "Khujner",
    "value": "Khujner"
  },
  {
    "label": "Khurai",
    "value": "Khurai"
  },
  {
    "label": "Kolāras",
    "value": "Kolāras"
  },
  {
    "label": "Korwai",
    "value": "Korwai"
  },
  {
    "label": "Kotār",
    "value": "Kotār"
  },
  {
    "label": "Kothi",
    "value": "Kothi"
  },
  {
    "label": "Kotma",
    "value": "Kotma"
  },
  {
    "label": "Kotwa",
    "value": "Kotwa"
  },
  {
    "label": "Kukshi",
    "value": "Kukshi"
  },
  {
    "label": "Kumbhrāj",
    "value": "Kumbhrāj"
  },
  {
    "label": "Lahār",
    "value": "Lahār"
  },
  {
    "label": "Lakhnādon",
    "value": "Lakhnādon"
  },
  {
    "label": "Leteri",
    "value": "Leteri"
  },
  {
    "label": "Lodhīkheda",
    "value": "Lodhīkheda"
  },
  {
    "label": "Māchalpur",
    "value": "Māchalpur"
  },
  {
    "label": "Madhogarh",
    "value": "Madhogarh"
  },
  {
    "label": "Maheshwar",
    "value": "Maheshwar"
  },
  {
    "label": "Mahgawān",
    "value": "Mahgawān"
  },
  {
    "label": "Maihar",
    "value": "Maihar"
  },
  {
    "label": "Majholi",
    "value": "Majholi"
  },
  {
    "label": "Maksi",
    "value": "Maksi"
  },
  {
    "label": "Malhārgarh",
    "value": "Malhārgarh"
  },
  {
    "label": "Manāsa",
    "value": "Manāsa"
  },
  {
    "label": "Manāwar",
    "value": "Manāwar"
  },
  {
    "label": "Mandideep",
    "value": "Mandideep"
  },
  {
    "label": "Mandlā",
    "value": "Mandlā"
  },
  {
    "label": "Māndleshwar",
    "value": "Māndleshwar"
  },
  {
    "label": "Mangawān",
    "value": "Mangawān"
  },
  {
    "label": "Mānpur",
    "value": "Mānpur"
  },
  {
    "label": "Mau",
    "value": "Mau"
  },
  {
    "label": "Mauganj",
    "value": "Mauganj"
  },
  {
    "label": "Mihona",
    "value": "Mihona"
  },
  {
    "label": "Mohgaon",
    "value": "Mohgaon"
  },
  {
    "label": "Morār",
    "value": "Morār"
  },
  {
    "label": "Multai",
    "value": "Multai"
  },
  {
    "label": "Mundi",
    "value": "Mundi"
  },
  {
    "label": "Mungaoli",
    "value": "Mungaoli"
  },
  {
    "label": "Murwāra",
    "value": "Murwāra"
  },
  {
    "label": "Nagda",
    "value": "Nagda"
  },
  {
    "label": "Nāgod",
    "value": "Nāgod"
  },
  {
    "label": "Naīgarhi",
    "value": "Naīgarhi"
  },
  {
    "label": "Nainpur",
    "value": "Nainpur"
  },
  {
    "label": "Namli",
    "value": "Namli"
  },
  {
    "label": "Naraini",
    "value": "Naraini"
  },
  {
    "label": "Nārāyangarh",
    "value": "Nārāyangarh"
  },
  {
    "label": "Narsimhapur",
    "value": "Narsimhapur"
  },
  {
    "label": "Narsinghgarh",
    "value": "Narsinghgarh"
  },
  {
    "label": "Narwar",
    "value": "Narwar"
  },
  {
    "label": "Nasrullāhganj",
    "value": "Nasrullāhganj"
  },
  {
    "label": "Neemuch",
    "value": "Neemuch"
  },
  {
    "label": "Nepānagar",
    "value": "Nepānagar"
  },
  {
    "label": "Orchha",
    "value": "Orchha"
  },
  {
    "label": "Palera",
    "value": "Palera"
  },
  {
    "label": "Pāli",
    "value": "Pāli"
  },
  {
    "label": "Panāgar",
    "value": "Panāgar"
  },
  {
    "label": "Panara",
    "value": "Panara"
  },
  {
    "label": "Pandhāna",
    "value": "Pandhāna"
  },
  {
    "label": "Pāndhurnā",
    "value": "Pāndhurnā"
  },
  {
    "label": "Panna",
    "value": "Panna"
  },
  {
    "label": "Pānsemāl",
    "value": "Pānsemāl"
  },
  {
    "label": "Parāsia",
    "value": "Parāsia"
  },
  {
    "label": "Patharia",
    "value": "Patharia"
  },
  {
    "label": "Pawai",
    "value": "Pawai"
  },
  {
    "label": "Petlāwad",
    "value": "Petlāwad"
  },
  {
    "label": "Piploda",
    "value": "Piploda"
  },
  {
    "label": "Pithampur",
    "value": "Pithampur"
  },
  {
    "label": "Porsa",
    "value": "Porsa"
  },
  {
    "label": "Punāsa",
    "value": "Punāsa"
  },
  {
    "label": "Rāghogarh",
    "value": "Rāghogarh"
  },
  {
    "label": "Rāhatgarh",
    "value": "Rāhatgarh"
  },
  {
    "label": "Raisen",
    "value": "Raisen"
  },
  {
    "label": "Rājnagar",
    "value": "Rājnagar"
  },
  {
    "label": "Rāmpura",
    "value": "Rāmpura"
  },
  {
    "label": "Rānāpur",
    "value": "Rānāpur"
  },
  {
    "label": "Ratangarh",
    "value": "Ratangarh"
  },
  {
    "label": "Ratlām",
    "value": "Ratlām"
  },
  {
    "label": "Rehli",
    "value": "Rehli"
  },
  {
    "label": "Rehti",
    "value": "Rehti"
  },
  {
    "label": "Sabalgarh",
    "value": "Sabalgarh"
  },
  {
    "label": "Sailāna",
    "value": "Sailāna"
  },
  {
    "label": "Sanāwad",
    "value": "Sanāwad"
  },
  {
    "label": "Sānchi",
    "value": "Sānchi"
  },
  {
    "label": "Sānwer",
    "value": "Sānwer"
  },
  {
    "label": "Sārangpur",
    "value": "Sārangpur"
  },
  {
    "label": "Satwās",
    "value": "Satwās"
  },
  {
    "label": "Saugor",
    "value": "Saugor"
  },
  {
    "label": "Sausar",
    "value": "Sausar"
  },
  {
    "label": "Seondha",
    "value": "Seondha"
  },
  {
    "label": "Seonī",
    "value": "Seonī"
  },
  {
    "label": "Seoni Mālwa",
    "value": "Seoni Mālwa"
  },
  {
    "label": "Shāhgarh",
    "value": "Shāhgarh"
  },
  {
    "label": "Shāhpura",
    "value": "Shāhpura"
  },
  {
    "label": "Shājāpur",
    "value": "Shājāpur"
  },
  {
    "label": "Shāmgarh",
    "value": "Shāmgarh"
  },
  {
    "label": "Sheopur",
    "value": "Sheopur"
  },
  {
    "label": "Shivpuri",
    "value": "Shivpuri"
  },
  {
    "label": "Shivpurī",
    "value": "Shivpurī"
  },
  {
    "label": "Shujālpur",
    "value": "Shujālpur"
  },
  {
    "label": "Sihorā",
    "value": "Sihorā"
  },
  {
    "label": "Simaria",
    "value": "Simaria"
  },
  {
    "label": "Singoli",
    "value": "Singoli"
  },
  {
    "label": "Singrauli",
    "value": "Singrauli"
  },
  {
    "label": "Sironj",
    "value": "Sironj"
  },
  {
    "label": "Sītāmau",
    "value": "Sītāmau"
  },
  {
    "label": "Sohāgi",
    "value": "Sohāgi"
  },
  {
    "label": "Sohāgpur",
    "value": "Sohāgpur"
  },
  {
    "label": "Sultānpur",
    "value": "Sultānpur"
  },
  {
    "label": "Susner",
    "value": "Susner"
  },
  {
    "label": "Tāl",
    "value": "Tāl"
  },
  {
    "label": "Talen",
    "value": "Talen"
  },
  {
    "label": "Tarāna",
    "value": "Tarāna"
  },
  {
    "label": "Tekanpur",
    "value": "Tekanpur"
  },
  {
    "label": "Tendūkheda",
    "value": "Tendūkheda"
  },
  {
    "label": "Teonthar",
    "value": "Teonthar"
  },
  {
    "label": "Thandla",
    "value": "Thandla"
  },
  {
    "label": "Tīkamgarh",
    "value": "Tīkamgarh"
  },
  {
    "label": "Tirodi",
    "value": "Tirodi"
  },
  {
    "label": "Udaipura",
    "value": "Udaipura"
  },
  {
    "label": "Ukwā",
    "value": "Ukwā"
  },
  {
    "label": "Umaria",
    "value": "Umaria"
  },
  {
    "label": "Umaria District",
    "value": "Umaria District"
  },
  {
    "label": "Umri",
    "value": "Umri"
  },
  {
    "label": "Unhel",
    "value": "Unhel"
  },
  {
    "label": "Wārāseonī",
    "value": "Wārāseonī"
  },
  {
    "label": "Achalpur",
    "value": "Achalpur"
  },
  {
    "label": "Ahiri",
    "value": "Ahiri"
  },
  {
    "label": "Ahmadnagar",
    "value": "Ahmadnagar"
  },
  {
    "label": "Ahmadpur",
    "value": "Ahmadpur"
  },
  {
    "label": "Airoli",
    "value": "Airoli"
  },
  {
    "label": "Ajra",
    "value": "Ajra"
  },
  {
    "label": "Akalkot",
    "value": "Akalkot"
  },
  {
    "label": "Akot",
    "value": "Akot"
  },
  {
    "label": "Alandi",
    "value": "Alandi"
  },
  {
    "label": "Alībāg",
    "value": "Alībāg"
  },
  {
    "label": "Allāpalli",
    "value": "Allāpalli"
  },
  {
    "label": "Amalner",
    "value": "Amalner"
  },
  {
    "label": "Amarnāth",
    "value": "Amarnāth"
  },
  {
    "label": "Ambājogāi",
    "value": "Ambājogāi"
  },
  {
    "label": "Amrāvati",
    "value": "Amrāvati"
  },
  {
    "label": "Amravati Division",
    "value": "Amravati Division"
  },
  {
    "label": "Anjangaon",
    "value": "Anjangaon"
  },
  {
    "label": "Anshing",
    "value": "Anshing"
  },
  {
    "label": "Ārangaon",
    "value": "Ārangaon"
  },
  {
    "label": "Artist Village",
    "value": "Artist Village"
  },
  {
    "label": "Ārvi",
    "value": "Ārvi"
  },
  {
    "label": "Ashti",
    "value": "Ashti"
  },
  {
    "label": "Ausa",
    "value": "Ausa"
  },
  {
    "label": "Bālāpur",
    "value": "Bālāpur"
  },
  {
    "label": "Ballālpur",
    "value": "Ballālpur"
  },
  {
    "label": "Bārāmati",
    "value": "Bārāmati"
  },
  {
    "label": "Bārsi",
    "value": "Bārsi"
  },
  {
    "label": "Basmat",
    "value": "Basmat"
  },
  {
    "label": "Beed",
    "value": "Beed"
  },
  {
    "label": "Bhandara",
    "value": "Bhandara"
  },
  {
    "label": "Bhandāra",
    "value": "Bhandāra"
  },
  {
    "label": "Bhayandar",
    "value": "Bhayandar"
  },
  {
    "label": "Bhigvan",
    "value": "Bhigvan"
  },
  {
    "label": "Bhor",
    "value": "Bhor"
  },
  {
    "label": "Bhudgaon",
    "value": "Bhudgaon"
  },
  {
    "label": "Bhūm",
    "value": "Bhūm"
  },
  {
    "label": "Bhusāval",
    "value": "Bhusāval"
  },
  {
    "label": "Bid",
    "value": "Bid"
  },
  {
    "label": "Biloli",
    "value": "Biloli"
  },
  {
    "label": "Borivli",
    "value": "Borivli"
  },
  {
    "label": "Buldana",
    "value": "Buldana"
  },
  {
    "label": "Buldāna",
    "value": "Buldāna"
  },
  {
    "label": "Chākan",
    "value": "Chākan"
  },
  {
    "label": "Chālisgaon",
    "value": "Chālisgaon"
  },
  {
    "label": "Chānda",
    "value": "Chānda"
  },
  {
    "label": "Chāndor",
    "value": "Chāndor"
  },
  {
    "label": "Chāndur",
    "value": "Chāndur"
  },
  {
    "label": "Chāndūr",
    "value": "Chāndūr"
  },
  {
    "label": "Chāndūr Bāzār",
    "value": "Chāndūr Bāzār"
  },
  {
    "label": "Chicholi",
    "value": "Chicholi"
  },
  {
    "label": "Chinchani",
    "value": "Chinchani"
  },
  {
    "label": "Chiplūn",
    "value": "Chiplūn"
  },
  {
    "label": "Dābhol",
    "value": "Dābhol"
  },
  {
    "label": "Dāhānu",
    "value": "Dāhānu"
  },
  {
    "label": "Dārwha",
    "value": "Dārwha"
  },
  {
    "label": "Daryāpur",
    "value": "Daryāpur"
  },
  {
    "label": "Dattāpur",
    "value": "Dattāpur"
  },
  {
    "label": "Daulatābād",
    "value": "Daulatābād"
  },
  {
    "label": "Daund",
    "value": "Daund"
  },
  {
    "label": "Dehu",
    "value": "Dehu"
  },
  {
    "label": "Deolāli",
    "value": "Deolāli"
  },
  {
    "label": "Deūlgaon Rāja",
    "value": "Deūlgaon Rāja"
  },
  {
    "label": "Dharangaon",
    "value": "Dharangaon"
  },
  {
    "label": "Dharmābād",
    "value": "Dharmābād"
  },
  {
    "label": "Dhārūr",
    "value": "Dhārūr"
  },
  {
    "label": "Dhūlia",
    "value": "Dhūlia"
  },
  {
    "label": "Dīglūr",
    "value": "Dīglūr"
  },
  {
    "label": "Digras",
    "value": "Digras"
  },
  {
    "label": "Dombivli",
    "value": "Dombivli"
  },
  {
    "label": "Dondaicha",
    "value": "Dondaicha"
  },
  {
    "label": "Dudhani",
    "value": "Dudhani"
  },
  {
    "label": "Durgāpur",
    "value": "Durgāpur"
  },
  {
    "label": "Erandol",
    "value": "Erandol"
  },
  {
    "label": "Faizpur",
    "value": "Faizpur"
  },
  {
    "label": "Gadchiroli",
    "value": "Gadchiroli"
  },
  {
    "label": "Gadhinglaj",
    "value": "Gadhinglaj"
  },
  {
    "label": "Gangākher",
    "value": "Gangākher"
  },
  {
    "label": "Gangāpur",
    "value": "Gangāpur"
  },
  {
    "label": "Gevrai",
    "value": "Gevrai"
  },
  {
    "label": "Ghātanji",
    "value": "Ghātanji"
  },
  {
    "label": "Ghoti Budrukh",
    "value": "Ghoti Budrukh"
  },
  {
    "label": "Ghugus",
    "value": "Ghugus"
  },
  {
    "label": "Gondiā",
    "value": "Gondiā"
  },
  {
    "label": "Gondiya",
    "value": "Gondiya"
  },
  {
    "label": "Guhāgar",
    "value": "Guhāgar"
  },
  {
    "label": "Hadgāon",
    "value": "Hadgāon"
  },
  {
    "label": "Harnai",
    "value": "Harnai"
  },
  {
    "label": "Hinganghāt",
    "value": "Hinganghāt"
  },
  {
    "label": "Hīrāpur Hamesha",
    "value": "Hīrāpur Hamesha"
  },
  {
    "label": "Indāpur",
    "value": "Indāpur"
  },
  {
    "label": "Jaisingpur",
    "value": "Jaisingpur"
  },
  {
    "label": "Jalgaon",
    "value": "Jalgaon"
  },
  {
    "label": "Jālna",
    "value": "Jālna"
  },
  {
    "label": "Jawhār",
    "value": "Jawhār"
  },
  {
    "label": "Jejūri",
    "value": "Jejūri"
  },
  {
    "label": "Jintūr",
    "value": "Jintūr"
  },
  {
    "label": "Junnar",
    "value": "Junnar"
  },
  {
    "label": "Kāgal",
    "value": "Kāgal"
  },
  {
    "label": "Kalamb",
    "value": "Kalamb"
  },
  {
    "label": "Kalamnūri",
    "value": "Kalamnūri"
  },
  {
    "label": "Kalas",
    "value": "Kalas"
  },
  {
    "label": "Kalmeshwar",
    "value": "Kalmeshwar"
  },
  {
    "label": "Kālundri",
    "value": "Kālundri"
  },
  {
    "label": "Kalyān",
    "value": "Kalyān"
  },
  {
    "label": "Kāmthi",
    "value": "Kāmthi"
  },
  {
    "label": "Kāndri",
    "value": "Kāndri"
  },
  {
    "label": "Kannad",
    "value": "Kannad"
  },
  {
    "label": "Karād",
    "value": "Karād"
  },
  {
    "label": "Kāranja",
    "value": "Kāranja"
  },
  {
    "label": "Karmāla",
    "value": "Karmāla"
  },
  {
    "label": "Kāti",
    "value": "Kāti"
  },
  {
    "label": "Kātol",
    "value": "Kātol"
  },
  {
    "label": "Khadki",
    "value": "Khadki"
  },
  {
    "label": "Khāmgaon",
    "value": "Khāmgaon"
  },
  {
    "label": "Khāpa",
    "value": "Khāpa"
  },
  {
    "label": "Kharakvasla",
    "value": "Kharakvasla"
  },
  {
    "label": "Khed",
    "value": "Khed"
  },
  {
    "label": "Khetia",
    "value": "Khetia"
  },
  {
    "label": "Khuldābād",
    "value": "Khuldābād"
  },
  {
    "label": "Kinwat",
    "value": "Kinwat"
  },
  {
    "label": "Kodoli",
    "value": "Kodoli"
  },
  {
    "label": "Kolhāpur",
    "value": "Kolhāpur"
  },
  {
    "label": "Kondalwādi",
    "value": "Kondalwādi"
  },
  {
    "label": "Kopargaon",
    "value": "Kopargaon"
  },
  {
    "label": "Korādi",
    "value": "Korādi"
  },
  {
    "label": "Koregaon",
    "value": "Koregaon"
  },
  {
    "label": "Koynanagar",
    "value": "Koynanagar"
  },
  {
    "label": "Kudāl",
    "value": "Kudāl"
  },
  {
    "label": "Kurandvād",
    "value": "Kurandvād"
  },
  {
    "label": "Kurduvādi",
    "value": "Kurduvādi"
  },
  {
    "label": "Lānja",
    "value": "Lānja"
  },
  {
    "label": "Lāsalgaon",
    "value": "Lāsalgaon"
  },
  {
    "label": "Lohogaon",
    "value": "Lohogaon"
  },
  {
    "label": "Lonār",
    "value": "Lonār"
  },
  {
    "label": "Lonavla",
    "value": "Lonavla"
  },
  {
    "label": "Mahābaleshwar",
    "value": "Mahābaleshwar"
  },
  {
    "label": "Mahād",
    "value": "Mahād"
  },
  {
    "label": "Maindargi",
    "value": "Maindargi"
  },
  {
    "label": "Mājalgaon",
    "value": "Mājalgaon"
  },
  {
    "label": "Mākhjan",
    "value": "Mākhjan"
  },
  {
    "label": "Mālegaon",
    "value": "Mālegaon"
  },
  {
    "label": "Malkapur",
    "value": "Malkapur"
  },
  {
    "label": "Malkāpur",
    "value": "Malkāpur"
  },
  {
    "label": "Mālvan",
    "value": "Mālvan"
  },
  {
    "label": "Manchar",
    "value": "Manchar"
  },
  {
    "label": "Mangrūl Pīr",
    "value": "Mangrūl Pīr"
  },
  {
    "label": "Manmād",
    "value": "Manmād"
  },
  {
    "label": "Mansar",
    "value": "Mansar"
  },
  {
    "label": "Mānwat",
    "value": "Mānwat"
  },
  {
    "label": "Mātherān",
    "value": "Mātherān"
  },
  {
    "label": "Mehekar",
    "value": "Mehekar"
  },
  {
    "label": "Mhasla",
    "value": "Mhasla"
  },
  {
    "label": "Mhāsvād",
    "value": "Mhāsvād"
  },
  {
    "label": "Mohpa",
    "value": "Mohpa"
  },
  {
    "label": "Moram",
    "value": "Moram"
  },
  {
    "label": "Morsi",
    "value": "Morsi"
  },
  {
    "label": "Mowād",
    "value": "Mowād"
  },
  {
    "label": "Mudkhed",
    "value": "Mudkhed"
  },
  {
    "label": "Mukher",
    "value": "Mukher"
  },
  {
    "label": "Mūl",
    "value": "Mūl"
  },
  {
    "label": "Mumbai Suburban",
    "value": "Mumbai Suburban"
  },
  {
    "label": "Murbād",
    "value": "Murbād"
  },
  {
    "label": "Murgūd",
    "value": "Murgūd"
  },
  {
    "label": "Murtajāpur",
    "value": "Murtajāpur"
  },
  {
    "label": "Murud",
    "value": "Murud"
  },
  {
    "label": "Nāgothana",
    "value": "Nāgothana"
  },
  {
    "label": "Nagpur Division",
    "value": "Nagpur Division"
  },
  {
    "label": "Naldurg",
    "value": "Naldurg"
  },
  {
    "label": "Nāndgaon",
    "value": "Nāndgaon"
  },
  {
    "label": "Nāndūra Buzurg",
    "value": "Nāndūra Buzurg"
  },
  {
    "label": "Nashik Division",
    "value": "Nashik Division"
  },
  {
    "label": "Navi Mumbai",
    "value": "Navi Mumbai"
  },
  {
    "label": "Nilanga",
    "value": "Nilanga"
  },
  {
    "label": "Nipāni",
    "value": "Nipāni"
  },
  {
    "label": "Ozar",
    "value": "Ozar"
  },
  {
    "label": "Pāchora",
    "value": "Pāchora"
  },
  {
    "label": "Paithan",
    "value": "Paithan"
  },
  {
    "label": "Pālghar",
    "value": "Pālghar"
  },
  {
    "label": "Pānchgani",
    "value": "Pānchgani"
  },
  {
    "label": "Panhāla",
    "value": "Panhāla"
  },
  {
    "label": "Parli Vaijnāth",
    "value": "Parli Vaijnāth"
  },
  {
    "label": "Parola",
    "value": "Parola"
  },
  {
    "label": "Partūr",
    "value": "Partūr"
  },
  {
    "label": "Pāthardi",
    "value": "Pāthardi"
  },
  {
    "label": "Pāthri",
    "value": "Pāthri"
  },
  {
    "label": "Pātūr",
    "value": "Pātūr"
  },
  {
    "label": "Pawni",
    "value": "Pawni"
  },
  {
    "label": "Pen",
    "value": "Pen"
  },
  {
    "label": "Phaltan",
    "value": "Phaltan"
  },
  {
    "label": "Pīpri",
    "value": "Pīpri"
  },
  {
    "label": "Pulgaon",
    "value": "Pulgaon"
  },
  {
    "label": "Pune Division",
    "value": "Pune Division"
  },
  {
    "label": "Pūrna",
    "value": "Pūrna"
  },
  {
    "label": "Pusad",
    "value": "Pusad"
  },
  {
    "label": "Rahimatpur",
    "value": "Rahimatpur"
  },
  {
    "label": "Rāhuri",
    "value": "Rāhuri"
  },
  {
    "label": "Rājāpur",
    "value": "Rājāpur"
  },
  {
    "label": "Rājgurunagar",
    "value": "Rājgurunagar"
  },
  {
    "label": "Rājur",
    "value": "Rājur"
  },
  {
    "label": "Rājūra",
    "value": "Rājūra"
  },
  {
    "label": "Rāmtek",
    "value": "Rāmtek"
  },
  {
    "label": "Rāver",
    "value": "Rāver"
  },
  {
    "label": "Revadanda",
    "value": "Revadanda"
  },
  {
    "label": "Risod",
    "value": "Risod"
  },
  {
    "label": "Sāngli",
    "value": "Sāngli"
  },
  {
    "label": "Sāngola",
    "value": "Sāngola"
  },
  {
    "label": "Saoner",
    "value": "Saoner"
  },
  {
    "label": "Sāsvad",
    "value": "Sāsvad"
  },
  {
    "label": "Satānā",
    "value": "Satānā"
  },
  {
    "label": "Satara Division",
    "value": "Satara Division"
  },
  {
    "label": "Sāvantvādi",
    "value": "Sāvantvādi"
  },
  {
    "label": "Sāvda",
    "value": "Sāvda"
  },
  {
    "label": "Selu",
    "value": "Selu"
  },
  {
    "label": "Shāhāda",
    "value": "Shāhāda"
  },
  {
    "label": "Shāhāpur",
    "value": "Shāhāpur"
  },
  {
    "label": "Shegaon",
    "value": "Shegaon"
  },
  {
    "label": "Shiraguppi",
    "value": "Shiraguppi"
  },
  {
    "label": "Shirdi",
    "value": "Shirdi"
  },
  {
    "label": "Shirpur",
    "value": "Shirpur"
  },
  {
    "label": "Shirwal",
    "value": "Shirwal"
  },
  {
    "label": "Shivaji Nagar",
    "value": "Shivaji Nagar"
  },
  {
    "label": "Shrīgonda",
    "value": "Shrīgonda"
  },
  {
    "label": "Sillod",
    "value": "Sillod"
  },
  {
    "label": "Sindhudurg",
    "value": "Sindhudurg"
  },
  {
    "label": "Sindi",
    "value": "Sindi"
  },
  {
    "label": "Sirūr",
    "value": "Sirūr"
  },
  {
    "label": "Solāpur",
    "value": "Solāpur"
  },
  {
    "label": "Sonegaon",
    "value": "Sonegaon"
  },
  {
    "label": "Soygaon",
    "value": "Soygaon"
  },
  {
    "label": "Srīvardhan",
    "value": "Srīvardhan"
  },
  {
    "label": "Surgāna",
    "value": "Surgāna"
  },
  {
    "label": "Talegaon Dābhāde",
    "value": "Talegaon Dābhāde"
  },
  {
    "label": "Taloda",
    "value": "Taloda"
  },
  {
    "label": "Tārāpur",
    "value": "Tārāpur"
  },
  {
    "label": "Tāsgaon",
    "value": "Tāsgaon"
  },
  {
    "label": "Telhāra",
    "value": "Telhāra"
  },
  {
    "label": "Thāne",
    "value": "Thāne"
  },
  {
    "label": "Tuljāpur",
    "value": "Tuljāpur"
  },
  {
    "label": "Tumsar",
    "value": "Tumsar"
  },
  {
    "label": "Udgīr",
    "value": "Udgīr"
  },
  {
    "label": "Umarga",
    "value": "Umarga"
  },
  {
    "label": "Umarkhed",
    "value": "Umarkhed"
  },
  {
    "label": "Uran",
    "value": "Uran"
  },
  {
    "label": "Vāda",
    "value": "Vāda"
  },
  {
    "label": "Vaijāpur",
    "value": "Vaijāpur"
  },
  {
    "label": "Varangaon",
    "value": "Varangaon"
  },
  {
    "label": "Vasind",
    "value": "Vasind"
  },
  {
    "label": "Vengurla",
    "value": "Vengurla"
  },
  {
    "label": "Virār",
    "value": "Virār"
  },
  {
    "label": "Vite",
    "value": "Vite"
  },
  {
    "label": "Wadgaon",
    "value": "Wadgaon"
  },
  {
    "label": "Wani",
    "value": "Wani"
  },
  {
    "label": "Warora",
    "value": "Warora"
  },
  {
    "label": "Warud",
    "value": "Warud"
  },
  {
    "label": "Wāshīm",
    "value": "Wāshīm"
  },
  {
    "label": "Washim",
    "value": "Washim"
  },
  {
    "label": "Yāval",
    "value": "Yāval"
  },
  {
    "label": "Yavatmāl",
    "value": "Yavatmāl"
  },
  {
    "label": "Churāchāndpur",
    "value": "Churāchāndpur"
  },
  {
    "label": "Kakching",
    "value": "Kakching"
  },
  {
    "label": "Mayāng Imphāl",
    "value": "Mayāng Imphāl"
  },
  {
    "label": "Moirāng",
    "value": "Moirāng"
  },
  {
    "label": "Phek",
    "value": "Phek"
  },
  {
    "label": "Senapati",
    "value": "Senapati"
  },
  {
    "label": "Tamenglong",
    "value": "Tamenglong"
  },
  {
    "label": "Thoubal",
    "value": "Thoubal"
  },
  {
    "label": "Thoubāl",
    "value": "Thoubāl"
  },
  {
    "label": "Ukhrul",
    "value": "Ukhrul"
  },
  {
    "label": "Wāngjing",
    "value": "Wāngjing"
  },
  {
    "label": "Yairipok",
    "value": "Yairipok"
  },
  {
    "label": "Cherrapunji",
    "value": "Cherrapunji"
  },
  {
    "label": "East Gāro Hills",
    "value": "East Gāro Hills"
  },
  {
    "label": "East Jaintia Hills",
    "value": "East Jaintia Hills"
  },
  {
    "label": "East Khāsi Hills",
    "value": "East Khāsi Hills"
  },
  {
    "label": "Mairang",
    "value": "Mairang"
  },
  {
    "label": "Mankāchar",
    "value": "Mankāchar"
  },
  {
    "label": "North Garo Hills",
    "value": "North Garo Hills"
  },
  {
    "label": "Ri-Bhoi",
    "value": "Ri-Bhoi"
  },
  {
    "label": "South Garo Hills",
    "value": "South Garo Hills"
  },
  {
    "label": "South West Garo Hills",
    "value": "South West Garo Hills"
  },
  {
    "label": "South West Khasi Hills",
    "value": "South West Khasi Hills"
  },
  {
    "label": "Tura",
    "value": "Tura"
  },
  {
    "label": "West Garo Hills",
    "value": "West Garo Hills"
  },
  {
    "label": "West Jaintia Hills",
    "value": "West Jaintia Hills"
  },
  {
    "label": "West Khasi Hills",
    "value": "West Khasi Hills"
  },
  {
    "label": "Champhai",
    "value": "Champhai"
  },
  {
    "label": "Darlawn",
    "value": "Darlawn"
  },
  {
    "label": "Khawhai",
    "value": "Khawhai"
  },
  {
    "label": "Kolasib",
    "value": "Kolasib"
  },
  {
    "label": "Kolasib district",
    "value": "Kolasib district"
  },
  {
    "label": "Lawngtlai",
    "value": "Lawngtlai"
  },
  {
    "label": "Lunglei",
    "value": "Lunglei"
  },
  {
    "label": "Mamit",
    "value": "Mamit"
  },
  {
    "label": "North Vanlaiphai",
    "value": "North Vanlaiphai"
  },
  {
    "label": "Saiha",
    "value": "Saiha"
  },
  {
    "label": "Sairang",
    "value": "Sairang"
  },
  {
    "label": "Sāitlaw",
    "value": "Sāitlaw"
  },
  {
    "label": "Serchhip",
    "value": "Serchhip"
  },
  {
    "label": "Serchhīp",
    "value": "Serchhīp"
  },
  {
    "label": "Thenzawl",
    "value": "Thenzawl"
  },
  {
    "label": "Dimāpur",
    "value": "Dimāpur"
  },
  {
    "label": "Kohīma",
    "value": "Kohīma"
  },
  {
    "label": "Mokokchūng",
    "value": "Mokokchūng"
  },
  {
    "label": "Mon",
    "value": "Mon"
  },
  {
    "label": "Peren",
    "value": "Peren"
  },
  {
    "label": "Tuensang",
    "value": "Tuensang"
  },
  {
    "label": "Tuensang District",
    "value": "Tuensang District"
  },
  {
    "label": "Wokha",
    "value": "Wokha"
  },
  {
    "label": "Zunheboto",
    "value": "Zunheboto"
  },
  {
    "label": "Angul District",
    "value": "Angul District"
  },
  {
    "label": "Āsika",
    "value": "Āsika"
  },
  {
    "label": "Āthagarh",
    "value": "Āthagarh"
  },
  {
    "label": "Bada Barabīl",
    "value": "Bada Barabīl"
  },
  {
    "label": "Balāngīr",
    "value": "Balāngīr"
  },
  {
    "label": "Bāleshwar",
    "value": "Bāleshwar"
  },
  {
    "label": "Balimila",
    "value": "Balimila"
  },
  {
    "label": "Bālugaon",
    "value": "Bālugaon"
  },
  {
    "label": "Bānapur",
    "value": "Bānapur"
  },
  {
    "label": "Bānki",
    "value": "Bānki"
  },
  {
    "label": "Bānposh",
    "value": "Bānposh"
  },
  {
    "label": "Baragarh",
    "value": "Baragarh"
  },
  {
    "label": "Barbil",
    "value": "Barbil"
  },
  {
    "label": "Barpāli",
    "value": "Barpāli"
  },
  {
    "label": "Bāsudebpur",
    "value": "Bāsudebpur"
  },
  {
    "label": "Baud",
    "value": "Baud"
  },
  {
    "label": "Baudh",
    "value": "Baudh"
  },
  {
    "label": "Belaguntha",
    "value": "Belaguntha"
  },
  {
    "label": "Bhadrak",
    "value": "Bhadrak"
  },
  {
    "label": "Bhadrakh",
    "value": "Bhadrakh"
  },
  {
    "label": "Bhanjanagar",
    "value": "Bhanjanagar"
  },
  {
    "label": "Bhawānipatna",
    "value": "Bhawānipatna"
  },
  {
    "label": "Bhuban",
    "value": "Bhuban"
  },
  {
    "label": "Bhubaneshwar",
    "value": "Bhubaneshwar"
  },
  {
    "label": "Binka",
    "value": "Binka"
  },
  {
    "label": "Birmitrapur",
    "value": "Birmitrapur"
  },
  {
    "label": "Bolānīkhodān",
    "value": "Bolānīkhodān"
  },
  {
    "label": "Brājarājnagar",
    "value": "Brājarājnagar"
  },
  {
    "label": "Buguda",
    "value": "Buguda"
  },
  {
    "label": "Burla",
    "value": "Burla"
  },
  {
    "label": "Chāmpua",
    "value": "Chāmpua"
  },
  {
    "label": "Chāndbāli",
    "value": "Chāndbāli"
  },
  {
    "label": "Chatrapur",
    "value": "Chatrapur"
  },
  {
    "label": "Chikitigarh",
    "value": "Chikitigarh"
  },
  {
    "label": "Chittarkonda",
    "value": "Chittarkonda"
  },
  {
    "label": "Daitari",
    "value": "Daitari"
  },
  {
    "label": "Dhenkānāl",
    "value": "Dhenkānāl"
  },
  {
    "label": "Digapahandi",
    "value": "Digapahandi"
  },
  {
    "label": "Gajapati",
    "value": "Gajapati"
  },
  {
    "label": "Ganjām",
    "value": "Ganjām"
  },
  {
    "label": "Gopālpur",
    "value": "Gopālpur"
  },
  {
    "label": "Gudāri",
    "value": "Gudāri"
  },
  {
    "label": "Gunupur",
    "value": "Gunupur"
  },
  {
    "label": "Hinjilikatu",
    "value": "Hinjilikatu"
  },
  {
    "label": "Hīrākud",
    "value": "Hīrākud"
  },
  {
    "label": "Jagatsinghapur",
    "value": "Jagatsinghapur"
  },
  {
    "label": "Jagatsinghpur",
    "value": "Jagatsinghpur"
  },
  {
    "label": "Jājpur",
    "value": "Jājpur"
  },
  {
    "label": "Jaleshwar",
    "value": "Jaleshwar"
  },
  {
    "label": "Jeypore",
    "value": "Jeypore"
  },
  {
    "label": "Jharsuguda District",
    "value": "Jharsuguda District"
  },
  {
    "label": "Kaintragarh",
    "value": "Kaintragarh"
  },
  {
    "label": "Kālāhandi",
    "value": "Kālāhandi"
  },
  {
    "label": "Kāmākhyānagar",
    "value": "Kāmākhyānagar"
  },
  {
    "label": "Kandhamal",
    "value": "Kandhamal"
  },
  {
    "label": "Kantābānji",
    "value": "Kantābānji"
  },
  {
    "label": "Kantilo",
    "value": "Kantilo"
  },
  {
    "label": "Kendrapara",
    "value": "Kendrapara"
  },
  {
    "label": "Kendrāparha",
    "value": "Kendrāparha"
  },
  {
    "label": "Kendujhar",
    "value": "Kendujhar"
  },
  {
    "label": "Kesinga",
    "value": "Kesinga"
  },
  {
    "label": "Khallikot",
    "value": "Khallikot"
  },
  {
    "label": "Kharhiāl",
    "value": "Kharhiāl"
  },
  {
    "label": "Khordha",
    "value": "Khordha"
  },
  {
    "label": "Kiri Buru",
    "value": "Kiri Buru"
  },
  {
    "label": "Kodala",
    "value": "Kodala"
  },
  {
    "label": "Konārka",
    "value": "Konārka"
  },
  {
    "label": "Korāput",
    "value": "Korāput"
  },
  {
    "label": "Kuchaiburi",
    "value": "Kuchaiburi"
  },
  {
    "label": "Kuchinda",
    "value": "Kuchinda"
  },
  {
    "label": "Malakanagiri",
    "value": "Malakanagiri"
  },
  {
    "label": "Malkangiri",
    "value": "Malkangiri"
  },
  {
    "label": "Mayūrbhanj",
    "value": "Mayūrbhanj"
  },
  {
    "label": "Nabarangpur",
    "value": "Nabarangpur"
  },
  {
    "label": "Nayāgarh",
    "value": "Nayāgarh"
  },
  {
    "label": "Nayagarh District",
    "value": "Nayagarh District"
  },
  {
    "label": "Nīlgiri",
    "value": "Nīlgiri"
  },
  {
    "label": "Nimāparha",
    "value": "Nimāparha"
  },
  {
    "label": "Nowrangapur",
    "value": "Nowrangapur"
  },
  {
    "label": "Nuapada",
    "value": "Nuapada"
  },
  {
    "label": "Padampur",
    "value": "Padampur"
  },
  {
    "label": "Parādīp Garh",
    "value": "Parādīp Garh"
  },
  {
    "label": "Patāmundai",
    "value": "Patāmundai"
  },
  {
    "label": "Patnāgarh",
    "value": "Patnāgarh"
  },
  {
    "label": "Phulbāni",
    "value": "Phulbāni"
  },
  {
    "label": "Pipili",
    "value": "Pipili"
  },
  {
    "label": "Polasara",
    "value": "Polasara"
  },
  {
    "label": "Purushottampur",
    "value": "Purushottampur"
  },
  {
    "label": "Rambha",
    "value": "Rambha"
  },
  {
    "label": "Remuna",
    "value": "Remuna"
  },
  {
    "label": "Rengāli",
    "value": "Rengāli"
  },
  {
    "label": "Sonepur",
    "value": "Sonepur"
  },
  {
    "label": "Sorada",
    "value": "Sorada"
  },
  {
    "label": "Soro",
    "value": "Soro"
  },
  {
    "label": "Subarnapur",
    "value": "Subarnapur"
  },
  {
    "label": "Tālcher",
    "value": "Tālcher"
  },
  {
    "label": "Tarabha",
    "value": "Tarabha"
  },
  {
    "label": "Titlāgarh",
    "value": "Titlāgarh"
  },
  {
    "label": "Udayagiri",
    "value": "Udayagiri"
  },
  {
    "label": "Kāraikāl",
    "value": "Kāraikāl"
  },
  {
    "label": "Mahe",
    "value": "Mahe"
  },
  {
    "label": "Abohar",
    "value": "Abohar"
  },
  {
    "label": "Adampur",
    "value": "Adampur"
  },
  {
    "label": "Ajitgarh",
    "value": "Ajitgarh"
  },
  {
    "label": "Ajnala",
    "value": "Ajnala"
  },
  {
    "label": "Akalgarh",
    "value": "Akalgarh"
  },
  {
    "label": "Alawalpur",
    "value": "Alawalpur"
  },
  {
    "label": "Amloh",
    "value": "Amloh"
  },
  {
    "label": "Anandpur Sahib",
    "value": "Anandpur Sahib"
  },
  {
    "label": "Badhni Kalan",
    "value": "Badhni Kalan"
  },
  {
    "label": "Bagha Purana",
    "value": "Bagha Purana"
  },
  {
    "label": "Bakloh",
    "value": "Bakloh"
  },
  {
    "label": "Balachor",
    "value": "Balachor"
  },
  {
    "label": "Banur",
    "value": "Banur"
  },
  {
    "label": "Begowal",
    "value": "Begowal"
  },
  {
    "label": "Bhadaur",
    "value": "Bhadaur"
  },
  {
    "label": "Bhawanigarh",
    "value": "Bhawanigarh"
  },
  {
    "label": "Bhikhi",
    "value": "Bhikhi"
  },
  {
    "label": "Bhogpur",
    "value": "Bhogpur"
  },
  {
    "label": "Bholath",
    "value": "Bholath"
  },
  {
    "label": "Budhlada",
    "value": "Budhlada"
  },
  {
    "label": "Chima",
    "value": "Chima"
  },
  {
    "label": "Dasuya",
    "value": "Dasuya"
  },
  {
    "label": "Dera Baba Nanak",
    "value": "Dera Baba Nanak"
  },
  {
    "label": "Dera Bassi",
    "value": "Dera Bassi"
  },
  {
    "label": "Dhanaula",
    "value": "Dhanaula"
  },
  {
    "label": "Dhilwan",
    "value": "Dhilwan"
  },
  {
    "label": "Dhudi",
    "value": "Dhudi"
  },
  {
    "label": "Dina Nagar",
    "value": "Dina Nagar"
  },
  {
    "label": "Dirba",
    "value": "Dirba"
  },
  {
    "label": "Doraha",
    "value": "Doraha"
  },
  {
    "label": "Fatehgarh Churian",
    "value": "Fatehgarh Churian"
  },
  {
    "label": "Fatehgarh Sahib",
    "value": "Fatehgarh Sahib"
  },
  {
    "label": "Fazilka",
    "value": "Fazilka"
  },
  {
    "label": "Firozpur District",
    "value": "Firozpur District"
  },
  {
    "label": "Gardhiwala",
    "value": "Gardhiwala"
  },
  {
    "label": "Ghanaur",
    "value": "Ghanaur"
  },
  {
    "label": "Giddarbaha",
    "value": "Giddarbaha"
  },
  {
    "label": "Guru Har Sahai",
    "value": "Guru Har Sahai"
  },
  {
    "label": "Hajipur",
    "value": "Hajipur"
  },
  {
    "label": "Hariana",
    "value": "Hariana"
  },
  {
    "label": "Ishanpur",
    "value": "Ishanpur"
  },
  {
    "label": "Jaito",
    "value": "Jaito"
  },
  {
    "label": "Jandiala",
    "value": "Jandiala"
  },
  {
    "label": "Jandiala Guru",
    "value": "Jandiala Guru"
  },
  {
    "label": "Kalanaur",
    "value": "Kalanaur"
  },
  {
    "label": "Kapurthala",
    "value": "Kapurthala"
  },
  {
    "label": "Kartarpur",
    "value": "Kartarpur"
  },
  {
    "label": "Khamanon",
    "value": "Khamanon"
  },
  {
    "label": "Khemkaran",
    "value": "Khemkaran"
  },
  {
    "label": "Kotkapura",
    "value": "Kotkapura"
  },
  {
    "label": "Laungowal",
    "value": "Laungowal"
  },
  {
    "label": "Majitha",
    "value": "Majitha"
  },
  {
    "label": "Makhu",
    "value": "Makhu"
  },
  {
    "label": "Malaut",
    "value": "Malaut"
  },
  {
    "label": "Malerkotla",
    "value": "Malerkotla"
  },
  {
    "label": "Maur Mandi",
    "value": "Maur Mandi"
  },
  {
    "label": "Nakodar",
    "value": "Nakodar"
  },
  {
    "label": "Nangal",
    "value": "Nangal"
  },
  {
    "label": "Nawanshahr",
    "value": "Nawanshahr"
  },
  {
    "label": "Nurmahal",
    "value": "Nurmahal"
  },
  {
    "label": "Nurpur Kalan",
    "value": "Nurpur Kalan"
  },
  {
    "label": "Patti",
    "value": "Patti"
  },
  {
    "label": "Phillaur",
    "value": "Phillaur"
  },
  {
    "label": "Qadian",
    "value": "Qadian"
  },
  {
    "label": "Rahon",
    "value": "Rahon"
  },
  {
    "label": "Raikot",
    "value": "Raikot"
  },
  {
    "label": "Rajasansi",
    "value": "Rajasansi"
  },
  {
    "label": "Ram Das",
    "value": "Ram Das"
  },
  {
    "label": "Rampura",
    "value": "Rampura"
  },
  {
    "label": "Samrala",
    "value": "Samrala"
  },
  {
    "label": "Sanaur",
    "value": "Sanaur"
  },
  {
    "label": "Sardulgarh",
    "value": "Sardulgarh"
  },
  {
    "label": "Shahid Bhagat Singh Nagar",
    "value": "Shahid Bhagat Singh Nagar"
  },
  {
    "label": "Sham Churasi",
    "value": "Sham Churasi"
  },
  {
    "label": "Sirhind-Fategarh",
    "value": "Sirhind-Fategarh"
  },
  {
    "label": "Sri Muktsar Sahib",
    "value": "Sri Muktsar Sahib"
  },
  {
    "label": "Sultanpur Lodhi",
    "value": "Sultanpur Lodhi"
  },
  {
    "label": "Talwandi Bhai",
    "value": "Talwandi Bhai"
  },
  {
    "label": "Talwara",
    "value": "Talwara"
  },
  {
    "label": "Tarn Taran Sahib",
    "value": "Tarn Taran Sahib"
  },
  {
    "label": "Zira",
    "value": "Zira"
  },
  {
    "label": "Abhaneri",
    "value": "Abhaneri"
  },
  {
    "label": "Ābu",
    "value": "Ābu"
  },
  {
    "label": "Ābu Road",
    "value": "Ābu Road"
  },
  {
    "label": "Aklera",
    "value": "Aklera"
  },
  {
    "label": "Anta",
    "value": "Anta"
  },
  {
    "label": "Anūpgarh",
    "value": "Anūpgarh"
  },
  {
    "label": "Āsind",
    "value": "Āsind"
  },
  {
    "label": "Bagar",
    "value": "Bagar"
  },
  {
    "label": "Bakāni",
    "value": "Bakāni"
  },
  {
    "label": "Bāli",
    "value": "Bāli"
  },
  {
    "label": "Bālotra",
    "value": "Bālotra"
  },
  {
    "label": "Bāndīkūi",
    "value": "Bāndīkūi"
  },
  {
    "label": "Bānswāra",
    "value": "Bānswāra"
  },
  {
    "label": "Baran",
    "value": "Baran"
  },
  {
    "label": "Bārān",
    "value": "Bārān"
  },
  {
    "label": "Bāri",
    "value": "Bāri"
  },
  {
    "label": "Bari Sādri",
    "value": "Bari Sādri"
  },
  {
    "label": "Bārmer",
    "value": "Bārmer"
  },
  {
    "label": "Basi",
    "value": "Basi"
  },
  {
    "label": "Basni",
    "value": "Basni"
  },
  {
    "label": "Baswa",
    "value": "Baswa"
  },
  {
    "label": "Bayāna",
    "value": "Bayāna"
  },
  {
    "label": "Beāwar",
    "value": "Beāwar"
  },
  {
    "label": "Begūn",
    "value": "Begūn"
  },
  {
    "label": "Behror",
    "value": "Behror"
  },
  {
    "label": "Bhādāsar",
    "value": "Bhādāsar"
  },
  {
    "label": "Bhādra",
    "value": "Bhādra"
  },
  {
    "label": "Bhasāwar",
    "value": "Bhasāwar"
  },
  {
    "label": "Bhīlwāra",
    "value": "Bhīlwāra"
  },
  {
    "label": "Bhindār",
    "value": "Bhindār"
  },
  {
    "label": "Bhīnmāl",
    "value": "Bhīnmāl"
  },
  {
    "label": "Bhiwadi",
    "value": "Bhiwadi"
  },
  {
    "label": "Bhuma",
    "value": "Bhuma"
  },
  {
    "label": "Bīkaner",
    "value": "Bīkaner"
  },
  {
    "label": "Bīkāner",
    "value": "Bīkāner"
  },
  {
    "label": "Bilāra",
    "value": "Bilāra"
  },
  {
    "label": "Bissāu",
    "value": "Bissāu"
  },
  {
    "label": "Borkhera",
    "value": "Borkhera"
  },
  {
    "label": "Būndi",
    "value": "Būndi"
  },
  {
    "label": "Chaksu",
    "value": "Chaksu"
  },
  {
    "label": "Chechat",
    "value": "Chechat"
  },
  {
    "label": "Chhāpar",
    "value": "Chhāpar"
  },
  {
    "label": "Chhoti Sādri",
    "value": "Chhoti Sādri"
  },
  {
    "label": "Chidawa",
    "value": "Chidawa"
  },
  {
    "label": "Chittaurgarh",
    "value": "Chittaurgarh"
  },
  {
    "label": "Chūru",
    "value": "Chūru"
  },
  {
    "label": "Dariba",
    "value": "Dariba"
  },
  {
    "label": "Dausa",
    "value": "Dausa"
  },
  {
    "label": "Deshnoke",
    "value": "Deshnoke"
  },
  {
    "label": "Dhaulpur",
    "value": "Dhaulpur"
  },
  {
    "label": "Dīdwāna",
    "value": "Dīdwāna"
  },
  {
    "label": "Dīg",
    "value": "Dīg"
  },
  {
    "label": "Dūngarpur",
    "value": "Dūngarpur"
  },
  {
    "label": "Galiākot",
    "value": "Galiākot"
  },
  {
    "label": "Gangānagar",
    "value": "Gangānagar"
  },
  {
    "label": "Gulābpura",
    "value": "Gulābpura"
  },
  {
    "label": "Hanumangarh",
    "value": "Hanumangarh"
  },
  {
    "label": "Hanumāngarh",
    "value": "Hanumāngarh"
  },
  {
    "label": "Hindaun",
    "value": "Hindaun"
  },
  {
    "label": "Jahāzpur",
    "value": "Jahāzpur"
  },
  {
    "label": "Jaitāran",
    "value": "Jaitāran"
  },
  {
    "label": "Jalor",
    "value": "Jalor"
  },
  {
    "label": "Jalore",
    "value": "Jalore"
  },
  {
    "label": "Jhālāwār",
    "value": "Jhālāwār"
  },
  {
    "label": "Jhālrapātan",
    "value": "Jhālrapātan"
  },
  {
    "label": "Jhunjhunūn",
    "value": "Jhunjhunūn"
  },
  {
    "label": "Jobner",
    "value": "Jobner"
  },
  {
    "label": "Kāman",
    "value": "Kāman"
  },
  {
    "label": "Kānor",
    "value": "Kānor"
  },
  {
    "label": "Kāpren",
    "value": "Kāpren"
  },
  {
    "label": "Karanpur",
    "value": "Karanpur"
  },
  {
    "label": "Karauli",
    "value": "Karauli"
  },
  {
    "label": "Kekri",
    "value": "Kekri"
  },
  {
    "label": "Keshorai Pātan",
    "value": "Keshorai Pātan"
  },
  {
    "label": "Khandela",
    "value": "Khandela"
  },
  {
    "label": "Khānpur",
    "value": "Khānpur"
  },
  {
    "label": "Khetri",
    "value": "Khetri"
  },
  {
    "label": "Kotputli",
    "value": "Kotputli"
  },
  {
    "label": "Kuchāman",
    "value": "Kuchāman"
  },
  {
    "label": "Kuchera",
    "value": "Kuchera"
  },
  {
    "label": "Kūmher",
    "value": "Kūmher"
  },
  {
    "label": "Kushālgarh",
    "value": "Kushālgarh"
  },
  {
    "label": "Lachhmangarh Sīkar",
    "value": "Lachhmangarh Sīkar"
  },
  {
    "label": "Lādnūn",
    "value": "Lādnūn"
  },
  {
    "label": "Lākheri",
    "value": "Lākheri"
  },
  {
    "label": "Lālsot",
    "value": "Lālsot"
  },
  {
    "label": "Losal",
    "value": "Losal"
  },
  {
    "label": "Mahwah",
    "value": "Mahwah"
  },
  {
    "label": "Makrāna",
    "value": "Makrāna"
  },
  {
    "label": "Mālpura",
    "value": "Mālpura"
  },
  {
    "label": "Māndalgarh",
    "value": "Māndalgarh"
  },
  {
    "label": "Mandāwar",
    "value": "Mandāwar"
  },
  {
    "label": "Manohar Thāna",
    "value": "Manohar Thāna"
  },
  {
    "label": "Meethari Marwar",
    "value": "Meethari Marwar"
  },
  {
    "label": "Merta",
    "value": "Merta"
  },
  {
    "label": "Mūndwa",
    "value": "Mūndwa"
  },
  {
    "label": "Nādbai",
    "value": "Nādbai"
  },
  {
    "label": "Nāgaur",
    "value": "Nāgaur"
  },
  {
    "label": "Nainwa",
    "value": "Nainwa"
  },
  {
    "label": "Napāsar",
    "value": "Napāsar"
  },
  {
    "label": "Nasīrābād",
    "value": "Nasīrābād"
  },
  {
    "label": "Nāthdwāra",
    "value": "Nāthdwāra"
  },
  {
    "label": "Nāwa",
    "value": "Nāwa"
  },
  {
    "label": "Nawalgarh",
    "value": "Nawalgarh"
  },
  {
    "label": "Neem ka Thana",
    "value": "Neem ka Thana"
  },
  {
    "label": "Nīmāj",
    "value": "Nīmāj"
  },
  {
    "label": "Nīmbāhera",
    "value": "Nīmbāhera"
  },
  {
    "label": "Niwai",
    "value": "Niwai"
  },
  {
    "label": "Nohar",
    "value": "Nohar"
  },
  {
    "label": "Nokha",
    "value": "Nokha"
  },
  {
    "label": "Partāpur",
    "value": "Partāpur"
  },
  {
    "label": "Parvatsar",
    "value": "Parvatsar"
  },
  {
    "label": "Phalodi",
    "value": "Phalodi"
  },
  {
    "label": "Phulera",
    "value": "Phulera"
  },
  {
    "label": "Pilāni",
    "value": "Pilāni"
  },
  {
    "label": "Pilibangan",
    "value": "Pilibangan"
  },
  {
    "label": "Pindwāra",
    "value": "Pindwāra"
  },
  {
    "label": "Pīpār",
    "value": "Pīpār"
  },
  {
    "label": "Pirāwa",
    "value": "Pirāwa"
  },
  {
    "label": "Pokaran",
    "value": "Pokaran"
  },
  {
    "label": "Pratapgarh",
    "value": "Pratapgarh"
  },
  {
    "label": "Pratāpgarh",
    "value": "Pratāpgarh"
  },
  {
    "label": "Rāisinghnagar",
    "value": "Rāisinghnagar"
  },
  {
    "label": "Rājākhera",
    "value": "Rājākhera"
  },
  {
    "label": "Rājaldesar",
    "value": "Rājaldesar"
  },
  {
    "label": "Rājsamand",
    "value": "Rājsamand"
  },
  {
    "label": "Rajsamand",
    "value": "Rajsamand"
  },
  {
    "label": "Rāmganj Mandi",
    "value": "Rāmganj Mandi"
  },
  {
    "label": "Rāni",
    "value": "Rāni"
  },
  {
    "label": "Rāwatbhāta",
    "value": "Rāwatbhāta"
  },
  {
    "label": "Rāwatsār",
    "value": "Rāwatsār"
  },
  {
    "label": "Rīngas",
    "value": "Rīngas"
  },
  {
    "label": "Sādri",
    "value": "Sādri"
  },
  {
    "label": "Sālūmbar",
    "value": "Sālūmbar"
  },
  {
    "label": "Sāmbhar",
    "value": "Sāmbhar"
  },
  {
    "label": "Samdari",
    "value": "Samdari"
  },
  {
    "label": "Sānchor",
    "value": "Sānchor"
  },
  {
    "label": "Sangariā",
    "value": "Sangariā"
  },
  {
    "label": "Sangod",
    "value": "Sangod"
  },
  {
    "label": "Sardārshahr",
    "value": "Sardārshahr"
  },
  {
    "label": "Sarwār",
    "value": "Sarwār"
  },
  {
    "label": "Sawāi Mādhopur",
    "value": "Sawāi Mādhopur"
  },
  {
    "label": "Sheoganj",
    "value": "Sheoganj"
  },
  {
    "label": "Sīkar",
    "value": "Sīkar"
  },
  {
    "label": "Siwāna",
    "value": "Siwāna"
  },
  {
    "label": "Sri Dūngargarh",
    "value": "Sri Dūngargarh"
  },
  {
    "label": "Sri Mādhopur",
    "value": "Sri Mādhopur"
  },
  {
    "label": "Sūjāngarh",
    "value": "Sūjāngarh"
  },
  {
    "label": "Suket",
    "value": "Suket"
  },
  {
    "label": "Sunel",
    "value": "Sunel"
  },
  {
    "label": "Sūrajgarh",
    "value": "Sūrajgarh"
  },
  {
    "label": "Sūratgarh",
    "value": "Sūratgarh"
  },
  {
    "label": "Takhatgarh",
    "value": "Takhatgarh"
  },
  {
    "label": "Tārānagar",
    "value": "Tārānagar"
  },
  {
    "label": "Tijāra",
    "value": "Tijāra"
  },
  {
    "label": "Todabhim",
    "value": "Todabhim"
  },
  {
    "label": "Todaraisingh",
    "value": "Todaraisingh"
  },
  {
    "label": "Udpura",
    "value": "Udpura"
  },
  {
    "label": "Uniāra",
    "value": "Uniāra"
  },
  {
    "label": "Wer",
    "value": "Wer"
  },
  {
    "label": "East District",
    "value": "East District"
  },
  {
    "label": "Gyalshing",
    "value": "Gyalshing"
  },
  {
    "label": "Jorethang",
    "value": "Jorethang"
  },
  {
    "label": "Mangan",
    "value": "Mangan"
  },
  {
    "label": "Namchi",
    "value": "Namchi"
  },
  {
    "label": "Naya Bāzār",
    "value": "Naya Bāzār"
  },
  {
    "label": "North District",
    "value": "North District"
  },
  {
    "label": "Rangpo",
    "value": "Rangpo"
  },
  {
    "label": "South District",
    "value": "South District"
  },
  {
    "label": "West District",
    "value": "West District"
  },
  {
    "label": "Abirāmam",
    "value": "Abirāmam"
  },
  {
    "label": "Adirampattinam",
    "value": "Adirampattinam"
  },
  {
    "label": "Aduthurai",
    "value": "Aduthurai"
  },
  {
    "label": "Alagāpuram",
    "value": "Alagāpuram"
  },
  {
    "label": "Alandur",
    "value": "Alandur"
  },
  {
    "label": "Alangānallūr",
    "value": "Alangānallūr"
  },
  {
    "label": "Alangāyam",
    "value": "Alangāyam"
  },
  {
    "label": "Ālangudi",
    "value": "Ālangudi"
  },
  {
    "label": "Ālangulam",
    "value": "Ālangulam"
  },
  {
    "label": "Ālappākkam",
    "value": "Ālappākkam"
  },
  {
    "label": "Alwa Tirunagari",
    "value": "Alwa Tirunagari"
  },
  {
    "label": "Ambasamudram",
    "value": "Ambasamudram"
  },
  {
    "label": "Ambattūr",
    "value": "Ambattūr"
  },
  {
    "label": "Ammāpettai",
    "value": "Ammāpettai"
  },
  {
    "label": "Anamalais",
    "value": "Anamalais"
  },
  {
    "label": "Āndippatti",
    "value": "Āndippatti"
  },
  {
    "label": "Annāmalainagar",
    "value": "Annāmalainagar"
  },
  {
    "label": "Annavāsal",
    "value": "Annavāsal"
  },
  {
    "label": "Annur",
    "value": "Annur"
  },
  {
    "label": "Anthiyur",
    "value": "Anthiyur"
  },
  {
    "label": "Arantāngi",
    "value": "Arantāngi"
  },
  {
    "label": "Arcot",
    "value": "Arcot"
  },
  {
    "label": "Arimalam",
    "value": "Arimalam"
  },
  {
    "label": "Ariyalūr",
    "value": "Ariyalūr"
  },
  {
    "label": "Arni",
    "value": "Arni"
  },
  {
    "label": "Arumbāvūr",
    "value": "Arumbāvūr"
  },
  {
    "label": "Arumuganeri",
    "value": "Arumuganeri"
  },
  {
    "label": "Aruppukkottai",
    "value": "Aruppukkottai"
  },
  {
    "label": "Aruvankad",
    "value": "Aruvankad"
  },
  {
    "label": "Āttayyāmpatti",
    "value": "Āttayyāmpatti"
  },
  {
    "label": "Auroville",
    "value": "Auroville"
  },
  {
    "label": "Āvadi",
    "value": "Āvadi"
  },
  {
    "label": "Avinashi",
    "value": "Avinashi"
  },
  {
    "label": "Ayakudi",
    "value": "Ayakudi"
  },
  {
    "label": "Ayyampettāi",
    "value": "Ayyampettāi"
  },
  {
    "label": "Bhavāni",
    "value": "Bhavāni"
  },
  {
    "label": "Bodināyakkanūr",
    "value": "Bodināyakkanūr"
  },
  {
    "label": "Chengam",
    "value": "Chengam"
  },
  {
    "label": "Chennimalai",
    "value": "Chennimalai"
  },
  {
    "label": "Chetput",
    "value": "Chetput"
  },
  {
    "label": "Chettipālaiyam",
    "value": "Chettipālaiyam"
  },
  {
    "label": "Cheyyur",
    "value": "Cheyyur"
  },
  {
    "label": "Chingleput",
    "value": "Chingleput"
  },
  {
    "label": "Chinna Salem",
    "value": "Chinna Salem"
  },
  {
    "label": "Chinnamanūr",
    "value": "Chinnamanūr"
  },
  {
    "label": "Chinnasekkadu",
    "value": "Chinnasekkadu"
  },
  {
    "label": "Cholapuram",
    "value": "Cholapuram"
  },
  {
    "label": "Colachel",
    "value": "Colachel"
  },
  {
    "label": "Denkanikota",
    "value": "Denkanikota"
  },
  {
    "label": "Desūr",
    "value": "Desūr"
  },
  {
    "label": "Devadānappatti",
    "value": "Devadānappatti"
  },
  {
    "label": "Devakottai",
    "value": "Devakottai"
  },
  {
    "label": "Dhali",
    "value": "Dhali"
  },
  {
    "label": "Dūsi",
    "value": "Dūsi"
  },
  {
    "label": "Elāyirampannai",
    "value": "Elāyirampannai"
  },
  {
    "label": "Elumalai",
    "value": "Elumalai"
  },
  {
    "label": "Eraniel",
    "value": "Eraniel"
  },
  {
    "label": "Erumaippatti",
    "value": "Erumaippatti"
  },
  {
    "label": "Ettaiyapuram",
    "value": "Ettaiyapuram"
  },
  {
    "label": "Gāndhī Nagar",
    "value": "Gāndhī Nagar"
  },
  {
    "label": "Gangaikondān",
    "value": "Gangaikondān"
  },
  {
    "label": "Gangavalli",
    "value": "Gangavalli"
  },
  {
    "label": "Gingee",
    "value": "Gingee"
  },
  {
    "label": "Gudalur",
    "value": "Gudalur"
  },
  {
    "label": "Gudiyatham",
    "value": "Gudiyatham"
  },
  {
    "label": "Gūduvāncheri",
    "value": "Gūduvāncheri"
  },
  {
    "label": "Gummidipundi",
    "value": "Gummidipundi"
  },
  {
    "label": "Harūr",
    "value": "Harūr"
  },
  {
    "label": "Hosūr",
    "value": "Hosūr"
  },
  {
    "label": "Idappadi",
    "value": "Idappadi"
  },
  {
    "label": "Ilampillai",
    "value": "Ilampillai"
  },
  {
    "label": "Iluppūr",
    "value": "Iluppūr"
  },
  {
    "label": "Injambakkam",
    "value": "Injambakkam"
  },
  {
    "label": "Irugūr",
    "value": "Irugūr"
  },
  {
    "label": "Jalakandapuram",
    "value": "Jalakandapuram"
  },
  {
    "label": "Jalārpet",
    "value": "Jalārpet"
  },
  {
    "label": "Jayamkondacholapuram",
    "value": "Jayamkondacholapuram"
  },
  {
    "label": "Kadambūr",
    "value": "Kadambūr"
  },
  {
    "label": "Kadayanallur",
    "value": "Kadayanallur"
  },
  {
    "label": "Kalakkādu",
    "value": "Kalakkādu"
  },
  {
    "label": "Kalavai",
    "value": "Kalavai"
  },
  {
    "label": "Kallakkurichchi",
    "value": "Kallakkurichchi"
  },
  {
    "label": "Kallidaikurichi",
    "value": "Kallidaikurichi"
  },
  {
    "label": "Kallupatti",
    "value": "Kallupatti"
  },
  {
    "label": "Kalugumalai",
    "value": "Kalugumalai"
  },
  {
    "label": "Kamuthi",
    "value": "Kamuthi"
  },
  {
    "label": "Kanadukattan",
    "value": "Kanadukattan"
  },
  {
    "label": "Kancheepuram",
    "value": "Kancheepuram"
  },
  {
    "label": "Kanniyākumāri",
    "value": "Kanniyākumāri"
  },
  {
    "label": "Kāraikkudi",
    "value": "Kāraikkudi"
  },
  {
    "label": "Kāramadai",
    "value": "Kāramadai"
  },
  {
    "label": "Karambakkudi",
    "value": "Karambakkudi"
  },
  {
    "label": "Kariapatti",
    "value": "Kariapatti"
  },
  {
    "label": "Karumbākkam",
    "value": "Karumbākkam"
  },
  {
    "label": "Kātpādi",
    "value": "Kātpādi"
  },
  {
    "label": "Kattivākkam",
    "value": "Kattivākkam"
  },
  {
    "label": "Kāttupputtūr",
    "value": "Kāttupputtūr"
  },
  {
    "label": "Kāveripatnam",
    "value": "Kāveripatnam"
  },
  {
    "label": "Kayalpattinam",
    "value": "Kayalpattinam"
  },
  {
    "label": "Kayattār",
    "value": "Kayattār"
  },
  {
    "label": "Keelakarai",
    "value": "Keelakarai"
  },
  {
    "label": "Kelamangalam",
    "value": "Kelamangalam"
  },
  {
    "label": "Kīl Bhuvanagiri",
    "value": "Kīl Bhuvanagiri"
  },
  {
    "label": "Kilvelur",
    "value": "Kilvelur"
  },
  {
    "label": "Kīranūr",
    "value": "Kīranūr"
  },
  {
    "label": "Kodaikānāl",
    "value": "Kodaikānāl"
  },
  {
    "label": "Kodumudi",
    "value": "Kodumudi"
  },
  {
    "label": "Kombai",
    "value": "Kombai"
  },
  {
    "label": "Konganāpuram",
    "value": "Konganāpuram"
  },
  {
    "label": "Koradāchcheri",
    "value": "Koradāchcheri"
  },
  {
    "label": "Korampallam",
    "value": "Korampallam"
  },
  {
    "label": "Kotagiri",
    "value": "Kotagiri"
  },
  {
    "label": "Kottaiyūr",
    "value": "Kottaiyūr"
  },
  {
    "label": "Kulattūr",
    "value": "Kulattūr"
  },
  {
    "label": "Kulittalai",
    "value": "Kulittalai"
  },
  {
    "label": "Kumaralingam",
    "value": "Kumaralingam"
  },
  {
    "label": "Kunnattūr",
    "value": "Kunnattūr"
  },
  {
    "label": "Kurinjippādi",
    "value": "Kurinjippādi"
  },
  {
    "label": "Kuttālam",
    "value": "Kuttālam"
  },
  {
    "label": "Madambakkam",
    "value": "Madambakkam"
  },
  {
    "label": "Madipakkam",
    "value": "Madipakkam"
  },
  {
    "label": "Madukkarai",
    "value": "Madukkarai"
  },
  {
    "label": "Madukkūr",
    "value": "Madukkūr"
  },
  {
    "label": "Madurāntakam",
    "value": "Madurāntakam"
  },
  {
    "label": "Mallāpuram",
    "value": "Mallāpuram"
  },
  {
    "label": "Mallasamudram",
    "value": "Mallasamudram"
  },
  {
    "label": "Māllūr",
    "value": "Māllūr"
  },
  {
    "label": "Manalūrpettai",
    "value": "Manalūrpettai"
  },
  {
    "label": "Manamadurai",
    "value": "Manamadurai"
  },
  {
    "label": "Manappakkam",
    "value": "Manappakkam"
  },
  {
    "label": "Manapparai",
    "value": "Manapparai"
  },
  {
    "label": "Manavālakurichi",
    "value": "Manavālakurichi"
  },
  {
    "label": "Mandapam",
    "value": "Mandapam"
  },
  {
    "label": "Mangalam",
    "value": "Mangalam"
  },
  {
    "label": "Marakkanam",
    "value": "Marakkanam"
  },
  {
    "label": "Mārāndahalli",
    "value": "Mārāndahalli"
  },
  {
    "label": "Masinigudi",
    "value": "Masinigudi"
  },
  {
    "label": "Māttūr",
    "value": "Māttūr"
  },
  {
    "label": "Mayiladuthurai",
    "value": "Mayiladuthurai"
  },
  {
    "label": "Melur",
    "value": "Melur"
  },
  {
    "label": "Mettupalayam",
    "value": "Mettupalayam"
  },
  {
    "label": "Mettuppālaiyam",
    "value": "Mettuppālaiyam"
  },
  {
    "label": "Mīnjūr",
    "value": "Mīnjūr"
  },
  {
    "label": "Mohanūr",
    "value": "Mohanūr"
  },
  {
    "label": "Mudukulattūr",
    "value": "Mudukulattūr"
  },
  {
    "label": "Mūlanūr",
    "value": "Mūlanūr"
  },
  {
    "label": "Musiri",
    "value": "Musiri"
  },
  {
    "label": "Muttupet",
    "value": "Muttupet"
  },
  {
    "label": "Naduvattam",
    "value": "Naduvattam"
  },
  {
    "label": "Nāgercoil",
    "value": "Nāgercoil"
  },
  {
    "label": "Nāmagiripettai",
    "value": "Nāmagiripettai"
  },
  {
    "label": "Nāmakkal",
    "value": "Nāmakkal"
  },
  {
    "label": "Nambiyūr",
    "value": "Nambiyūr"
  },
  {
    "label": "Nambutalai",
    "value": "Nambutalai"
  },
  {
    "label": "Nandambakkam",
    "value": "Nandambakkam"
  },
  {
    "label": "Nangavalli",
    "value": "Nangavalli"
  },
  {
    "label": "Nangilickondan",
    "value": "Nangilickondan"
  },
  {
    "label": "Nanguneri",
    "value": "Nanguneri"
  },
  {
    "label": "Nannilam",
    "value": "Nannilam"
  },
  {
    "label": "Nāravārikuppam",
    "value": "Nāravārikuppam"
  },
  {
    "label": "Nattam",
    "value": "Nattam"
  },
  {
    "label": "Nāttarasankottai",
    "value": "Nāttarasankottai"
  },
  {
    "label": "Needamangalam",
    "value": "Needamangalam"
  },
  {
    "label": "Neelankarai",
    "value": "Neelankarai"
  },
  {
    "label": "Negapatam",
    "value": "Negapatam"
  },
  {
    "label": "Nellikkuppam",
    "value": "Nellikkuppam"
  },
  {
    "label": "Nilakottai",
    "value": "Nilakottai"
  },
  {
    "label": "Nilgiris",
    "value": "Nilgiris"
  },
  {
    "label": "Odugattūr",
    "value": "Odugattūr"
  },
  {
    "label": "Padmanābhapuram",
    "value": "Padmanābhapuram"
  },
  {
    "label": "Pālakkodu",
    "value": "Pālakkodu"
  },
  {
    "label": "Pālamedu",
    "value": "Pālamedu"
  },
  {
    "label": "Palavakkam",
    "value": "Palavakkam"
  },
  {
    "label": "Pallappatti",
    "value": "Pallappatti"
  },
  {
    "label": "Pallattūr",
    "value": "Pallattūr"
  },
  {
    "label": "Pallāvaram",
    "value": "Pallāvaram"
  },
  {
    "label": "Pallikondai",
    "value": "Pallikondai"
  },
  {
    "label": "Pallipattu",
    "value": "Pallipattu"
  },
  {
    "label": "Pallippatti",
    "value": "Pallippatti"
  },
  {
    "label": "Papanasam",
    "value": "Papanasam"
  },
  {
    "label": "Pāpireddippatti",
    "value": "Pāpireddippatti"
  },
  {
    "label": "Pāppārappatti",
    "value": "Pāppārappatti"
  },
  {
    "label": "Paramagudi",
    "value": "Paramagudi"
  },
  {
    "label": "Pennādam",
    "value": "Pennādam"
  },
  {
    "label": "Pennāgaram",
    "value": "Pennāgaram"
  },
  {
    "label": "Pennāthur",
    "value": "Pennāthur"
  },
  {
    "label": "Peraiyur",
    "value": "Peraiyur"
  },
  {
    "label": "Peranamallūr",
    "value": "Peranamallūr"
  },
  {
    "label": "Peranāmpattu",
    "value": "Peranāmpattu"
  },
  {
    "label": "Peravurani",
    "value": "Peravurani"
  },
  {
    "label": "Periyakulam",
    "value": "Periyakulam"
  },
  {
    "label": "Periyanayakkanpalaiyam",
    "value": "Periyanayakkanpalaiyam"
  },
  {
    "label": "Periyanegamam",
    "value": "Periyanegamam"
  },
  {
    "label": "Periyapatti",
    "value": "Periyapatti"
  },
  {
    "label": "Periyapattinam",
    "value": "Periyapattinam"
  },
  {
    "label": "Perungudi",
    "value": "Perungudi"
  },
  {
    "label": "Perūr",
    "value": "Perūr"
  },
  {
    "label": "Polūr",
    "value": "Polūr"
  },
  {
    "label": "Ponnamarāvati",
    "value": "Ponnamarāvati"
  },
  {
    "label": "Poonamalle",
    "value": "Poonamalle"
  },
  {
    "label": "Porur",
    "value": "Porur"
  },
  {
    "label": "Puduppatti",
    "value": "Puduppatti"
  },
  {
    "label": "Pudūr",
    "value": "Pudūr"
  },
  {
    "label": "Puduvāyal",
    "value": "Puduvāyal"
  },
  {
    "label": "Puliyangudi",
    "value": "Puliyangudi"
  },
  {
    "label": "Puliyūr",
    "value": "Puliyūr"
  },
  {
    "label": "Pullambādi",
    "value": "Pullambādi"
  },
  {
    "label": "Punjai Puliyampatti",
    "value": "Punjai Puliyampatti"
  },
  {
    "label": "Rāmanāthapuram",
    "value": "Rāmanāthapuram"
  },
  {
    "label": "Rameswaram",
    "value": "Rameswaram"
  },
  {
    "label": "Rasipuram",
    "value": "Rasipuram"
  },
  {
    "label": "Saint Thomas Mount",
    "value": "Saint Thomas Mount"
  },
  {
    "label": "Sathankulam",
    "value": "Sathankulam"
  },
  {
    "label": "Sathyamangalam",
    "value": "Sathyamangalam"
  },
  {
    "label": "Sāyalkudi",
    "value": "Sāyalkudi"
  },
  {
    "label": "Seven Pagodas",
    "value": "Seven Pagodas"
  },
  {
    "label": "Sholinghur",
    "value": "Sholinghur"
  },
  {
    "label": "Singānallūr",
    "value": "Singānallūr"
  },
  {
    "label": "Singapperumālkovil",
    "value": "Singapperumālkovil"
  },
  {
    "label": "Sīrkāzhi",
    "value": "Sīrkāzhi"
  },
  {
    "label": "Sirumugai",
    "value": "Sirumugai"
  },
  {
    "label": "Sivagiri",
    "value": "Sivagiri"
  },
  {
    "label": "Srīmushnam",
    "value": "Srīmushnam"
  },
  {
    "label": "Srīperumbūdūr",
    "value": "Srīperumbūdūr"
  },
  {
    "label": "Srivaikuntam",
    "value": "Srivaikuntam"
  },
  {
    "label": "Suchindram",
    "value": "Suchindram"
  },
  {
    "label": "Sulur",
    "value": "Sulur"
  },
  {
    "label": "Sūrandai",
    "value": "Sūrandai"
  },
  {
    "label": "Swāmimalai",
    "value": "Swāmimalai"
  },
  {
    "label": "Tanjore",
    "value": "Tanjore"
  },
  {
    "label": "Tāramangalam",
    "value": "Tāramangalam"
  },
  {
    "label": "Tāttayyangārpettai",
    "value": "Tāttayyangārpettai"
  },
  {
    "label": "Teni",
    "value": "Teni"
  },
  {
    "label": "Tharangambadi",
    "value": "Tharangambadi"
  },
  {
    "label": "Theni",
    "value": "Theni"
  },
  {
    "label": "Thirukattupalli",
    "value": "Thirukattupalli"
  },
  {
    "label": "Thiruvaiyaru",
    "value": "Thiruvaiyaru"
  },
  {
    "label": "Thiruvallur",
    "value": "Thiruvallur"
  },
  {
    "label": "Thiruvidaimaruthur",
    "value": "Thiruvidaimaruthur"
  },
  {
    "label": "Thoothukkudi",
    "value": "Thoothukkudi"
  },
  {
    "label": "Tinnanūr",
    "value": "Tinnanūr"
  },
  {
    "label": "Tiruchengode",
    "value": "Tiruchengode"
  },
  {
    "label": "Tiruchirappalli",
    "value": "Tiruchirappalli"
  },
  {
    "label": "Tirukkoyilur",
    "value": "Tirukkoyilur"
  },
  {
    "label": "Tirumullaivāsal",
    "value": "Tirumullaivāsal"
  },
  {
    "label": "Tirunelveli Kattabo",
    "value": "Tirunelveli Kattabo"
  },
  {
    "label": "Tiruppālaikudi",
    "value": "Tiruppālaikudi"
  },
  {
    "label": "Tirupparangunram",
    "value": "Tirupparangunram"
  },
  {
    "label": "Tiruppuvanam",
    "value": "Tiruppuvanam"
  },
  {
    "label": "Tiruttangal",
    "value": "Tiruttangal"
  },
  {
    "label": "Tiruvannāmalai",
    "value": "Tiruvannāmalai"
  },
  {
    "label": "Tiruvottiyūr",
    "value": "Tiruvottiyūr"
  },
  {
    "label": "Tisaiyanvilai",
    "value": "Tisaiyanvilai"
  },
  {
    "label": "Tondi",
    "value": "Tondi"
  },
  {
    "label": "Turaiyūr",
    "value": "Turaiyūr"
  },
  {
    "label": "Udangudi",
    "value": "Udangudi"
  },
  {
    "label": "Udumalaippettai",
    "value": "Udumalaippettai"
  },
  {
    "label": "Uppiliyapuram",
    "value": "Uppiliyapuram"
  },
  {
    "label": "Usilampatti",
    "value": "Usilampatti"
  },
  {
    "label": "Uttamapālaiyam",
    "value": "Uttamapālaiyam"
  },
  {
    "label": "Uttiramerūr",
    "value": "Uttiramerūr"
  },
  {
    "label": "Ūttukkuli",
    "value": "Ūttukkuli"
  },
  {
    "label": "V.S.K.Valasai (Dindigul-Dist.)",
    "value": "V.S.K.Valasai (Dindigul-Dist.)"
  },
  {
    "label": "Vadakku Valliyūr",
    "value": "Vadakku Valliyūr"
  },
  {
    "label": "Vadakku Viravanallur",
    "value": "Vadakku Viravanallur"
  },
  {
    "label": "Vādippatti",
    "value": "Vādippatti"
  },
  {
    "label": "Valangaiman",
    "value": "Valangaiman"
  },
  {
    "label": "Valavanur",
    "value": "Valavanur"
  },
  {
    "label": "Vallam",
    "value": "Vallam"
  },
  {
    "label": "Valparai",
    "value": "Valparai"
  },
  {
    "label": "Vandalūr",
    "value": "Vandalūr"
  },
  {
    "label": "Vandavāsi",
    "value": "Vandavāsi"
  },
  {
    "label": "Vāsudevanallūr",
    "value": "Vāsudevanallūr"
  },
  {
    "label": "Vattalkundu",
    "value": "Vattalkundu"
  },
  {
    "label": "Vedaraniyam",
    "value": "Vedaraniyam"
  },
  {
    "label": "Vedasandūr",
    "value": "Vedasandūr"
  },
  {
    "label": "Velankanni",
    "value": "Velankanni"
  },
  {
    "label": "Vellānūr",
    "value": "Vellānūr"
  },
  {
    "label": "Velur",
    "value": "Velur"
  },
  {
    "label": "Vengavasal",
    "value": "Vengavasal"
  },
  {
    "label": "Vettaikkaranpudur",
    "value": "Vettaikkaranpudur"
  },
  {
    "label": "Vettavalam",
    "value": "Vettavalam"
  },
  {
    "label": "Vijayapuri",
    "value": "Vijayapuri"
  },
  {
    "label": "Vikravāndi",
    "value": "Vikravāndi"
  },
  {
    "label": "Vilattikulam",
    "value": "Vilattikulam"
  },
  {
    "label": "Vīraganūr",
    "value": "Vīraganūr"
  },
  {
    "label": "Virudhunagar",
    "value": "Virudhunagar"
  },
  {
    "label": "Virudunagar",
    "value": "Virudunagar"
  },
  {
    "label": "Vriddhāchalam",
    "value": "Vriddhāchalam"
  },
  {
    "label": "Walajapet",
    "value": "Walajapet"
  },
  {
    "label": "Wallajahbad",
    "value": "Wallajahbad"
  },
  {
    "label": "Ādilābād",
    "value": "Ādilābād"
  },
  {
    "label": "Ālampur",
    "value": "Ālampur"
  },
  {
    "label": "Andol",
    "value": "Andol"
  },
  {
    "label": "Asifābād",
    "value": "Asifābād"
  },
  {
    "label": "Bānswāda",
    "value": "Bānswāda"
  },
  {
    "label": "Bellampalli",
    "value": "Bellampalli"
  },
  {
    "label": "Bhadrāchalam",
    "value": "Bhadrāchalam"
  },
  {
    "label": "Bhadradri Kothagudem",
    "value": "Bhadradri Kothagudem"
  },
  {
    "label": "Bhaisa",
    "value": "Bhaisa"
  },
  {
    "label": "Bhongīr",
    "value": "Bhongīr"
  },
  {
    "label": "Chandūr",
    "value": "Chandūr"
  },
  {
    "label": "Chātakonda",
    "value": "Chātakonda"
  },
  {
    "label": "Dasnapur",
    "value": "Dasnapur"
  },
  {
    "label": "Devarkonda",
    "value": "Devarkonda"
  },
  {
    "label": "Dornakal",
    "value": "Dornakal"
  },
  {
    "label": "Gaddi Annaram",
    "value": "Gaddi Annaram"
  },
  {
    "label": "Gadwāl",
    "value": "Gadwāl"
  },
  {
    "label": "Ghatkesar",
    "value": "Ghatkesar"
  },
  {
    "label": "Gopālur",
    "value": "Gopālur"
  },
  {
    "label": "Hyderābād",
    "value": "Hyderābād"
  },
  {
    "label": "Jagitial",
    "value": "Jagitial"
  },
  {
    "label": "Jagtiāl",
    "value": "Jagtiāl"
  },
  {
    "label": "Jangaon",
    "value": "Jangaon"
  },
  {
    "label": "Jangoan",
    "value": "Jangoan"
  },
  {
    "label": "Jayashankar Bhupalapally",
    "value": "Jayashankar Bhupalapally"
  },
  {
    "label": "Jogulamba Gadwal",
    "value": "Jogulamba Gadwal"
  },
  {
    "label": "Kagaznāgār",
    "value": "Kagaznāgār"
  },
  {
    "label": "Kāmāreddi",
    "value": "Kāmāreddi"
  },
  {
    "label": "Kamareddy",
    "value": "Kamareddy"
  },
  {
    "label": "Karīmnagar",
    "value": "Karīmnagar"
  },
  {
    "label": "Kodār",
    "value": "Kodār"
  },
  {
    "label": "Koratla",
    "value": "Koratla"
  },
  {
    "label": "Kothāpet",
    "value": "Kothāpet"
  },
  {
    "label": "Kottagūdem",
    "value": "Kottagūdem"
  },
  {
    "label": "Kottapalli",
    "value": "Kottapalli"
  },
  {
    "label": "Kūkatpalli",
    "value": "Kūkatpalli"
  },
  {
    "label": "Kyathampalle",
    "value": "Kyathampalle"
  },
  {
    "label": "Lakshettipet",
    "value": "Lakshettipet"
  },
  {
    "label": "Lal Bahadur Nagar",
    "value": "Lal Bahadur Nagar"
  },
  {
    "label": "Mahabubabad",
    "value": "Mahabubabad"
  },
  {
    "label": "Mahbūbābād",
    "value": "Mahbūbābād"
  },
  {
    "label": "Mahbūbnagar",
    "value": "Mahbūbnagar"
  },
  {
    "label": "Malkajgiri",
    "value": "Malkajgiri"
  },
  {
    "label": "Mancherāl",
    "value": "Mancherāl"
  },
  {
    "label": "Mandamarri",
    "value": "Mandamarri"
  },
  {
    "label": "Manthani",
    "value": "Manthani"
  },
  {
    "label": "Manuguru",
    "value": "Manuguru"
  },
  {
    "label": "Medchal Malkajgiri",
    "value": "Medchal Malkajgiri"
  },
  {
    "label": "Miriālgūda",
    "value": "Miriālgūda"
  },
  {
    "label": "Nāgar Karnūl",
    "value": "Nāgar Karnūl"
  },
  {
    "label": "Nārāyanpet",
    "value": "Nārāyanpet"
  },
  {
    "label": "Nārsingi",
    "value": "Nārsingi"
  },
  {
    "label": "Nāspur",
    "value": "Nāspur"
  },
  {
    "label": "Nirmal",
    "value": "Nirmal"
  },
  {
    "label": "Nizāmābād",
    "value": "Nizāmābād"
  },
  {
    "label": "Pāloncha",
    "value": "Pāloncha"
  },
  {
    "label": "Palwancha",
    "value": "Palwancha"
  },
  {
    "label": "Peddapalli",
    "value": "Peddapalli"
  },
  {
    "label": "Quthbullapur",
    "value": "Quthbullapur"
  },
  {
    "label": "Rajanna Sircilla",
    "value": "Rajanna Sircilla"
  },
  {
    "label": "Ramagundam",
    "value": "Ramagundam"
  },
  {
    "label": "Rāmgundam",
    "value": "Rāmgundam"
  },
  {
    "label": "Rangareddi",
    "value": "Rangareddi"
  },
  {
    "label": "Sadāseopet",
    "value": "Sadāseopet"
  },
  {
    "label": "Sangāreddi",
    "value": "Sangāreddi"
  },
  {
    "label": "Sathupalli",
    "value": "Sathupalli"
  },
  {
    "label": "Serilingampalle",
    "value": "Serilingampalle"
  },
  {
    "label": "Siddipet",
    "value": "Siddipet"
  },
  {
    "label": "Singāpur",
    "value": "Singāpur"
  },
  {
    "label": "Sirpur",
    "value": "Sirpur"
  },
  {
    "label": "Sirsilla",
    "value": "Sirsilla"
  },
  {
    "label": "Srīrāmnagar",
    "value": "Srīrāmnagar"
  },
  {
    "label": "Suriāpet",
    "value": "Suriāpet"
  },
  {
    "label": "Tāndūr",
    "value": "Tāndūr"
  },
  {
    "label": "Uppal Kalan",
    "value": "Uppal Kalan"
  },
  {
    "label": "Vemalwāda",
    "value": "Vemalwāda"
  },
  {
    "label": "Vikārābād",
    "value": "Vikārābād"
  },
  {
    "label": "Wanparti",
    "value": "Wanparti"
  },
  {
    "label": "Yellandu",
    "value": "Yellandu"
  },
  {
    "label": "Zahirābād",
    "value": "Zahirābād"
  },
  {
    "label": "Āmbāsa",
    "value": "Āmbāsa"
  },
  {
    "label": "Barjala",
    "value": "Barjala"
  },
  {
    "label": "Belonia",
    "value": "Belonia"
  },
  {
    "label": "Dhalai",
    "value": "Dhalai"
  },
  {
    "label": "Dharmanagar",
    "value": "Dharmanagar"
  },
  {
    "label": "Gomati",
    "value": "Gomati"
  },
  {
    "label": "Kailāshahar",
    "value": "Kailāshahar"
  },
  {
    "label": "Khowai",
    "value": "Khowai"
  },
  {
    "label": "North Tripura",
    "value": "North Tripura"
  },
  {
    "label": "Rānīr Bāzār",
    "value": "Rānīr Bāzār"
  },
  {
    "label": "Sabrūm",
    "value": "Sabrūm"
  },
  {
    "label": "Sonāmura",
    "value": "Sonāmura"
  },
  {
    "label": "South Tripura",
    "value": "South Tripura"
  },
  {
    "label": "Unakoti",
    "value": "Unakoti"
  },
  {
    "label": "West Tripura",
    "value": "West Tripura"
  },
  {
    "label": "Achhnera",
    "value": "Achhnera"
  },
  {
    "label": "Afzalgarh",
    "value": "Afzalgarh"
  },
  {
    "label": "Āgra",
    "value": "Āgra"
  },
  {
    "label": "Ahraura",
    "value": "Ahraura"
  },
  {
    "label": "Ajodhya",
    "value": "Ajodhya"
  },
  {
    "label": "Akbarpur",
    "value": "Akbarpur"
  },
  {
    "label": "Alīganj",
    "value": "Alīganj"
  },
  {
    "label": "Alīgarh",
    "value": "Alīgarh"
  },
  {
    "label": "Allahābād",
    "value": "Allahābād"
  },
  {
    "label": "Allāhganj",
    "value": "Allāhganj"
  },
  {
    "label": "Amānpur",
    "value": "Amānpur"
  },
  {
    "label": "Ambahta",
    "value": "Ambahta"
  },
  {
    "label": "Ambedkar Nagar",
    "value": "Ambedkar Nagar"
  },
  {
    "label": "Amethi",
    "value": "Amethi"
  },
  {
    "label": "Amethī",
    "value": "Amethī"
  },
  {
    "label": "Amroha",
    "value": "Amroha"
  },
  {
    "label": "Anandnagar",
    "value": "Anandnagar"
  },
  {
    "label": "Antu",
    "value": "Antu"
  },
  {
    "label": "Anūpshahr",
    "value": "Anūpshahr"
  },
  {
    "label": "Aonla",
    "value": "Aonla"
  },
  {
    "label": "Atarra",
    "value": "Atarra"
  },
  {
    "label": "Atraulī",
    "value": "Atraulī"
  },
  {
    "label": "Atraulia",
    "value": "Atraulia"
  },
  {
    "label": "Auraiya",
    "value": "Auraiya"
  },
  {
    "label": "Aurās",
    "value": "Aurās"
  },
  {
    "label": "Āzamgarh",
    "value": "Āzamgarh"
  },
  {
    "label": "Baberu",
    "value": "Baberu"
  },
  {
    "label": "Babīna",
    "value": "Babīna"
  },
  {
    "label": "Babrāla",
    "value": "Babrāla"
  },
  {
    "label": "Babugarh",
    "value": "Babugarh"
  },
  {
    "label": "Bachhraon",
    "value": "Bachhraon"
  },
  {
    "label": "Bachhrāwān",
    "value": "Bachhrāwān"
  },
  {
    "label": "Baghpat",
    "value": "Baghpat"
  },
  {
    "label": "Bāghpat",
    "value": "Bāghpat"
  },
  {
    "label": "Bāh",
    "value": "Bāh"
  },
  {
    "label": "Baheri",
    "value": "Baheri"
  },
  {
    "label": "Bahjoi",
    "value": "Bahjoi"
  },
  {
    "label": "Bahraich",
    "value": "Bahraich"
  },
  {
    "label": "Bahraigh",
    "value": "Bahraigh"
  },
  {
    "label": "Bahsūma",
    "value": "Bahsūma"
  },
  {
    "label": "Bahua",
    "value": "Bahua"
  },
  {
    "label": "Bājna",
    "value": "Bājna"
  },
  {
    "label": "Bakewar",
    "value": "Bakewar"
  },
  {
    "label": "Baldev",
    "value": "Baldev"
  },
  {
    "label": "Ballia",
    "value": "Ballia"
  },
  {
    "label": "Balrampur",
    "value": "Balrampur"
  },
  {
    "label": "Balrāmpur",
    "value": "Balrāmpur"
  },
  {
    "label": "Banat",
    "value": "Banat"
  },
  {
    "label": "Banbasa",
    "value": "Banbasa"
  },
  {
    "label": "Bānda",
    "value": "Bānda"
  },
  {
    "label": "Bāngarmau",
    "value": "Bāngarmau"
  },
  {
    "label": "Bānsdīh",
    "value": "Bānsdīh"
  },
  {
    "label": "Bānsgāon",
    "value": "Bānsgāon"
  },
  {
    "label": "Bānsi",
    "value": "Bānsi"
  },
  {
    "label": "Bāra Banki",
    "value": "Bāra Banki"
  },
  {
    "label": "Barāgaon",
    "value": "Barāgaon"
  },
  {
    "label": "Baraut",
    "value": "Baraut"
  },
  {
    "label": "Barkhera Kalān",
    "value": "Barkhera Kalān"
  },
  {
    "label": "Barsāna",
    "value": "Barsāna"
  },
  {
    "label": "Bastī",
    "value": "Bastī"
  },
  {
    "label": "Behat",
    "value": "Behat"
  },
  {
    "label": "Benīganj",
    "value": "Benīganj"
  },
  {
    "label": "Beswān",
    "value": "Beswān"
  },
  {
    "label": "Bewar",
    "value": "Bewar"
  },
  {
    "label": "Bhagwantnagar",
    "value": "Bhagwantnagar"
  },
  {
    "label": "Bharthana",
    "value": "Bharthana"
  },
  {
    "label": "Bharwāri",
    "value": "Bharwāri"
  },
  {
    "label": "Bhinga",
    "value": "Bhinga"
  },
  {
    "label": "Bhongaon",
    "value": "Bhongaon"
  },
  {
    "label": "Bidhūna",
    "value": "Bidhūna"
  },
  {
    "label": "Bīghāpur Khurd",
    "value": "Bīghāpur Khurd"
  },
  {
    "label": "Bijnor",
    "value": "Bijnor"
  },
  {
    "label": "Bīkāpur",
    "value": "Bīkāpur"
  },
  {
    "label": "Bilāri",
    "value": "Bilāri"
  },
  {
    "label": "Bilariāganj",
    "value": "Bilariāganj"
  },
  {
    "label": "Bīlāspur",
    "value": "Bīlāspur"
  },
  {
    "label": "Bilgrām",
    "value": "Bilgrām"
  },
  {
    "label": "Bilhaur",
    "value": "Bilhaur"
  },
  {
    "label": "Bilsanda",
    "value": "Bilsanda"
  },
  {
    "label": "Bilsi",
    "value": "Bilsi"
  },
  {
    "label": "Bilthra",
    "value": "Bilthra"
  },
  {
    "label": "Bindki",
    "value": "Bindki"
  },
  {
    "label": "Bīsalpur",
    "value": "Bīsalpur"
  },
  {
    "label": "Bisauli",
    "value": "Bisauli"
  },
  {
    "label": "Bisenda Buzurg",
    "value": "Bisenda Buzurg"
  },
  {
    "label": "Bishunpur Urf Mahārājganj",
    "value": "Bishunpur Urf Mahārājganj"
  },
  {
    "label": "Biswān",
    "value": "Biswān"
  },
  {
    "label": "Bithūr",
    "value": "Bithūr"
  },
  {
    "label": "Budhāna",
    "value": "Budhāna"
  },
  {
    "label": "Captainganj",
    "value": "Captainganj"
  },
  {
    "label": "Chail",
    "value": "Chail"
  },
  {
    "label": "Chakia",
    "value": "Chakia"
  },
  {
    "label": "Chandauli District",
    "value": "Chandauli District"
  },
  {
    "label": "Chāndpur",
    "value": "Chāndpur"
  },
  {
    "label": "Chanduasi",
    "value": "Chanduasi"
  },
  {
    "label": "Charkhāri",
    "value": "Charkhāri"
  },
  {
    "label": "Charthāwal",
    "value": "Charthāwal"
  },
  {
    "label": "Chhaprauli",
    "value": "Chhaprauli"
  },
  {
    "label": "Chharra",
    "value": "Chharra"
  },
  {
    "label": "Chhāta",
    "value": "Chhāta"
  },
  {
    "label": "Chhibrāmau",
    "value": "Chhibrāmau"
  },
  {
    "label": "Chhutmalpur",
    "value": "Chhutmalpur"
  },
  {
    "label": "Chillupār",
    "value": "Chillupār"
  },
  {
    "label": "Chirgaon",
    "value": "Chirgaon"
  },
  {
    "label": "Chitrakoot",
    "value": "Chitrakoot"
  },
  {
    "label": "Chopan",
    "value": "Chopan"
  },
  {
    "label": "Chunār",
    "value": "Chunār"
  },
  {
    "label": "Colonelganj",
    "value": "Colonelganj"
  },
  {
    "label": "Dādri",
    "value": "Dādri"
  },
  {
    "label": "Dalmau",
    "value": "Dalmau"
  },
  {
    "label": "Dankaur",
    "value": "Dankaur"
  },
  {
    "label": "Dāsna",
    "value": "Dāsna"
  },
  {
    "label": "Dātāganj",
    "value": "Dātāganj"
  },
  {
    "label": "Daurāla",
    "value": "Daurāla"
  },
  {
    "label": "Dayāl Bāgh",
    "value": "Dayāl Bāgh"
  },
  {
    "label": "Deoband",
    "value": "Deoband"
  },
  {
    "label": "Deoraniān",
    "value": "Deoraniān"
  },
  {
    "label": "Dewā",
    "value": "Dewā"
  },
  {
    "label": "Dhāmpur",
    "value": "Dhāmpur"
  },
  {
    "label": "Dhanaura",
    "value": "Dhanaura"
  },
  {
    "label": "Dhaurahra",
    "value": "Dhaurahra"
  },
  {
    "label": "Dibai",
    "value": "Dibai"
  },
  {
    "label": "Dohrighāt",
    "value": "Dohrighāt"
  },
  {
    "label": "Dostpur",
    "value": "Dostpur"
  },
  {
    "label": "Dūdhi",
    "value": "Dūdhi"
  },
  {
    "label": "Etah",
    "value": "Etah"
  },
  {
    "label": "Etāwah",
    "value": "Etāwah"
  },
  {
    "label": "Faizābād",
    "value": "Faizābād"
  },
  {
    "label": "Farah",
    "value": "Farah"
  },
  {
    "label": "Farīdnagar",
    "value": "Farīdnagar"
  },
  {
    "label": "Farīdpur",
    "value": "Farīdpur"
  },
  {
    "label": "Farrukhābād",
    "value": "Farrukhābād"
  },
  {
    "label": "Fatehganj West",
    "value": "Fatehganj West"
  },
  {
    "label": "Fatehpur Chaurāsi",
    "value": "Fatehpur Chaurāsi"
  },
  {
    "label": "Fatehpur Sīkri",
    "value": "Fatehpur Sīkri"
  },
  {
    "label": "Fīrozābād",
    "value": "Fīrozābād"
  },
  {
    "label": "Fyzābād",
    "value": "Fyzābād"
  },
  {
    "label": "Gajraula",
    "value": "Gajraula"
  },
  {
    "label": "Gangoh",
    "value": "Gangoh"
  },
  {
    "label": "Ganj Dundwāra",
    "value": "Ganj Dundwāra"
  },
  {
    "label": "Ganj Murādābād",
    "value": "Ganj Murādābād"
  },
  {
    "label": "Garautha",
    "value": "Garautha"
  },
  {
    "label": "Garhi Pūkhta",
    "value": "Garhi Pūkhta"
  },
  {
    "label": "Garhmuktesar",
    "value": "Garhmuktesar"
  },
  {
    "label": "Gautam Buddha Nagar",
    "value": "Gautam Buddha Nagar"
  },
  {
    "label": "Gawān",
    "value": "Gawān"
  },
  {
    "label": "Ghātampur",
    "value": "Ghātampur"
  },
  {
    "label": "Ghāziābād",
    "value": "Ghāziābād"
  },
  {
    "label": "Ghazīpur",
    "value": "Ghazīpur"
  },
  {
    "label": "Ghāzīpur",
    "value": "Ghāzīpur"
  },
  {
    "label": "Ghiror",
    "value": "Ghiror"
  },
  {
    "label": "Ghorāwal",
    "value": "Ghorāwal"
  },
  {
    "label": "Ghosī",
    "value": "Ghosī"
  },
  {
    "label": "Gohānd",
    "value": "Gohānd"
  },
  {
    "label": "Gokul",
    "value": "Gokul"
  },
  {
    "label": "Gola Bāzār",
    "value": "Gola Bāzār"
  },
  {
    "label": "Gola Gokarannāth",
    "value": "Gola Gokarannāth"
  },
  {
    "label": "Gondā City",
    "value": "Gondā City"
  },
  {
    "label": "Gopāmau",
    "value": "Gopāmau"
  },
  {
    "label": "Goshāinganj",
    "value": "Goshāinganj"
  },
  {
    "label": "Goshāīnganj",
    "value": "Goshāīnganj"
  },
  {
    "label": "Govardhan",
    "value": "Govardhan"
  },
  {
    "label": "Greater Noida",
    "value": "Greater Noida"
  },
  {
    "label": "Gulāothi",
    "value": "Gulāothi"
  },
  {
    "label": "Gunnaur",
    "value": "Gunnaur"
  },
  {
    "label": "Gursahāiganj",
    "value": "Gursahāiganj"
  },
  {
    "label": "Gursarāi",
    "value": "Gursarāi"
  },
  {
    "label": "Gyānpur",
    "value": "Gyānpur"
  },
  {
    "label": "Haldaur",
    "value": "Haldaur"
  },
  {
    "label": "Handiā",
    "value": "Handiā"
  },
  {
    "label": "Hāpur",
    "value": "Hāpur"
  },
  {
    "label": "Haraiya",
    "value": "Haraiya"
  },
  {
    "label": "Hardoi",
    "value": "Hardoi"
  },
  {
    "label": "Hardoī",
    "value": "Hardoī"
  },
  {
    "label": "Harduāganj",
    "value": "Harduāganj"
  },
  {
    "label": "Hastināpur",
    "value": "Hastināpur"
  },
  {
    "label": "Hātā",
    "value": "Hātā"
  },
  {
    "label": "Hāthras",
    "value": "Hāthras"
  },
  {
    "label": "Iglās",
    "value": "Iglās"
  },
  {
    "label": "Ikauna",
    "value": "Ikauna"
  },
  {
    "label": "Indergarh",
    "value": "Indergarh"
  },
  {
    "label": "Islāmnagar",
    "value": "Islāmnagar"
  },
  {
    "label": "Itaunja",
    "value": "Itaunja"
  },
  {
    "label": "Itimādpur",
    "value": "Itimādpur"
  },
  {
    "label": "Jagdīshpur",
    "value": "Jagdīshpur"
  },
  {
    "label": "Jagnair",
    "value": "Jagnair"
  },
  {
    "label": "Jahāngīrābād",
    "value": "Jahāngīrābād"
  },
  {
    "label": "Jahāngīrpur",
    "value": "Jahāngīrpur"
  },
  {
    "label": "Jainpur",
    "value": "Jainpur"
  },
  {
    "label": "Jais",
    "value": "Jais"
  },
  {
    "label": "Jalālābad",
    "value": "Jalālābad"
  },
  {
    "label": "Jalālābād",
    "value": "Jalālābād"
  },
  {
    "label": "Jalālī",
    "value": "Jalālī"
  },
  {
    "label": "Jalālpur",
    "value": "Jalālpur"
  },
  {
    "label": "Jālaun",
    "value": "Jālaun"
  },
  {
    "label": "Jānsath",
    "value": "Jānsath"
  },
  {
    "label": "Jarwal",
    "value": "Jarwal"
  },
  {
    "label": "Jasrāna",
    "value": "Jasrāna"
  },
  {
    "label": "Jaswantnagar",
    "value": "Jaswantnagar"
  },
  {
    "label": "Jewar",
    "value": "Jewar"
  },
  {
    "label": "Jhālu",
    "value": "Jhālu"
  },
  {
    "label": "Jhānsi",
    "value": "Jhānsi"
  },
  {
    "label": "Jhīnjhak",
    "value": "Jhīnjhak"
  },
  {
    "label": "Jhinjhāna",
    "value": "Jhinjhāna"
  },
  {
    "label": "Jhūsi",
    "value": "Jhūsi"
  },
  {
    "label": "Jyotiba Phule Nagar",
    "value": "Jyotiba Phule Nagar"
  },
  {
    "label": "Kabrāi",
    "value": "Kabrāi"
  },
  {
    "label": "Kachhwa",
    "value": "Kachhwa"
  },
  {
    "label": "Kadaura",
    "value": "Kadaura"
  },
  {
    "label": "Kādīpur",
    "value": "Kādīpur"
  },
  {
    "label": "Kairāna",
    "value": "Kairāna"
  },
  {
    "label": "Kākori",
    "value": "Kākori"
  },
  {
    "label": "Kakrāla",
    "value": "Kakrāla"
  },
  {
    "label": "Kālīnagar",
    "value": "Kālīnagar"
  },
  {
    "label": "Kālpi",
    "value": "Kālpi"
  },
  {
    "label": "Kamalganj",
    "value": "Kamalganj"
  },
  {
    "label": "Kampil",
    "value": "Kampil"
  },
  {
    "label": "Kāndhla",
    "value": "Kāndhla"
  },
  {
    "label": "Kannauj",
    "value": "Kannauj"
  },
  {
    "label": "Kānpur",
    "value": "Kānpur"
  },
  {
    "label": "Kanpur Dehat",
    "value": "Kanpur Dehat"
  },
  {
    "label": "Kānt",
    "value": "Kānt"
  },
  {
    "label": "Kānth",
    "value": "Kānth"
  },
  {
    "label": "Karārī",
    "value": "Karārī"
  },
  {
    "label": "Karhal",
    "value": "Karhal"
  },
  {
    "label": "Kāsganj",
    "value": "Kāsganj"
  },
  {
    "label": "Kaushambi District",
    "value": "Kaushambi District"
  },
  {
    "label": "Kemrī",
    "value": "Kemrī"
  },
  {
    "label": "Khada",
    "value": "Khada"
  },
  {
    "label": "Khāga",
    "value": "Khāga"
  },
  {
    "label": "Khair",
    "value": "Khair"
  },
  {
    "label": "Khairābād",
    "value": "Khairābād"
  },
  {
    "label": "Khalīlābād",
    "value": "Khalīlābād"
  },
  {
    "label": "Kharela",
    "value": "Kharela"
  },
  {
    "label": "Khargupur",
    "value": "Khargupur"
  },
  {
    "label": "Khekra",
    "value": "Khekra"
  },
  {
    "label": "Khudāganj",
    "value": "Khudāganj"
  },
  {
    "label": "Khūtār",
    "value": "Khūtār"
  },
  {
    "label": "Kirākat",
    "value": "Kirākat"
  },
  {
    "label": "Kiraoli",
    "value": "Kiraoli"
  },
  {
    "label": "Kīratpur",
    "value": "Kīratpur"
  },
  {
    "label": "Kishanpur",
    "value": "Kishanpur"
  },
  {
    "label": "Kishni",
    "value": "Kishni"
  },
  {
    "label": "Kithor",
    "value": "Kithor"
  },
  {
    "label": "Konch",
    "value": "Konch"
  },
  {
    "label": "Kopāganj",
    "value": "Kopāganj"
  },
  {
    "label": "Kosi",
    "value": "Kosi"
  },
  {
    "label": "Kotra",
    "value": "Kotra"
  },
  {
    "label": "Kulpahār",
    "value": "Kulpahār"
  },
  {
    "label": "Kunda",
    "value": "Kunda"
  },
  {
    "label": "Kundarkhi",
    "value": "Kundarkhi"
  },
  {
    "label": "Kurāra",
    "value": "Kurāra"
  },
  {
    "label": "Kushinagar",
    "value": "Kushinagar"
  },
  {
    "label": "Lāharpur",
    "value": "Lāharpur"
  },
  {
    "label": "Lakhīmpur",
    "value": "Lakhīmpur"
  },
  {
    "label": "Lakhnā",
    "value": "Lakhnā"
  },
  {
    "label": "Lalitpur",
    "value": "Lalitpur"
  },
  {
    "label": "Lar",
    "value": "Lar"
  },
  {
    "label": "Lāwar Khās",
    "value": "Lāwar Khās"
  },
  {
    "label": "Loni",
    "value": "Loni"
  },
  {
    "label": "Lucknow District",
    "value": "Lucknow District"
  },
  {
    "label": "Machhlīshahr",
    "value": "Machhlīshahr"
  },
  {
    "label": "Mādhoganj",
    "value": "Mādhoganj"
  },
  {
    "label": "Mādhogarh",
    "value": "Mādhogarh"
  },
  {
    "label": "Maghar",
    "value": "Maghar"
  },
  {
    "label": "Mahāban",
    "value": "Mahāban"
  },
  {
    "label": "Mahārāganj",
    "value": "Mahārāganj"
  },
  {
    "label": "Maharajganj",
    "value": "Maharajganj"
  },
  {
    "label": "Mahārājganj",
    "value": "Mahārājganj"
  },
  {
    "label": "Mahmudābād",
    "value": "Mahmudābād"
  },
  {
    "label": "Mahoba",
    "value": "Mahoba"
  },
  {
    "label": "Mahobā",
    "value": "Mahobā"
  },
  {
    "label": "Maholi",
    "value": "Maholi"
  },
  {
    "label": "Mahroni",
    "value": "Mahroni"
  },
  {
    "label": "Mailāni",
    "value": "Mailāni"
  },
  {
    "label": "Mainpuri",
    "value": "Mainpuri"
  },
  {
    "label": "Malīhābād",
    "value": "Malīhābād"
  },
  {
    "label": "Maniar",
    "value": "Maniar"
  },
  {
    "label": "Mānikpur",
    "value": "Mānikpur"
  },
  {
    "label": "Manjhanpur",
    "value": "Manjhanpur"
  },
  {
    "label": "Mankāpur",
    "value": "Mankāpur"
  },
  {
    "label": "Mārahra",
    "value": "Mārahra"
  },
  {
    "label": "Mariāhu",
    "value": "Mariāhu"
  },
  {
    "label": "Mataundh",
    "value": "Mataundh"
  },
  {
    "label": "Mau Aimma",
    "value": "Mau Aimma"
  },
  {
    "label": "Maudaha",
    "value": "Maudaha"
  },
  {
    "label": "Maurānwān",
    "value": "Maurānwān"
  },
  {
    "label": "Mawāna",
    "value": "Mawāna"
  },
  {
    "label": "Mehnagar",
    "value": "Mehnagar"
  },
  {
    "label": "Mehndāwal",
    "value": "Mehndāwal"
  },
  {
    "label": "Milak",
    "value": "Milak"
  },
  {
    "label": "Mīrānpur",
    "value": "Mīrānpur"
  },
  {
    "label": "Mīrānpur Katra",
    "value": "Mīrānpur Katra"
  },
  {
    "label": "Mīrganj",
    "value": "Mīrganj"
  },
  {
    "label": "Mirzāpur",
    "value": "Mirzāpur"
  },
  {
    "label": "Misrikh",
    "value": "Misrikh"
  },
  {
    "label": "Mohān",
    "value": "Mohān"
  },
  {
    "label": "Mohanpur",
    "value": "Mohanpur"
  },
  {
    "label": "Morādābād",
    "value": "Morādābād"
  },
  {
    "label": "Moth",
    "value": "Moth"
  },
  {
    "label": "Mubārakpur",
    "value": "Mubārakpur"
  },
  {
    "label": "Mughal Sarāi",
    "value": "Mughal Sarāi"
  },
  {
    "label": "Muhammadābād",
    "value": "Muhammadābād"
  },
  {
    "label": "Murādnagar",
    "value": "Murādnagar"
  },
  {
    "label": "Mursān",
    "value": "Mursān"
  },
  {
    "label": "Musāfir-Khāna",
    "value": "Musāfir-Khāna"
  },
  {
    "label": "Nadīgaon",
    "value": "Nadīgaon"
  },
  {
    "label": "Nagīna",
    "value": "Nagīna"
  },
  {
    "label": "Nagrām",
    "value": "Nagrām"
  },
  {
    "label": "Najībābād",
    "value": "Najībābād"
  },
  {
    "label": "Nakūr",
    "value": "Nakūr"
  },
  {
    "label": "Nanauta",
    "value": "Nanauta"
  },
  {
    "label": "Nandgaon",
    "value": "Nandgaon"
  },
  {
    "label": "Nānpāra",
    "value": "Nānpāra"
  },
  {
    "label": "Narauli",
    "value": "Narauli"
  },
  {
    "label": "Naraura",
    "value": "Naraura"
  },
  {
    "label": "Nautanwa",
    "value": "Nautanwa"
  },
  {
    "label": "Nawābganj",
    "value": "Nawābganj"
  },
  {
    "label": "Nichlaul",
    "value": "Nichlaul"
  },
  {
    "label": "Nihtaur",
    "value": "Nihtaur"
  },
  {
    "label": "Niwāri",
    "value": "Niwāri"
  },
  {
    "label": "Nūrpur",
    "value": "Nūrpur"
  },
  {
    "label": "Obra",
    "value": "Obra"
  },
  {
    "label": "Oran",
    "value": "Oran"
  },
  {
    "label": "Pachperwa",
    "value": "Pachperwa"
  },
  {
    "label": "Pahāsu",
    "value": "Pahāsu"
  },
  {
    "label": "Paliā Kalān",
    "value": "Paliā Kalān"
  },
  {
    "label": "Pārīchha",
    "value": "Pārīchha"
  },
  {
    "label": "Parīchhatgarh",
    "value": "Parīchhatgarh"
  },
  {
    "label": "Parshādepur",
    "value": "Parshādepur"
  },
  {
    "label": "Patiāli",
    "value": "Patiāli"
  },
  {
    "label": "Pawāyan",
    "value": "Pawāyan"
  },
  {
    "label": "Phalauda",
    "value": "Phalauda"
  },
  {
    "label": "Phaphūnd",
    "value": "Phaphūnd"
  },
  {
    "label": "Pharihā",
    "value": "Pharihā"
  },
  {
    "label": "Phulpur",
    "value": "Phulpur"
  },
  {
    "label": "Phūlpur",
    "value": "Phūlpur"
  },
  {
    "label": "Pihānī",
    "value": "Pihānī"
  },
  {
    "label": "Pīlibhīt",
    "value": "Pīlibhīt"
  },
  {
    "label": "Pīlībhīt",
    "value": "Pīlībhīt"
  },
  {
    "label": "Pilkhua",
    "value": "Pilkhua"
  },
  {
    "label": "Pināhat",
    "value": "Pināhat"
  },
  {
    "label": "Pipraich",
    "value": "Pipraich"
  },
  {
    "label": "Pukhrāyān",
    "value": "Pukhrāyān"
  },
  {
    "label": "Pūranpur",
    "value": "Pūranpur"
  },
  {
    "label": "Purwā",
    "value": "Purwā"
  },
  {
    "label": "Rabūpura",
    "value": "Rabūpura"
  },
  {
    "label": "Rādhākund",
    "value": "Rādhākund"
  },
  {
    "label": "Rāe Bareli",
    "value": "Rāe Bareli"
  },
  {
    "label": "Raebareli",
    "value": "Raebareli"
  },
  {
    "label": "Rāmkola",
    "value": "Rāmkola"
  },
  {
    "label": "Rānīpur",
    "value": "Rānīpur"
  },
  {
    "label": "Rasrā",
    "value": "Rasrā"
  },
  {
    "label": "Rasūlābād",
    "value": "Rasūlābād"
  },
  {
    "label": "Rāth",
    "value": "Rāth"
  },
  {
    "label": "Rāya",
    "value": "Rāya"
  },
  {
    "label": "Renukūt",
    "value": "Renukūt"
  },
  {
    "label": "Reoti",
    "value": "Reoti"
  },
  {
    "label": "Richha",
    "value": "Richha"
  },
  {
    "label": "Robertsganj",
    "value": "Robertsganj"
  },
  {
    "label": "Rūdarpur",
    "value": "Rūdarpur"
  },
  {
    "label": "Rura",
    "value": "Rura"
  },
  {
    "label": "Sadābād",
    "value": "Sadābād"
  },
  {
    "label": "Sādāt",
    "value": "Sādāt"
  },
  {
    "label": "Safīpur",
    "value": "Safīpur"
  },
  {
    "label": "Sahāranpur",
    "value": "Sahāranpur"
  },
  {
    "label": "Sahaspur",
    "value": "Sahaspur"
  },
  {
    "label": "Sahaswān",
    "value": "Sahaswān"
  },
  {
    "label": "Sahāwar",
    "value": "Sahāwar"
  },
  {
    "label": "Saidpur",
    "value": "Saidpur"
  },
  {
    "label": "Sakīt",
    "value": "Sakīt"
  },
  {
    "label": "Salon",
    "value": "Salon"
  },
  {
    "label": "Sambhal",
    "value": "Sambhal"
  },
  {
    "label": "Samthar",
    "value": "Samthar"
  },
  {
    "label": "Sāndi",
    "value": "Sāndi"
  },
  {
    "label": "Sandīla",
    "value": "Sandīla"
  },
  {
    "label": "Sant Kabir Nagar",
    "value": "Sant Kabir Nagar"
  },
  {
    "label": "Sant Ravi Das Nagar",
    "value": "Sant Ravi Das Nagar"
  },
  {
    "label": "Sarāi Ākil",
    "value": "Sarāi Ākil"
  },
  {
    "label": "Sarai Ekdil",
    "value": "Sarai Ekdil"
  },
  {
    "label": "Sarāi Mīr",
    "value": "Sarāi Mīr"
  },
  {
    "label": "Sarauli",
    "value": "Sarauli"
  },
  {
    "label": "Sardhana",
    "value": "Sardhana"
  },
  {
    "label": "Sarīla",
    "value": "Sarīla"
  },
  {
    "label": "Sāsni",
    "value": "Sāsni"
  },
  {
    "label": "Satrikh",
    "value": "Satrikh"
  },
  {
    "label": "Saurikh",
    "value": "Saurikh"
  },
  {
    "label": "Sector",
    "value": "Sector"
  },
  {
    "label": "Seohāra",
    "value": "Seohāra"
  },
  {
    "label": "Shāhganj",
    "value": "Shāhganj"
  },
  {
    "label": "Shāhi",
    "value": "Shāhi"
  },
  {
    "label": "Shāhjahānpur",
    "value": "Shāhjahānpur"
  },
  {
    "label": "Shāhjānpur",
    "value": "Shāhjānpur"
  },
  {
    "label": "Shāmli",
    "value": "Shāmli"
  },
  {
    "label": "Shamsābād",
    "value": "Shamsābād"
  },
  {
    "label": "Shankargarh",
    "value": "Shankargarh"
  },
  {
    "label": "Shergarh",
    "value": "Shergarh"
  },
  {
    "label": "Sherkot",
    "value": "Sherkot"
  },
  {
    "label": "Shikārpūr",
    "value": "Shikārpūr"
  },
  {
    "label": "Shikohābād",
    "value": "Shikohābād"
  },
  {
    "label": "Shīshgarh",
    "value": "Shīshgarh"
  },
  {
    "label": "Shrawasti",
    "value": "Shrawasti"
  },
  {
    "label": "Siddharthnagar",
    "value": "Siddharthnagar"
  },
  {
    "label": "Sidhaulī",
    "value": "Sidhaulī"
  },
  {
    "label": "Sidhpura",
    "value": "Sidhpura"
  },
  {
    "label": "Sikandarābād",
    "value": "Sikandarābād"
  },
  {
    "label": "Sikandarpur",
    "value": "Sikandarpur"
  },
  {
    "label": "Sikandra",
    "value": "Sikandra"
  },
  {
    "label": "Sikandra Rao",
    "value": "Sikandra Rao"
  },
  {
    "label": "Sirāthu",
    "value": "Sirāthu"
  },
  {
    "label": "Sirsā",
    "value": "Sirsā"
  },
  {
    "label": "Sirsāganj",
    "value": "Sirsāganj"
  },
  {
    "label": "Sisauli",
    "value": "Sisauli"
  },
  {
    "label": "Siswā Bāzār",
    "value": "Siswā Bāzār"
  },
  {
    "label": "Sītāpur",
    "value": "Sītāpur"
  },
  {
    "label": "Sonbhadra",
    "value": "Sonbhadra"
  },
  {
    "label": "Soron",
    "value": "Soron"
  },
  {
    "label": "Suār",
    "value": "Suār"
  },
  {
    "label": "Suriānwān",
    "value": "Suriānwān"
  },
  {
    "label": "Tājpur",
    "value": "Tājpur"
  },
  {
    "label": "Tālbahat",
    "value": "Tālbahat"
  },
  {
    "label": "Tālgrām",
    "value": "Tālgrām"
  },
  {
    "label": "Tānda",
    "value": "Tānda"
  },
  {
    "label": "Tāndā",
    "value": "Tāndā"
  },
  {
    "label": "Thakurdwara",
    "value": "Thakurdwara"
  },
  {
    "label": "Thāna Bhawan",
    "value": "Thāna Bhawan"
  },
  {
    "label": "Tikaitnagar",
    "value": "Tikaitnagar"
  },
  {
    "label": "Tīkri",
    "value": "Tīkri"
  },
  {
    "label": "Tilhar",
    "value": "Tilhar"
  },
  {
    "label": "Tindwāri",
    "value": "Tindwāri"
  },
  {
    "label": "Titron",
    "value": "Titron"
  },
  {
    "label": "Tori-Fatehpur",
    "value": "Tori-Fatehpur"
  },
  {
    "label": "Tulsīpur",
    "value": "Tulsīpur"
  },
  {
    "label": "Tūndla",
    "value": "Tūndla"
  },
  {
    "label": "Ugu",
    "value": "Ugu"
  },
  {
    "label": "Ujhāni",
    "value": "Ujhāni"
  },
  {
    "label": "Ūn",
    "value": "Ūn"
  },
  {
    "label": "Unnāo",
    "value": "Unnāo"
  },
  {
    "label": "Usehat",
    "value": "Usehat"
  },
  {
    "label": "Utraula",
    "value": "Utraula"
  },
  {
    "label": "Vārānasi",
    "value": "Vārānasi"
  },
  {
    "label": "Vrindāvan",
    "value": "Vrindāvan"
  },
  {
    "label": "Wazīrganj",
    "value": "Wazīrganj"
  },
  {
    "label": "Zafarābād",
    "value": "Zafarābād"
  },
  {
    "label": "Zaidpur",
    "value": "Zaidpur"
  },
  {
    "label": "Zamānia",
    "value": "Zamānia"
  },
  {
    "label": "Bageshwar",
    "value": "Bageshwar"
  },
  {
    "label": "Bāgeshwar",
    "value": "Bāgeshwar"
  },
  {
    "label": "Barkot",
    "value": "Barkot"
  },
  {
    "label": "Bāzpur",
    "value": "Bāzpur"
  },
  {
    "label": "Bhīm Tāl",
    "value": "Bhīm Tāl"
  },
  {
    "label": "Bhowali",
    "value": "Bhowali"
  },
  {
    "label": "Birbhaddar",
    "value": "Birbhaddar"
  },
  {
    "label": "Chakrāta",
    "value": "Chakrāta"
  },
  {
    "label": "Chamoli",
    "value": "Chamoli"
  },
  {
    "label": "Champawat",
    "value": "Champawat"
  },
  {
    "label": "Clement Town",
    "value": "Clement Town"
  },
  {
    "label": "Dehra Dūn",
    "value": "Dehra Dūn"
  },
  {
    "label": "Devaprayāg",
    "value": "Devaprayāg"
  },
  {
    "label": "Dhārchula",
    "value": "Dhārchula"
  },
  {
    "label": "Doiwāla",
    "value": "Doiwāla"
  },
  {
    "label": "Dugadda",
    "value": "Dugadda"
  },
  {
    "label": "Dwārāhāt",
    "value": "Dwārāhāt"
  },
  {
    "label": "Garhwāl",
    "value": "Garhwāl"
  },
  {
    "label": "Harbatpur",
    "value": "Harbatpur"
  },
  {
    "label": "Joshīmath",
    "value": "Joshīmath"
  },
  {
    "label": "Kālādhūngi",
    "value": "Kālādhūngi"
  },
  {
    "label": "Kālāgarh Project Colony",
    "value": "Kālāgarh Project Colony"
  },
  {
    "label": "Khatīma",
    "value": "Khatīma"
  },
  {
    "label": "Kichha",
    "value": "Kichha"
  },
  {
    "label": "Kotdwāra",
    "value": "Kotdwāra"
  },
  {
    "label": "Laksar",
    "value": "Laksar"
  },
  {
    "label": "Lansdowne",
    "value": "Lansdowne"
  },
  {
    "label": "Lohaghāt",
    "value": "Lohaghāt"
  },
  {
    "label": "Manglaur",
    "value": "Manglaur"
  },
  {
    "label": "Naini Tāl",
    "value": "Naini Tāl"
  },
  {
    "label": "Narendranagar",
    "value": "Narendranagar"
  },
  {
    "label": "Pīpalkoti",
    "value": "Pīpalkoti"
  },
  {
    "label": "Pithorāgarh",
    "value": "Pithorāgarh"
  },
  {
    "label": "Rāipur",
    "value": "Rāipur"
  },
  {
    "label": "Rāīwāla Bara",
    "value": "Rāīwāla Bara"
  },
  {
    "label": "Rānikhet",
    "value": "Rānikhet"
  },
  {
    "label": "Rishīkesh",
    "value": "Rishīkesh"
  },
  {
    "label": "Rudraprayag",
    "value": "Rudraprayag"
  },
  {
    "label": "Rudraprayāg",
    "value": "Rudraprayāg"
  },
  {
    "label": "Sitārganj",
    "value": "Sitārganj"
  },
  {
    "label": "Tanakpur",
    "value": "Tanakpur"
  },
  {
    "label": "Tehri",
    "value": "Tehri"
  },
  {
    "label": "Tehri-Garhwāl",
    "value": "Tehri-Garhwāl"
  },
  {
    "label": "Udham Singh Nagar",
    "value": "Udham Singh Nagar"
  },
  {
    "label": "Uttarkāshi",
    "value": "Uttarkāshi"
  },
  {
    "label": "Vikāsnagar",
    "value": "Vikāsnagar"
  },
  {
    "label": "Ahmedpur",
    "value": "Ahmedpur"
  },
  {
    "label": "Aistala",
    "value": "Aistala"
  },
  {
    "label": "Aknapur",
    "value": "Aknapur"
  },
  {
    "label": "Alīpur Duār",
    "value": "Alīpur Duār"
  },
  {
    "label": "Alipurduar",
    "value": "Alipurduar"
  },
  {
    "label": "Āmlāgora",
    "value": "Āmlāgora"
  },
  {
    "label": "Amta",
    "value": "Amta"
  },
  {
    "label": "Amtala",
    "value": "Amtala"
  },
  {
    "label": "Andal",
    "value": "Andal"
  },
  {
    "label": "Arambagh community development block",
    "value": "Arambagh community development block"
  },
  {
    "label": "Ashoknagar Kalyangarh",
    "value": "Ashoknagar Kalyangarh"
  },
  {
    "label": "Badkulla",
    "value": "Badkulla"
  },
  {
    "label": "Baduria",
    "value": "Baduria"
  },
  {
    "label": "Bagdogra",
    "value": "Bagdogra"
  },
  {
    "label": "Bagnan",
    "value": "Bagnan"
  },
  {
    "label": "Bagula",
    "value": "Bagula"
  },
  {
    "label": "Bahula",
    "value": "Bahula"
  },
  {
    "label": "Bakreswar",
    "value": "Bakreswar"
  },
  {
    "label": "Balarāmpur",
    "value": "Balarāmpur"
  },
  {
    "label": "Bāli Chak",
    "value": "Bāli Chak"
  },
  {
    "label": "Bally",
    "value": "Bally"
  },
  {
    "label": "Bamangola community development block",
    "value": "Bamangola community development block"
  },
  {
    "label": "Baneswar",
    "value": "Baneswar"
  },
  {
    "label": "Bankra",
    "value": "Bankra"
  },
  {
    "label": "Bansberia",
    "value": "Bansberia"
  },
  {
    "label": "Bansihari community development block",
    "value": "Bansihari community development block"
  },
  {
    "label": "Barabazar",
    "value": "Barabazar"
  },
  {
    "label": "Baranagar",
    "value": "Baranagar"
  },
  {
    "label": "Barasat",
    "value": "Barasat"
  },
  {
    "label": "Bardhaman",
    "value": "Bardhaman"
  },
  {
    "label": "Barjora",
    "value": "Barjora"
  },
  {
    "label": "Barrackpore",
    "value": "Barrackpore"
  },
  {
    "label": "Baruipur",
    "value": "Baruipur"
  },
  {
    "label": "Basanti",
    "value": "Basanti"
  },
  {
    "label": "Bāwāli",
    "value": "Bāwāli"
  },
  {
    "label": "Begampur",
    "value": "Begampur"
  },
  {
    "label": "Belda",
    "value": "Belda"
  },
  {
    "label": "Beldanga",
    "value": "Beldanga"
  },
  {
    "label": "Beliatore",
    "value": "Beliatore"
  },
  {
    "label": "Berhampore",
    "value": "Berhampore"
  },
  {
    "label": "Bhadreswar",
    "value": "Bhadreswar"
  },
  {
    "label": "Bhandardaha",
    "value": "Bhandardaha"
  },
  {
    "label": "Bhatpara",
    "value": "Bhatpara"
  },
  {
    "label": "Birbhum district",
    "value": "Birbhum district"
  },
  {
    "label": "Birpara",
    "value": "Birpara"
  },
  {
    "label": "Budge Budge",
    "value": "Budge Budge"
  },
  {
    "label": "Chakapara",
    "value": "Chakapara"
  },
  {
    "label": "Chakdaha",
    "value": "Chakdaha"
  },
  {
    "label": "Champadanga",
    "value": "Champadanga"
  },
  {
    "label": "Chāmpāhāti",
    "value": "Chāmpāhāti"
  },
  {
    "label": "Champdani",
    "value": "Champdani"
  },
  {
    "label": "Chandrakona",
    "value": "Chandrakona"
  },
  {
    "label": "Chittaranjan",
    "value": "Chittaranjan"
  },
  {
    "label": "Churulia",
    "value": "Churulia"
  },
  {
    "label": "Contai",
    "value": "Contai"
  },
  {
    "label": "Cooch Behar",
    "value": "Cooch Behar"
  },
  {
    "label": "Cossimbazar",
    "value": "Cossimbazar"
  },
  {
    "label": "Dakshin Dinajpur district",
    "value": "Dakshin Dinajpur district"
  },
  {
    "label": "Dalkola",
    "value": "Dalkola"
  },
  {
    "label": "Debagram",
    "value": "Debagram"
  },
  {
    "label": "Debīpur",
    "value": "Debīpur"
  },
  {
    "label": "Dhaniakhali community development block",
    "value": "Dhaniakhali community development block"
  },
  {
    "label": "Dhulagari",
    "value": "Dhulagari"
  },
  {
    "label": "Dhulian",
    "value": "Dhulian"
  },
  {
    "label": "Dhupguri",
    "value": "Dhupguri"
  },
  {
    "label": "Diamond Harbour",
    "value": "Diamond Harbour"
  },
  {
    "label": "Digha",
    "value": "Digha"
  },
  {
    "label": "Dinhata",
    "value": "Dinhata"
  },
  {
    "label": "Domjur",
    "value": "Domjur"
  },
  {
    "label": "Dubrajpur",
    "value": "Dubrajpur"
  },
  {
    "label": "Egra",
    "value": "Egra"
  },
  {
    "label": "Falakata",
    "value": "Falakata"
  },
  {
    "label": "Fort Gloster",
    "value": "Fort Gloster"
  },
  {
    "label": "Gaighata community development block",
    "value": "Gaighata community development block"
  },
  {
    "label": "Gairkata",
    "value": "Gairkata"
  },
  {
    "label": "Gangadharpur",
    "value": "Gangadharpur"
  },
  {
    "label": "Gangarampur",
    "value": "Gangarampur"
  },
  {
    "label": "Garui",
    "value": "Garui"
  },
  {
    "label": "Garulia",
    "value": "Garulia"
  },
  {
    "label": "Giria",
    "value": "Giria"
  },
  {
    "label": "Gobardanga",
    "value": "Gobardanga"
  },
  {
    "label": "Gobindapur",
    "value": "Gobindapur"
  },
  {
    "label": "Gopinathpur",
    "value": "Gopinathpur"
  },
  {
    "label": "Gorubathan",
    "value": "Gorubathan"
  },
  {
    "label": "Gosaba",
    "value": "Gosaba"
  },
  {
    "label": "Gosanimari",
    "value": "Gosanimari"
  },
  {
    "label": "Gurdaha",
    "value": "Gurdaha"
  },
  {
    "label": "Guskhara",
    "value": "Guskhara"
  },
  {
    "label": "Haldibari",
    "value": "Haldibari"
  },
  {
    "label": "Halisahar",
    "value": "Halisahar"
  },
  {
    "label": "Harindanga",
    "value": "Harindanga"
  },
  {
    "label": "Haringhata",
    "value": "Haringhata"
  },
  {
    "label": "Hasimara",
    "value": "Hasimara"
  },
  {
    "label": "Hindusthan Cables Town",
    "value": "Hindusthan Cables Town"
  },
  {
    "label": "Hooghly district",
    "value": "Hooghly district"
  },
  {
    "label": "Ichapur",
    "value": "Ichapur"
  },
  {
    "label": "Indpur community development block",
    "value": "Indpur community development block"
  },
  {
    "label": "Ingrāj Bāzār",
    "value": "Ingrāj Bāzār"
  },
  {
    "label": "Islampur",
    "value": "Islampur"
  },
  {
    "label": "Jafarpur",
    "value": "Jafarpur"
  },
  {
    "label": "Jaigaon",
    "value": "Jaigaon"
  },
  {
    "label": "Jamuria",
    "value": "Jamuria"
  },
  {
    "label": "Jangipur",
    "value": "Jangipur"
  },
  {
    "label": "Jaynagar Majilpur",
    "value": "Jaynagar Majilpur"
  },
  {
    "label": "Jejur",
    "value": "Jejur"
  },
  {
    "label": "Jhalida",
    "value": "Jhalida"
  },
  {
    "label": "Jhilimili",
    "value": "Jhilimili"
  },
  {
    "label": "Kalaikunda",
    "value": "Kalaikunda"
  },
  {
    "label": "Kaliaganj",
    "value": "Kaliaganj"
  },
  {
    "label": "Kalimpong",
    "value": "Kalimpong"
  },
  {
    "label": "Kalna",
    "value": "Kalna"
  },
  {
    "label": "Kamarhati",
    "value": "Kamarhati"
  },
  {
    "label": "Kamarpukur",
    "value": "Kamarpukur"
  },
  {
    "label": "Karimpur",
    "value": "Karimpur"
  },
  {
    "label": "Katwa",
    "value": "Katwa"
  },
  {
    "label": "Kenda",
    "value": "Kenda"
  },
  {
    "label": "Keshabpur",
    "value": "Keshabpur"
  },
  {
    "label": "Kharba",
    "value": "Kharba"
  },
  {
    "label": "Khardaha",
    "value": "Khardaha"
  },
  {
    "label": "Khatra",
    "value": "Khatra"
  },
  {
    "label": "Kirnahar",
    "value": "Kirnahar"
  },
  {
    "label": "Krishnanagar",
    "value": "Krishnanagar"
  },
  {
    "label": "Krishnapur",
    "value": "Krishnapur"
  },
  {
    "label": "Kshirpai",
    "value": "Kshirpai"
  },
  {
    "label": "Kulpi",
    "value": "Kulpi"
  },
  {
    "label": "Kultali",
    "value": "Kultali"
  },
  {
    "label": "Kurseong",
    "value": "Kurseong"
  },
  {
    "label": "Lalgarh",
    "value": "Lalgarh"
  },
  {
    "label": "Lalgola",
    "value": "Lalgola"
  },
  {
    "label": "Loyabad",
    "value": "Loyabad"
  },
  {
    "label": "Madanpur",
    "value": "Madanpur"
  },
  {
    "label": "Madhyamgram",
    "value": "Madhyamgram"
  },
  {
    "label": "Mahiari",
    "value": "Mahiari"
  },
  {
    "label": "Mahishadal community development block",
    "value": "Mahishadal community development block"
  },
  {
    "label": "Mainaguri",
    "value": "Mainaguri"
  },
  {
    "label": "Manikpara",
    "value": "Manikpara"
  },
  {
    "label": "Masila",
    "value": "Masila"
  },
  {
    "label": "Mathabhanga",
    "value": "Mathabhanga"
  },
  {
    "label": "Matiali community development block",
    "value": "Matiali community development block"
  },
  {
    "label": "Matigara community development block",
    "value": "Matigara community development block"
  },
  {
    "label": "Mejia community development block",
    "value": "Mejia community development block"
  },
  {
    "label": "Memari",
    "value": "Memari"
  },
  {
    "label": "Mirik",
    "value": "Mirik"
  },
  {
    "label": "Mohanpur community development block",
    "value": "Mohanpur community development block"
  },
  {
    "label": "Monoharpur",
    "value": "Monoharpur"
  },
  {
    "label": "Muragācha",
    "value": "Muragācha"
  },
  {
    "label": "Muri",
    "value": "Muri"
  },
  {
    "label": "Nabagram",
    "value": "Nabagram"
  },
  {
    "label": "Nadia district",
    "value": "Nadia district"
  },
  {
    "label": "Nagarukhra",
    "value": "Nagarukhra"
  },
  {
    "label": "Nagrakata",
    "value": "Nagrakata"
  },
  {
    "label": "Naihati",
    "value": "Naihati"
  },
  {
    "label": "Naksalbari",
    "value": "Naksalbari"
  },
  {
    "label": "Nalhati",
    "value": "Nalhati"
  },
  {
    "label": "Nalpur",
    "value": "Nalpur"
  },
  {
    "label": "Namkhana community development block",
    "value": "Namkhana community development block"
  },
  {
    "label": "Nandigram",
    "value": "Nandigram"
  },
  {
    "label": "Nangi",
    "value": "Nangi"
  },
  {
    "label": "Nayagram community development block",
    "value": "Nayagram community development block"
  },
  {
    "label": "North 24 Parganas district",
    "value": "North 24 Parganas district"
  },
  {
    "label": "Odlabari",
    "value": "Odlabari"
  },
  {
    "label": "Paikpara",
    "value": "Paikpara"
  },
  {
    "label": "Panagarh",
    "value": "Panagarh"
  },
  {
    "label": "Panchla",
    "value": "Panchla"
  },
  {
    "label": "Panchmura",
    "value": "Panchmura"
  },
  {
    "label": "Pandua",
    "value": "Pandua"
  },
  {
    "label": "Panihati",
    "value": "Panihati"
  },
  {
    "label": "Panskura",
    "value": "Panskura"
  },
  {
    "label": "Parbatipur",
    "value": "Parbatipur"
  },
  {
    "label": "Paschim Medinipur district",
    "value": "Paschim Medinipur district"
  },
  {
    "label": "Patiram",
    "value": "Patiram"
  },
  {
    "label": "Pātrasāer",
    "value": "Pātrasāer"
  },
  {
    "label": "Patuli",
    "value": "Patuli"
  },
  {
    "label": "Pujali",
    "value": "Pujali"
  },
  {
    "label": "Puncha community development block",
    "value": "Puncha community development block"
  },
  {
    "label": "Purba Medinipur district",
    "value": "Purba Medinipur district"
  },
  {
    "label": "Purulia",
    "value": "Purulia"
  },
  {
    "label": "Raghudebbati",
    "value": "Raghudebbati"
  },
  {
    "label": "Raghunathpur",
    "value": "Raghunathpur"
  },
  {
    "label": "Raiganj",
    "value": "Raiganj"
  },
  {
    "label": "Rajmahal",
    "value": "Rajmahal"
  },
  {
    "label": "Rajnagar community development block",
    "value": "Rajnagar community development block"
  },
  {
    "label": "Ramchandrapur",
    "value": "Ramchandrapur"
  },
  {
    "label": "Ramjibanpur",
    "value": "Ramjibanpur"
  },
  {
    "label": "Rampur Hat",
    "value": "Rampur Hat"
  },
  {
    "label": "Raypur",
    "value": "Raypur"
  },
  {
    "label": "Rishra",
    "value": "Rishra"
  },
  {
    "label": "Sahapur",
    "value": "Sahapur"
  },
  {
    "label": "Sainthia",
    "value": "Sainthia"
  },
  {
    "label": "Salanpur community development block",
    "value": "Salanpur community development block"
  },
  {
    "label": "Sankarpur",
    "value": "Sankarpur"
  },
  {
    "label": "Sankrail",
    "value": "Sankrail"
  },
  {
    "label": "Santipur",
    "value": "Santipur"
  },
  {
    "label": "Santoshpur",
    "value": "Santoshpur"
  },
  {
    "label": "Santuri community development block",
    "value": "Santuri community development block"
  },
  {
    "label": "Sarenga",
    "value": "Sarenga"
  },
  {
    "label": "Serpur",
    "value": "Serpur"
  },
  {
    "label": "Shyamnagar, West Bengal",
    "value": "Shyamnagar, West Bengal"
  },
  {
    "label": "Singur",
    "value": "Singur"
  },
  {
    "label": "Sodpur",
    "value": "Sodpur"
  },
  {
    "label": "Solap",
    "value": "Solap"
  },
  {
    "label": "Sonada",
    "value": "Sonada"
  },
  {
    "label": "Sonamukhi",
    "value": "Sonamukhi"
  },
  {
    "label": "Sonarpur community development block",
    "value": "Sonarpur community development block"
  },
  {
    "label": "South 24 Parganas district",
    "value": "South 24 Parganas district"
  },
  {
    "label": "Srikhanda",
    "value": "Srikhanda"
  },
  {
    "label": "Srirampur",
    "value": "Srirampur"
  },
  {
    "label": "Suri",
    "value": "Suri"
  },
  {
    "label": "Swarupnagar community development block",
    "value": "Swarupnagar community development block"
  },
  {
    "label": "Takdah",
    "value": "Takdah"
  },
  {
    "label": "Taki",
    "value": "Taki"
  },
  {
    "label": "Tamluk",
    "value": "Tamluk"
  },
  {
    "label": "Tarakeswar",
    "value": "Tarakeswar"
  },
  {
    "label": "Titagarh",
    "value": "Titagarh"
  },
  {
    "label": "Tufanganj",
    "value": "Tufanganj"
  },
  {
    "label": "Tulin",
    "value": "Tulin"
  },
  {
    "label": "Uchalan",
    "value": "Uchalan"
  },
  {
    "label": "Ula",
    "value": "Ula"
  },
  {
    "label": "Uluberia",
    "value": "Uluberia"
  },
  {
    "label": "Uttar Dinajpur district",
    "value": "Uttar Dinajpur district"
  },
  {
    "label": "Uttarpara Kotrung",
    "value": "Uttarpara Kotrung"
  }
]

export const fillMissingData = (data, startDate, endDate, timeFrame) => {
  const period = timeFrame?.toLowerCase();
  const result = [];
  let current = moment(startDate);
  const end = moment(endDate);

  // Format options for different periods
  const formatOptions = {
    daily: "YYYY-MM-DD",
    weekly: "YYYY-[W]WW",
    monthly: "YYYY-MM",
    quarterly: "YYYY-[Q]Q",
    yearly: "YYYY",
  };

  // Create a map from existing data
  const existingData = new Map(data.map((item) => [item.date, item.userCount]));

  // Loop until we reach the end date
  while (current.isSameOrBefore(end)) {
    const formattedDate = current.format(formatOptions[period]);

    // Add the date to the result with userCount from existing data or 0 if not present
    result.push({
      date: formattedDate,
      userCount: existingData.get(formattedDate) || 0,
    });

    // Increment the date based on the specified period
    if (period === "daily") {
      current.add(1, "days");
    } else if (period === "weekly") {
      current.add(1, "weeks");
    } else if (period === "monthly") {
      current.add(1, "months");
    } else if (period === "quarterly") {
      current.add(1, "quarters");
    } else if (period === "yearly") {
      current.add(1, "years");
    }
  }
  return result;
  // console.log(result);
};

